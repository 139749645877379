<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：新增其他信息页面管理页面
	开始时间：2021-01-14
	开发人员：刘巍骏,万攀
	最后修改：2021-05-01
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="basicAll productTab_item">
    <el-button size="mini" type="primary" @click="InfoVisible = true ">新增</el-button>
    <ul>
     <li v-for="(item,index) in productOtherInformationData">
       <div class="title">{{item.name}}:</div>
        <div class="date"><el-input v-model="item.value" size="mini" placeholder="请输入内容"></el-input></div>
     </li>
    </ul>
    <!-- 新增弹框 -->
    <el-dialog
      title="新增"
      :visible.sync="InfoVisible"
      width="15%"
      append-to-body>
      <el-input v-model="infoName" size="mini" placeholder="请输入新增信息名称"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="InfoVisible = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="addInfo" size="mini">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  // 导入vuex
  import {mapMutations,mapState,mapActions} from 'vuex'

  export default{
		// custom_field
    data(){
      return{
        InfoVisible:false,
        infoName:'',
        productOtherInformationData:[
        {
          name:'产地',
          value:''
        },{
          name:'汇率',
          value:''
        },]
      }
    },
    mounted() {
      if(this.Is_add == 1&&this.currentUpdateProduct.product!=null){
          this.updateOther()
      }
    },
    watch:{
      productOtherInformationData:{
      handler(newVal) {
        this.PRODUCT_DATA({
          productOtherInformationData: newVal
        });
      },
      deep: true,
      },
      show_addBox(newVal){
        if(newVal == false){
          this.productOtherInformationData = [
        {
          name:'产地',
          value:''
        },{
          name:'汇率',
          value:''
        },]
        }
      },
      currentUpdateProduct:{
      handler(newVal) {
        this.updateOther()
      },
      deep: true,
      },
    },
    props:{
      Is_add:{
        type:Number,
        default:0
      }
    },
    computed:{
      ...mapState({
      currentUpdateProduct: state => state.datum.currentUpdateProduct,
      show_addBox: state => state.datum.show_addBox,
      })
    },
    methods:{
      ...mapMutations([
        'PRODUCT_DATA',//修改货品数据
      ]),
      /* 添加基本信息*/
      addInfo(){
        if(this.infoName==''){
          this.$message({
            type:'error',
            message:'新增名称不能为空'
          })
          return;
        }
        this.InfoVisible = false
        this.productOtherInformationData.push({
          name:this.infoName,
          value:'',
        })
      },
      updateOther(){
        let arr = []
        let OtherInformationData = []
        let obj = {
          name:'',
          value:'',
        }
        if(this.currentUpdateProduct.product.custom_field==null)return;
        let str = this.currentUpdateProduct.product.custom_field
          str = str.replace(/###/g,',');
          str = str.replace(/@INFOSPEED@/g,',');
          arr = str.split(",");
          this.productOtherInformationData = []
          for(let i = 0 ; i<arr.length;i++){

            if(i%2==0){
              obj.name = arr[i]
            }else if(i%2==1){
              obj.value = arr[i]
            }
            if(i%2==1){
              this.productOtherInformationData.push(obj)
              obj = {
                name:'',
                value:'',
                  }
            }
          }
      },
    }
  }
</script>

<style lang="less" scoped="scoped">
  .basicAll{
    // width: 500px;
    // border: 1px solid black;
    ul{
      margin-left: 3px;
      // border: 1px solid black;
      li{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 10px;
        // border: 1px solid black;
        .title{
          width: 80px;
          color: black;
          font-weight: bold;
          // border: 1px solid black;
        }
        .date{
          .iteminput{
            width: 250px;
          }
          .itemtextarea{
            width: 250px;
          }
        }
      }
    }
  }
</style>
