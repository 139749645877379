<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：新建库存管理页面
	开始时间：2021-01-14
	开发人员：刘巍骏,万攀
	最后修改：2021-05-01
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="productTab_item">
    <!-- <div class="inventoryBtn">
      <el-button size="mini" type="primary" @click="SHOW_WAREHOUSEBOX(true)">库存设定</el-button>
    </div> -->
    <!-- 库存提示信息 -->
    <div>

    </div>

    <!-- 批次号信息 -->
    <div class="baseMsgBox">
      <span class="title">期初批次号:</span>
      <span class="data">{{batchStr}}</span>
    </div>
    <!-- 库存信息框 -->
    <div class="billing_table inventoryBox">
      <el-table :data="inventoryData" height="100%" border style="width: 100%" row-key="id" ref="inventoryTable"
        @selection-change="getSelChange" @select="getCurrentSelRow" default-expand-all
        :tree-props="defaultProps">
        <!-- 索引 -->
        <el-table-column type="index" fixed width="50"></el-table-column>
        <!-- 全选 -->
        <el-table-column type="selection" :selectable="disableDefaultRow" fixed width="50"></el-table-column>
        <!-- 数据内容 -->
        <el-table-column prop="warehouseName" label="仓库名称" sortable width="100"></el-table-column>
        <!-- <el-table-column prop="warehouseCode" label="仓库编号" width="120"></el-table-column> -->
        <el-table-column :label="'库存下限' + '('+getUnitName+')'" width="120">
          <template slot-scope="scope">
            <el-input size="mini" v-model="scope.row.inventoryFloor" placeholder="请输入下限" :disabled="scope.row.isEdit"
              oninput="this.value=this.value.replace(/[^0-9\.]/g,'')"></el-input>
          </template>
        </el-table-column>
        <el-table-column :label="'库存上限' + '('+getUnitName+')'" width="120">
          <template slot-scope="scope">
            <el-input size="mini" v-model="scope.row.inventoryUpper" placeholder="请输入上限" :disabled="scope.row.isEdit"
              oninput="this.value=this.value.replace(/[^0-9\.]/g,'')"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="初期库存" width="120">
          <template slot-scope="scope">
            <el-input size="mini" v-model="scope.row.initInventory" placeholder="请输入初期库存"
              :disabled="scope.row.isEditInit" :oninput="getInitNumChange(scope.row)"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="place" label="仓位号" width="120" show-overflow-tooltip>
          <template slot-scope="scope">
            <div @click="showWarehousePlaceBox(scope.row)">
              <span v-if="scope.row.place.length==0" class="noSetPlace">未设置</span>
              <span v-if="scope.row.place.length==1" class="setPlace">{{scope.row.place[0].productPlace}}</span>
              <div v-if="scope.row.place.length>1">
                <el-tooltip placement="right">
                  <ul slot="content">
                    <li v-for="elem in scope.row.place">{{elem.productPlace}}</li>
                  </ul>
                  <span class="setPlace">已设{{scope.row.place.length}}个仓位</span>
                </el-tooltip>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="practicalCount" :label="'实际数量' + '('+getUnitName+')'" width="120"></el-table-column>
        <el-table-column prop="count" :label="'账面数量' + '('+getUnitName+')'" width="120">
        </el-table-column>
        <el-table-column :label="'成本单价' + '('+productData.basicData.priceUnit +')'" prop="price" width="120">
        </el-table-column>
        <el-table-column prop="inventoryPrice" :label="'库存金额' + '('+productData.basicData.priceUnit +')'" width="120">
          <template slot-scope="scope">
            <span>{{scope.row.inventoryPrice | getCalculate(scope.row)}}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 仓库选择组件 -->
    <!-- <warehouseList @SelectedData="getSelectData"></warehouseList> -->

    <!-- 仓位号选择弹框 -->
    <warehousePlace @SelectedData="getSelectWarehousePlace" :warehouseId="curInventoryId"
      :defaultSelPlace="defaultSelPlace"></warehousePlace>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  // 引入网络请求
  import sellApi from '@/network/sell/bills.js'; //销售模块

  //导入组件
  // import warehouseList from '@/components/commComponent/list/list_warehouse.vue';
  import warehousePlace from '@/components/commComponent/tree/tree_warehousePlace'; //仓位号弹框

  var $this; //声明一个全局变量用于存储this
  export default {
    name: 'create_inventoryMessage',
    props: {
      //判断是新增还是修改
      Is_add: {
        type: Number,
        default: 0
      },
    },
    data() {
      return {
        //库存数据
        inventoryData: [], //所有仓库列表数据
        selInventoryData: [], //已选仓库数据
        defaultSelId: [], //默认选中的仓库id
        batchStr: '', //期初批次号
        curInventoryId: -1, //当前操作的仓库id

        //仓位相关数据
        defaultSelPlace: [], //默认选中的仓位

        //辅助数据
        defaultProps: { //表格子级匹配字段
          children: 'children',
          hasChildren: 'hasChildren'
        },
      }
    },
    beforeCreate() {
      $this = this;
    },
    created() {
      //判断是否存在仓库列表数据
      this.judgeWarehouseData();
    },
    computed: {
      ...mapState({
        productData: state => state.datum.productData, //产品数据
        show_addBox: state => state.datum.show_addBox, //控制新增弹框
        WarehouseConfig: state => state.system.WarehouseConfig, //仓库数据
        currentUpdateProduct: state => state.datum.currentUpdateProduct, //获取修改的数据
        addInventoryPlace: state => state.datum.addInventoryPlace, //新增的仓位号数据
        deleteInventoryPlace: state => state.datum.deleteInventoryPlace, //已删除的仓位号数据
        billTemplateData: state => state.system.billTemplateData, //所有单据类型数据
        saleAssisUnitName: state => state.datum.saleAssisUnitName, //销售强制使用计量单位(名称)
        purchaseAssisUnitName: state => state.datum.purchaseAssisUnitName, //采购强制使用计量单位(名称)
      }),
      /* 获取显示的计量单位 */
      getUnitName() {
        if (!!this.purchaseAssisUnitName) {
          return this.purchaseAssisUnitName;
        }

        return this.productData.basicData.calculateUnit;
      },
    },
    watch: {
      // 监听货品库存信息
      selInventoryData: {
        deep: true,
        handler(newVal) {
          //将数据添加到vuex
          this.PRODUCT_DATA({
            stockData: newVal
          })
        },
      },
      /* 关闭新增页面时清空数据 */
      show_addBox(newVal) {
        if (newVal == false) {
          this.inventoryData = []
        }
      },
      //监听货品采购价发生变化
      productData: {
        handler(newVal) {
          if (this.Is_add == 0) {
            this.inventoryData.forEach((item, index) => {
              item.price = newVal.basicData.purchasePrice;
            })
          }
        },
        deep: true
      },
      //监听默认选中所选库存信息
      defaultSelId(newVal) {
        this.$nextTick(() => {
          this.inventoryData.forEach((item, index) => {
            if (newVal.includes(item.id)) {
              this.$refs.inventoryTable.toggleRowSelection(item, true);
            }
          })
        })
      },
    },
    methods: {
      ...mapMutations([
        'SHOW_WAREHOUSEBOX', //修改仓库是否显示
        'SHOW_WAREHOUSEPLACE', //显示仓位号弹框
        'PRODUCT_DATA', //货品数据
        'ADD_INVENTORY_PLACE', //新增的仓位号信息
        'DELETE_INVENTORY_PLACE', //已删除的仓位号信息
      ]),
      ...mapActions([
        'getWarehouseConfig', //获取仓库数据
        'getelectronicTemplate', //获取所有单据类型
      ]),

      /* 判断vuex是否存在仓库数据 */
      async judgeWarehouseData() {
        //若没有仓库则请求数据
        if (this.WarehouseConfig.length == 0) {
          await this.getWarehouseConfig();
        }

        //获取批次号
        this.judgeBillTemplateData();

        //循环前清空仓库数据
        this.inventoryData = [];
        //默认加载仓库列表数据
        this.WarehouseConfig.forEach((item, index) => {
          //判断只显示主仓和普通仓
          if(item.is_main == 0 || item.is_main == 1){
            let warehouseItem = {
              id: item.id, //仓库id
              wareHouseNmberId: null, //库存数量id
              wareHouseRuleId: null, //库存规则id
              warehouseName: item.name, //仓库名称
              warehouseCode: item.encoding, //仓库编码
              inventoryFloor: '', //仓库下限
              inventoryUpper: '', //仓库上限
              initInventory: 0, //初期库存
              place: [], //仓位号
              price: '', //成本单价
              practicalCount: 0, //实际数量
              count: 0, //账面数量
              isEdit: true, //是否可编辑(选择仓库后可编辑,true:不可编辑  false:可编辑)
              isEditInit: true, //是否可以编辑期初库存(新增时可编辑,修改是已添加的不可编辑,未添加的可编辑)
              // children:item.children,//子级仓库
            }
            //获取仓库数据
            this.inventoryData.push(warehouseItem);
          }
        })

        //默认选中第一个(主仓)
        if (this.Is_add == 0) {
          this.defaultSelId = [];
          this.WarehouseConfig.forEach((item, index) => {
            if (parseInt(item.is_main) == 0) {
              this.defaultSelId.push(item.id)
            }
          })
        }

        //获取修改仓库信息
        this.getUpdateWareData(this.currentUpdateProduct);
      },

      /* 判断单据模板数据是否存在 */
      async judgeBillTemplateData() {
        //若没有单据模板信息则请求数据
        if (this.billTemplateData.length == 0) {
          await this.getelectronicTemplate();
        }

        //获取期初库存单据模板
        let defaultCode = 'DOC-S-012'; //期初入库单模板
        let defaultTempData = {}; //定义接受选择的单据模板数据
        //根据默认单据模板获取id
        this.billTemplateData.forEach((itemI, indexI) => {
          if (itemI.children) {
            itemI.children.forEach((itemJ, indexJ) => {
              if (itemJ.code == defaultCode) {
                defaultTempData = itemJ
              }
            })
          }
        })

        // 判断有无默认模板
        if (JSON.stringify(defaultTempData) != "{}") {
          // 根据单据模板获取批次号
          let batchData = {
            templateId: defaultTempData.id
          }
          sellApi.findBatchCodeByFormatStr(batchData).then(res => {
            if (res.code == 200) {
              //判断是添加还是修改
              if (this.Is_add == 0) { //添加则默认加上批次号
                // 设置期初批次号
                this.inventoryData.forEach((item, index) => {
                  item.batchStr = res.data; //期初批次号
                  item.bills_type_id = defaultTempData.id; //单据类型id
                })
              } else if (this.Is_add == 1) { //修改职添加未选中的仓库批次号
                // 设置期初批次号
                this.inventoryData.forEach((item, index) => {
                  if (!this.defaultSelId.includes(item.id)) {
                    item.batchStr = res.data; //期初批次号
                    item.bills_type_id = defaultTempData.id; //单据类型id
                  }
                })
              }
              //将批次号存入data
              this.batchStr = res.data;
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        }
      },

      /* 获取已选仓库数据 */
      getSelChange(selection) {
        // 勾选后可编辑
        selection.forEach((item, index) => {
          // 判断是否默认选中(默认选中期初需特殊处理)
          if (this.defaultSelId.includes(item.id)) { //默认选中的
            // 判断是新增还是修改
            if (this.Is_add == 0) { //新增
              item.isEditInit = false;
            } else if (this.Is_add == 1) { //修改
              item.isEditInit = true;
            }
          } else {
            item.isEditInit = false;
          }
          //其他数据正常处理
          item.isEdit = false;
        })
        //将数据存入data
        this.selInventoryData = selection;
      },

      /* 获取当前选中行 */
      getCurrentSelRow(selection, row) {
        //获取当前行是否选中
        let curSel = selection.length && selection.indexOf(row) !== -1;
        if (!curSel) { //取消选中
          row.isEdit = true; //其他信息不可编辑
          row.isEditInit = true; //期初信息不可编辑
        }
      },

      /* 禁用默认选中的不可改数据 */
      disableDefaultRow(row) {
        //不可选数据条件
        return !this.defaultSelId.includes(row.id);
      },

      /* 获取初期库存发生改变 */
      getInitNumChange(row) {
        //定义一个正则表达式/[^0-9]/g
        let rule = /[^0-9]/g;
        if (rule.test(row.initInventory)) {
          row.initInventory = "";
        }
        // 规定实际数据等于库存数量(不需要,重新考虑)
        // row.practicalCount = row.initInventory;
      },

      /* 根据请求返回id获取当前货品对应仓库 */
      getUpdateWareData(data) {
        // 判断是否为修改模式下
        if (JSON.stringify(data) != '{}' && this.Is_add == 1) {
          //循环获取信息
          this.inventoryData.forEach((itemI, indexI) => {
            // 获取初期库存和实际库存
            data.depotStockNumber.forEach((itemJ, indexJ) => {
              //判断获取对应数据信息
              if (itemI.id == itemJ.warehouseId) {
                itemI.wareHouseNmberId = itemJ.id; //库存数量表id
                //将期初库存转换为采购计量单位
                if (itemJ.isInit == 1) {
                  itemI.initInventory = this.commonJsExtend.getPurchaseNumByBase(itemJ.initNumber, data
                  .prices); //期初库存
                }
                itemI.practicalCount = itemI.practicalCount + itemJ.warehouseNumber; //实际数量
                itemI.count = this.commonJsExtend.getPurchaseNumByBase(itemJ.usableNumber, data
                .prices); //账面数量(有问题,需调整,账面数量是无法记录在数据库的)
                itemI.place = itemJ.productInfoPlaces; //仓位号数据
              }
            })

            //换算成采购计量单位
            itemI.practicalCount = this.commonJsExtend.getPurchaseNumByBase(itemI.practicalCount, data.prices);
            itemI.count = itemI.practicalCount;

            //获取预警规则
            data.depotStockWarningRule.forEach((itemZ, indexZ) => {
              if (itemI.id == itemZ.warehouseId) {
                itemI.wareHouseRuleId = itemZ.id; //库存规则表id
                itemI.inventoryFloor = itemZ.minWarning; //仓库下限
                itemI.inventoryUpper = itemZ.maxWarning; //仓库上限
              }
            })

            //获取成本单价
            data.prices.forEach((itemK, indexK) => {
              if (itemK.is_base == 1) {
                itemI.price = itemK.cost_price;
              }
            })
            //如果没有基础计量单位则默认选择第一个
            if (itemI.price == "") {
              itemI.price = data.prices[0].cost_price;
            }
          })

          //获取有库存信息的仓库id数组
          this.defaultSelId = [];
          data.depotStockNumber.forEach((item, index) => {
            this.defaultSelId.push(item.warehouseId);
          })
        }
      },

      /* 获取仓位号弹框选择数据 */
      getSelectWarehousePlace(data) {
        let curInverntoryObj = this.inventoryData.filter(item => item.id == this.curInventoryId);
        //获取数据
        if (curInverntoryObj[0]) {
          //获取数据
          curInverntoryObj[0].place = data;
          // 判断是否为修改界面
          if (this.Is_add == 1) { //修改界面
            //定义旧的仓位号数据信息
            let oldPlaceData = [];
            let oldPlaceIdArr = [];
            //获取原仓库仓位号信息
            let oldInventoryObj = this.currentUpdateProduct.depotStockNumber.filter(item => item.warehouseId == this
              .curInventoryId);
            //判断是否有仓位号数据信息
            if (oldInventoryObj && oldInventoryObj[0].productInfoPlaces) {
              //循环获取仓位号id数据
              oldInventoryObj[0].productInfoPlaces.forEach((item, index) => {
                //获取旧数据
                oldPlaceData.push(item);
                //获取旧数据组成id数组
                oldPlaceIdArr.push(item.placeIds)
              })
            }
            //获取新选择的仓位id数组
            let newPlaceIdArr = [];
            data.forEach((item, index) => {
              newPlaceIdArr.push(item.placeIds);
            })

            //获取删除的仓位信息
            oldPlaceData.forEach((item, index) => {
              if (!newPlaceIdArr.includes(item.placeIds)) {
                //设置类型为删除
                item.type = -1
                // 判断已存入的信息不在存入
                if (JSON.stringify(this.deleteInventoryPlace).indexOf(JSON.stringify(item)) == -1) {
                  this.DELETE_INVENTORY_PLACE(item);
                }
                console.log(this.deleteInventoryPlace);
              }
            })

            //获取新增的仓位信息
            data.forEach((item, index) => {
              if (!oldPlaceIdArr.includes(item.placeIds)) {
                //设置类型为新增
                item.type = 0
                // 判断已存入的信息不在存入
                if (JSON.stringify(this.addInventoryPlace).indexOf(JSON.stringify(item)) == -1) {
                  this.ADD_INVENTORY_PLACE(item);
                }
                console.log(this.addInventoryPlace);
              }
            })
          }
        }
      },

      /* 显示仓位号弹框 */
      showWarehousePlaceBox(data) {
        //获取当前操作的仓库id
        this.curInventoryId = data.id;
        //循环前清空数组
        this.defaultSelPlace = [];
        //获取当前已选的仓位数据
        data.place.forEach((item, index) => {
          if (item.placeIds) {
            //获取最有一个id下标
            let endIndex = item.placeIds.lastIndexOf(",");
            //获取最有一个id数据
            let endId = item.placeIds.substring(endIndex + 1, item.placeIds.length);
            //将数据存入
            this.defaultSelPlace.push(parseInt(endId));
          }
        })
        //显示仓库弹框
        this.SHOW_WAREHOUSEPLACE(true);
      },
    },
    components: {
      // warehouseList
      warehousePlace
    },
    filters: {
      /* 获取需要计算的值 */
      getCalculate(curFileName, row) {
        let result = eval(row.practicalCount * row.price); //定义一个返回值
        //处理小数
        result = $this.commonJsExtend.getDecimalsLen(result, 5);
        //将数据存入当前行
        row[curFileName] = result;
        return result;
      },
    }
  }
</script>

<style lang="less" scoped="scoped">
  .baseMsgBox {
    padding: 5px 0;

    // border: 1px solid black;
    .title {
      padding: 5px;
      color: black;
      font-weight: bold;
    }
  }

  .inventoryBtn {
    height: 40px;
    // border: 1px solid black;
  }

  .inventoryBox {
    height: calc(100% - 40px);
  }

  /* 未设置仓位号样式 */
  .noSetPlace {
    color: red;
    font-size: 12px;
    cursor: pointer;
  }

  /* 已设置仓位号样式 */
  .setPlace {
    color: @theme;
    font-size: 12px;
    cursor: pointer;
  }
</style>
