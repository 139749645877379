<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：新建基本信息页面
	开始时间：2021-01-14
	开发人员：刘巍骏,万攀
	最后修改：2021-05-01
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="basicAll productTab_item scollStyle">
    <ul>
      <li>
        <div class="title">
          <span class="mustHint">*</span>
          货品分类:
        </div>
        <div class="date" @click="SHOW_PRODUCTTYPEBOX(true)" v-if="Is_add==0">
          <el-input v-model="productBasicData.productClassify" class="iteminput" size="small" placeholder="请选择分类"
            oninput="this.value=this.value.replace(/\S+/g,'')"></el-input>
        </div>
        <div v-else class="date">
          <el-input v-model="productBasicData.productClassify" class="iteminput" size="small" disabled
            placeholder="请选择分类"></el-input>
        </div>
      </li>
      <li>
        <div class="title">
          <span class="mustHint">*</span>
          货品名称:
        </div>
        <div class="date">
          <el-input v-model="productBasicData.productName" class="iteminput" size="small" placeholder="请输入名称">
          </el-input>
        </div>
      </li>
      <li>
        <div class="title">
          <span class="mustHint">*</span>
          货品编号:
        </div>
        <div class="date">
          <el-input :disabled="CodeIsDisabled" v-model="productBasicData.productCode" class="iteminput" size="small"
            placeholder="请输入货品编码">
          </el-input>
        </div>
      </li>
      <li v-if="sourceProductCodeIsShow">
        <div class="title">
          原物料编码:
        </div>
        <div class="date">
          <el-input v-model="productBasicData.sourceProductCode" class="iteminput" size="small" placeholder="请输入名称">
          </el-input>
        </div>
      </li>
      <li>
        <div class="title">
          货品属性:
        </div>
        <div class="date">
          <el-select class="iteminput" v-model="productBasicData.productProperty" multiple collapse-tags size="small"
            placeholder="请选择" @visible-change="visibleSelect">
            <el-option v-for="item in ProductProperty" :key="item.id" :label="item.name" :value="item.name"
              @click.native="getProductPropertyId(item)">
            </el-option>
          </el-select>
        </div>
      </li>
      <li>
        <div class="title">
          货品来源:
        </div>
        <div class="date">
          <el-select class="iteminput" v-model="productBasicData.productSource" size="small" placeholder="请选择">
            <el-option v-for="item in ProductSource" :key="item.id" :label="item.name" :value="item.name"
              @click.native="getProductSourceId(item)"></el-option>
          </el-select>
        </div>
      </li>
      <li>
        <div class="title">
          <span class="mustHint" v-if="paraVals.supplierName == 0">*</span>
          供应商:
        </div>
        <div class="date" @click="showSupplierBox()">
          <el-input v-model="productBasicData.supplierName" class="iteminput" size="small" placeholder="请选择供应商"
            oninput="this.value=this.value.replace(/\S+/g,'')"></el-input>
        </div>
      </li>
      <li>
        <div class="title">
          品牌:
        </div>
        <div class="date">
          <el-select class="iteminput" v-model="productBasicData.productBrand" size="small" placeholder="请选择">
            <el-option v-for="item in Brand" :key="item.id" :label="item.name" :value="item.name"
              @click.native="getProductBrandId(item)"></el-option>
          </el-select>
        </div>
      </li>
      <li v-show="Is_add==0">
        <div class="title">
          <span class="mustHint" v-if="paraVals.salePrice == 0">*</span>
          销售单价:
        </div>
        <div class="date">
          <el-input v-model="productBasicData.unitSellingPrice" class="iteminput" size="small" placeholder="请输入内容"
            oninput="this.value=this.value.replace(/[^0-9\.]/g,'')"></el-input>
        </div>
      </li>
      <li v-show="Is_add==0">
        <div class="title">
          <span class="mustHint" v-if="paraVals.purchasePrice == 0">*</span>
          采购单价:
        </div>
        <div class="date">
          <el-input v-model="productBasicData.purchasePrice" class="iteminput" size="small" placeholder="请输入内容"
            oninput="this.value=this.value.replace(/[^0-9\.]/g,'')"></el-input>
        </div>
      </li>
      <li>
        <div class="title">
          <span class="mustHint">*</span>
          价格单位:
        </div>
        <div class="date">
          <el-select class="iteminput" v-model="productBasicData.priceUnit" size="small" placeholder="请选择">
            <el-option v-for="item in FinanceCurrency" :key="item.id" :label="item.name" :value="item.name"
              @click.native="getPriceId(item)"></el-option>
          </el-select>
        </div>
      </li>
      <li>
        <div class="title">
          <span class="mustHint">*</span>
          计量单位:
        </div>
        <div class="date">
          <el-select class="iteminput" v-model="productBasicData.calculateUnit" size="small" placeholder="请选择" :disabled="judgeUpdateUnit()">
            <el-option v-for="item in ProductUnit" :key="item.id" :label="item.name" :value="item.name"
              @click.native="getcalculateUnitId(item)"></el-option>
          </el-select>
        </div>
      </li>
      <li v-if="!productType ||speData.length==0">
        <div class="title">
          <span class="mustHint" v-if="paraVals.specification == 0">*</span>
          规格信息:
        </div>
        <div class="date">
          <el-select v-if="Is_add==0" no-data-text="该分类下暂无规格信息" class="iteminput"
            v-model="productBasicData.specificationParameter" multiple collapse-tags size="small" filterable
            @change.native="getSelInputVal" @blur.native="getSelInputVal" placeholder="选择货品分类获取规格">
            <el-option-group v-for="group in speData" :key="group.id" :label="group.name">
              <el-option v-for="elem in group.children" :key="elem.id" :label="elem.name" :value="elem.name">
              </el-option>
            </el-option-group>
          </el-select>
          <el-input v-else v-model="productBasicData.specificationParameter" class="iteminput" size="small"
            placeholder="请输入内容"></el-input>
        </div>
      </li>
      <li>
        <div class="title">
          <span class="mustHint" v-if="paraVals.minNum == 0">*</span>
          起订量:
        </div>
        <div class="date">
          <el-input v-model="productBasicData.minNum" class="iteminput" size="small" placeholder="请输入内容"
            oninput="this.value=this.value.replace(/[^0-9]/g,'')"></el-input>
        </div>
      </li>
      <li>
        <div class="title">
          <span class="mustHint" v-if="paraVals.weightItem == 0">*</span>
          单个重量:
        </div>
        <div class="date">
          <div class="date_left">
            <el-input v-model="productBasicData.weightItem" size="small" placeholder="请输入内容"
              oninput="this.value=this.value.replace(/[^0-9\.]/g,'')"></el-input>
          </div>
          <div class="date_right">
            <el-radio v-model="productBasicData.IsWeight" label="0">g</el-radio>
            <el-radio v-model="productBasicData.IsWeight" label="1">kg</el-radio>
          </div>
        </div>
      </li>
      <li>
        <div class="title">
          是否可组装:
        </div>
        <div class="date">
          <el-radio v-model="productBasicData.IsGroup" label="1">是</el-radio>
          <el-radio v-model="productBasicData.IsGroup" label="0">否</el-radio>
        </div>
      </li>
      <li>
        <div class="title">
          是否可拆卸:
        </div>
        <div class="date">
          <el-radio v-model="productBasicData.IsDisassembly" label="1">是</el-radio>
          <el-radio v-model="productBasicData.IsDisassembly" label="0">否</el-radio>
        </div>
      </li>
      <li>
        <div class="title">
          允许客户下单:
        </div>
        <div class="date">
          <el-checkbox v-model="productBasicData.isSelfOrder" :disabled="disable_is_self_order"></el-checkbox>
        </div>
      </li>
      <li>
        <div class="title">
          不参与库存:
        </div>
        <div class="date">
          <el-checkbox v-model="productBasicData.isDepot" :disabled="disable_is_depot"></el-checkbox>
        </div>
      </li>
      <li>
        <div class="title">
          不参与成本核算:
        </div>
        <div class="date">
          <el-checkbox v-model="productBasicData.isCostCheck" :disabled="disable_is_cost_check"></el-checkbox>
        </div>
      </li>
      <li>
        <div class="title">
          BOM合成物料:
        </div>
        <div class="date">
          <el-checkbox v-model="productBasicData.isBomMerge" :disabled="disable_is_bom_merge"></el-checkbox>
        </div>
      </li>
      <li class="singleli">
        <div class="title">
          <span class="mustHint" v-if="paraVals.simplDescription == 0">*</span>
          商品简介:
        </div>
        <div class="date">
          <el-input v-model="productBasicData.simplDescription" class="itemtextarea" type="textarea" :rows="3"
            placeholder="请输入内容"></el-input>
        </div>
      </li>
    </ul>

    <!-- 弹框 -->
    <!-- 货品分类弹框组件 -->
    <product-type @SelectedData="getProductData"></product-type>

    <!-- 供应商选择弹框组件 -->
    <supplier-list @SelectedData="supplierData" v-if="show_supplierBox"></supplier-list>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  // 导入组件
  import productType from '@/components/commComponent/tree/tree_productType'; //货品分类组件
  import supplierList from '@/components/commComponent/list/list_supplier'; //供应商组件

  // 导入接口请求
  import api from '@/network/datum/product'
  import apiDictionary from '@/network/system/dictionary'

  export default {
    name: 'basicMessage',
    props: {
      //判断是添加还是修改(0:新增  1:修改)
      Is_add: {
        type: Number,
        default: 0
      },
      paraVals: Object
    },
    data() {
      return {
        // 货品基本信息数据
        productBasicData: {
          productCode: '', //货品编号
          sourceProductCode: '', //原物料编码
          productName: '', //货品名称
          productClassify: '', //货品分类
          productClassifyId: '', //货品分类id
          productProperty: [], //货品属性数组
          productPropertyId: [], //货品属性id数组
          productSourceId: '', //货品来源id
          productSource: '', //货品来源
          productBrand: '', //品牌
          productBrandId: '', //品牌id
          specificationParameter: '', //规格信息
          supplierName: '', //供应商名称
          supplierId: '', //供应商id
          minNum: 0, //起订量
          weightItem: 0, //单个重量
          IsWeight: '0', //判断重量单位
          simplDescription: '', //简介
          IsGroup: '0', //是否可组装
          IsDisassembly: '0', //是否可拆卸
          isSelfOrder:false,//允许客户下单
          isDepot: false, //不参与库存
          isCostCheck: false, //不参与成本核算
          isBomMerge: false, //BOM合成物料
          //价格相关
          priceUnit: '', //价格单位
          priceUnitId: '', //价格单位id
          priceId: '', //基本计量价格id(修改界面使用)
          calculateUnit: '', //计量单位
          calculateUnitId: '', //计量单位Id
          unitSellingPrice: 0, //销售单价
          isOrderDefault: 0, //是否销售强制使用单位(修改界面使用)
          purchasePrice: 0, //采购单价
          isPurchaseDefault: 0, //是否为采购强制使用单位(修改界面使用)
        },
        speData: [], //规格信息
        CodeIsDisabled: true, //货品编码是否可输入
        sourceProductCodeIsShow: true, //原物料编码是否显示
        encodings: {
          productProperty: '', //货品属性
          productSource: '', //货品来源
          supplier: '', //供应商
          productBrand: '', //货品品牌
          productType: '', //货品分类
          warehouse: '', //仓库
        }, //货品编码选中的encoding数组

        //禁用控制
        disable_is_self_order:false,//是否禁用允许客户下单
        disable_is_depot: false, //是否禁用参与库存
        disable_is_cost_check: false, //是否禁用参与成本核算
        disable_is_bom_merge: false, //是否禁用bom合成物料

        //子组件相关
        show_supplierBox:false,//控制是否加载供应商组件
      }
    },
    computed: {
      ...mapState({
        show_addBox: state => state.datum.show_addBox, //控制新增弹框
        FinanceCurrency: state => state.system.FinanceCurrency, //交易币种
        ProductUnit: state => state.system.ProductUnit, //计量单位
        productType: state => state.datum.productType, //货品类型为哪种模式
        currentUpdateProduct: state => state.datum.currentUpdateProduct, //获取修改的数据
        specsData: state => state.datum.specsData, //根据分类保存规格信息
        ProductProperty: state => state.system.ProductProperty, //货品属性
        ProductSource: state => state.system.ProductSource, //货品来源
        companyparamlist: state => state.companyparamlist, //获取企业级参数
        Brand: state => state.system.Brand, //品牌
        ProductClassfiy: state => state.system.ProductClassfiy, //货品分类数据
        WarehouseConfig: state => state.system.WarehouseConfig, //仓库数据
        baseUnitData: state => state.datum.baseUnitData, //货品界面设置的基础计量单位
        productIsBillsUsed: state => state.datum.productIsBillsUsed, //货品是否已被单据使用
      }),
    },
    watch: {
      // 监听产品基本信息改变时将数据存入vuex
      productBasicData: {
        handler(newVal) {
          //处理货品属性数据
          this.PRODUCT_DATA({
            productBasicData: newVal
          });
        },
        deep: true,
      },
      // 监听价格管理界面基本计量单位发生改变
      baseUnitData:{
        handler(newVal) {
          //获取计量单位
          this.productBasicData.calculateUnit = newVal.name;
          this.productBasicData.calculateUnitId = newVal.id;
        },
        deep:true
      }
    },
    created() {
      //判断公共数据是否存在
      this.judgeDictData();
    },
    mounted() {},
    methods: {
      ...mapMutations([
        "PRODUCT_DATA", //修改货品数据
        'SHOW_PRODUCTTYPEBOX', //控制显示货品分类弹框
        'SHOW_SUPPLIERBOX', //控制显示供应商选择组件
        'SPECS_DATA', //规格信息
        'PRODUCT_BRAND', //品牌
        'SALE_ASSIS_UNIT', //销售强制使用计量单位
        'PURCHASE_ASSIS_UNIT', //采购强制使用计量单位
        'BOM_ASSIS_UNIT', //bom指定计量单位
        'PRODUCT_TYPE_MORE_UTIL',//多计量数据
      ]),
      ...mapActions([
        "getFinanceCurrency", //获取交易币种信息
        'getProductUnit', //获取计量单位信息
        'getProductProperty', //获取货品属性
        'getAllProductSource', //获取货品来源
        'getProductClassfiy', //获取货品分类数据
        'getWarehouseConfig', //获取仓库数据
      ]),

      /* 判断数据字典数据是否在vuex */
      async judgeDictData() {
        //获取仓库信息
        if (this.WarehouseConfig.length == 0) {
          this.getWarehouseConfig();
        }
        // this.encodings.warehouse = this.WarehouseConfig[0].encoding //仓库
        // 获取分类信息
        if (!this.ProductClassfiy.length > 0) {
          this.getProductClassfiy();
        }
        //判断品牌
        if (this.Brand.length == 0) {
          this.findAllBrand();
        }
        //判断交易币种
        if (!this.FinanceCurrency.length > 0) {
          await this.getFinanceCurrency();
        }
        //给价格单位赋值默认值
        if(this.Is_add == 0){
          this.productBasicData.priceUnit = this.FinanceCurrency[0].name;
          this.productBasicData.priceUnitId = this.FinanceCurrency[0].id;
        }
        //加载企业级参数
        if (this.companyparamlist == "") {
          this.getcompanyparamlist();
        }
        //判断货品属性
        if (!this.ProductProperty.length > 0) {
          await this.getProductProperty()
        }
        //货品属性设置默认值
        if(this.Is_add == 0){
          this.productBasicData.productProperty.push(this.ProductProperty[0].name);
          this.productBasicData.productPropertyId.push(this.ProductProperty[0].id);
          this.encodings.productProperty = this.ProductProperty[0].encoding; //属性
          this.$parent.$parent.$parent.$parent.getMustHint(this.ProductProperty[0]);
        }

        //判断货品来源
        if (!this.ProductSource.length > 0) {
          await this.getAllProductSource()
        }
        //货品来源设置默认值
        if(this.Is_add == 0){
          this.productBasicData.productSource = this.ProductSource[0].name
          this.productBasicData.productSourceId = this.ProductSource[0].id
          this.encodings.productSource = this.ProductSource[0].encoding
        }

        //判断计量单位
        if (!this.ProductUnit.length > 0) {
          await this.getProductUnit();
        }
        //给产品计量单位赋值默认值
        if(this.Is_add == 0){
          this.productBasicData.calculateUnit = this.ProductUnit[0].name;
          this.productBasicData.calculateUnitId = this.ProductUnit[0].id;
          //调用设置默认计量单位方法
          this.setPriceDefaultUnit(this.ProductUnit[0].id);
        }

        //判断货品编码
        this.CodeIsDisabled = this.companyparamlist.param301 == 0 ? false : true //手动填写货品编码
        this.sourceProductCodeIsShow = this.companyparamlist.param345 == 0 ? true : false //原物料编码

        // 获取修改信息
        this.updateBaseInfo()
      },

      //设置价格默认的几个计量单位
      setPriceDefaultUnit(id) {
        //销售强制使用单位
        this.SALE_ASSIS_UNIT(id);
        //采购强制使用单位
        this.PURCHASE_ASSIS_UNIT(id);
        //bom强制使用单位
        this.BOM_ASSIS_UNIT(id);
      },

      /* 查询品牌*/
      findAllBrand(type) {
        // 获取需要loading的容器
        this.loading = this.commonJsExtend.customLoading("#checkBrank", 3);
        apiDictionary.productDict.findAllBrand().then(res => {
          this.loading.close(); //关闭loading框
          if (res.code == 200) {
            //将获取的数据存入vuex
            this.PRODUCT_BRAND(res.data);
          }
        });
      },

      /* 获取货品编码 type(0:不需要清空编码  1:需要清空编码) */
      getProductCode(type) {
        //判断修改时不获取编码
        if(this.Is_add == 1){
          return
        }

        //手动编写货品编码开启时
        if (this.companyparamlist.param301 == 0 && this.Is_add == 0){
          //判断是否有过滤分类
          if(!!this.companyparamlist.param368 && this.companyparamlist.param368.indexOf(this.productBasicData.productClassifyId) == -1){
             this.CodeIsDisabled = false;

             //判断是否需要清空编码
             if(type == 1){
               this.productBasicData.productCode = '';
             }
            return
          }else{
            this.CodeIsDisabled = true;
          }
        };
        var data = { //设置默认编码
          classfiyId: this.productBasicData.productClassifyId, //分类
          encodings: JSON.stringify(this.encodings) //编码数组
        }
        //发送网络请求
        api.getProductCode(data).then(res => {
          if (res.code == 200) {
            this.productBasicData.productCode = res.data
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: 3000
            })
          }
        })
      },

      /* 货品分类组件中传过来的参数 */
      getProductData(data) {
        if (data.length > 1) {
          this.$message({
            type: 'warning',
            message: '只能选择一个分类',
            duration: this.elDuration
          })
          return
        }
        //获取货品分类数据
        this.productBasicData.productClassify = data[0].name;
        this.productBasicData.productClassifyId = data[0].id;
        //树形结构转非树形
        let varcodeData = this.commonJsExtend.toNoTree(this.ProductClassfiy, [])
        let classifyData = [] //定义数组储存符合条件的编码
        let isClassify = []
        if (data[0].format_str) {
          let formatStrArr = data[0].format_str.split('@@@')
          formatStrArr = formatStrArr.slice(1, formatStrArr.length) //切除前缀(防止前缀中包含5)
          formatStrArr.forEach(item => {
            if (item.indexOf(5) != -1) {
              if (item) {
                isClassify = item.split('-')
              }
            }
          })
        }
        //获取分类编码规则制定的数组
        varcodeData.forEach(itemI => {
          if (itemI.id == data[0].id) {
            itemI.level = '5.3'
            classifyData.push(itemI)
          }
          if (itemI.id == data[0].parent_id) {
            itemI.level = '5.2'
            classifyData.push(itemI)
            varcodeData.forEach(itemJ => {
              if (itemI.parent_id == itemJ.id) {
                itemJ.level = '5.1'
                classifyData.push(itemJ)
              }
            })
          }
        })
        let classifyEncoing = [] //定义数组储存分类编码
        if (data[0].is_classfiy_set == 0) { //开启通用时
          classifyData.forEach(itemI => {
            classifyEncoing.push(itemI.encoding) //储存分类编码
          })
        } else { //开启分类时
          isClassify.forEach(itemJ => {
            classifyData.forEach(itemI => {
              if (itemI.level == itemJ) {
                classifyEncoing.push(itemI.encoding) //储存分类编码
              }
            })
          })
        }
        //赋值分类编码
        this.encodings.productType = classifyEncoing.join(data[0].separator);

        //判断是否为新增,新增则获取货品编码
        if (this.Is_add == 0) {
          //发起请求
          this.getProductCode(1);
        }

        //判断允许客户下单是否启用
        if (data[0].is_self_order == 1) {
          this.disable_is_self_order = true;
        }
        //判断不参与库存是否启用
        if (data[0].is_depot == 1) {
          this.disable_is_depot = true;
        }
        //判断不参与成本核算是否启用
        if (data[0].is_cost_check == 1) {
          this.disable_is_cost_check = true;
        }
        //判断bom合成物料是否启用
        if (data[0].is_bom_merge == 1) {
          this.disable_is_bom_merge = true;
        }

        // ********************************获取规格********************************
        //根据货品分类获取货品规格信息
        let speData = {
          productTypeId: data[0].id
        }
        //获取规格信息
        api.searchSpecsByClassfiyId(speData).then(res => {
          if (res.code == 200) {
            // 过滤获取已启用的规格数据
            this.speData = res.data.filter((current, index, array) => {
              return current.isEnable != 0
            })
            this.SPECS_DATA(this.speData)
          } else {
            this.$message({
              type: 'error',
              message: '货品规格信息获取失败!',
              duration: this.elDuration
            })
          }
        })

        // ********************************获取多计量********************************
        //根据货品分类获取货品规格信息
        let unitData = {
          productTypeId: data[0].id
        }
        //获取多计量信息
        apiDictionary.productDict.findProductUnitByProductType(unitData).then(res => {
          if (res.code == 200) {
            if(res.data.length>0){
              //开启增强模式
              this.$emit("updateIsEnhance",true);
              if(res.data.length>0){
                //获取当前默认计量数据
                let curUnite = res.data[0].sysProductUnitVals;
                //将数据存入vuex
                this.PRODUCT_TYPE_MORE_UTIL(curUnite);
              }
            }else{
              //关闭增强模式
              this.$emit("updateIsEnhance",false);
              //清除vuex数据
              this.PRODUCT_TYPE_MORE_UTIL([]);
            }
          } else {
            this.$message({
              type: 'error',
              message: '多计量信息获取失败!',
              duration: this.elDuration
            })
          }
        })
      },

      /* 加载供应商组件 */
      showSupplierBox(){
        //加载弹框
        this.show_supplierBox = true;
        //显示弹框
        this.SHOW_SUPPLIERBOX(true);
      },

      /* 供应商选择组件中传过来的参数 */
      supplierData(data) {
        this.productBasicData.supplierName = data.fullName
        this.productBasicData.supplierId = data.id
        this.encodings.supplier = data.supplier_no
        this.getProductCode(0); //获取编码

        //移除弹框
        this.show_supplierBox = false;
      },

      /* 获取价格单位 */
      getPriceId(data) {
        this.productBasicData.priceUnitId = data.id;
        this.productBasicData.priceUnit = data.name;
      },

      /* 点击获取计量单位Id */
      getcalculateUnitId(data) {
        this.productBasicData.calculateUnitId = data.id;
        this.productBasicData.calculateUnit = data.name;
        //调用设置默认计量单位方法
        if(this.Is_add == 0){//新增界面才触发
          this.setPriceDefaultUnit(data.id);
        }
      },

      /* 点击获取货品来源Id */
      getProductSourceId(data) {
        this.productBasicData.productSourceId = data.id;
        this.productBasicData.productSource = data.name;
        this.encodings.productSource = data.encoding;
        this.getProductCode(0); //获取编码
      },

      /* 点击获取货品品牌Id */
      getProductBrandId(data) {
        this.productBasicData.productBrandId = data.id;
        this.productBasicData.productBrand = data.name;
        this.encodings.productBrand = data.encoding;
        this.getProductCode(0); //获取编码
      },

      /* 判断是否可以修改计量单位 */
      judgeUpdateUnit(){
        //定义返回结果
        let result = false;

        //判断修改时才触发
        if(this.Is_add == 1){
          //判断是否被单据引用
          if(this.productIsBillsUsed){
            result = true;
          }
        }

        return result;
      },

      /*  点击获取货品属性Id*/
      getProductPropertyId(data) {
        let curIndex = -1;
        //循环获取数据是否显示
        this.productBasicData.productPropertyId.forEach((item, index) => {
          if (item == data.id) {
            curIndex = index;
          }
        })
        // 判断是新增还是删除
        if (curIndex == -1) { //不存在(新增)
          this.productBasicData.productPropertyId.push(data.id);
          this.productBasicData.productProperty.push(data.name);
        } else { //存在(删除)
          this.productBasicData.productPropertyId.splice(curIndex, 1);
          this.productBasicData.productProperty.splice(curIndex, 1);
        }
        this.encodings.productProperty = data.encoding;
        //获取编码
        this.getProductCode(0);
        this.$parent.$parent.$parent.$parent.getMustHint(data);
      },

      /* 获取规格手动输入规格型号数据 */
      getSelInputVal(e) { //不考虑输入多规格情况
        this.$set(this.productBasicData, 'specificationParameter', [e.target.value]);
      },

      /* 获取点击修改的数据 */
      updateBaseInfo() {
        if (JSON.stringify(this.currentUpdateProduct) != '{}' && this.Is_add == 1) {
          //获取基础计价信息
          let basePrice = this.currentUpdateProduct.prices.filter(item => item.is_base == 1)[0];

          // 获取货品基本信息
          let proBaseData = {
            productId: this.currentUpdateProduct.product.id, //货品id
            productCode: this.currentUpdateProduct.product.product_code, //货品编号
            sourceProductCode: this.currentUpdateProduct.product.product_old_code, //原物料编号
            productName: this.currentUpdateProduct.product.name, //货品名称
            specificationParameter: this.currentUpdateProduct.product.specifications, //规格信息
            productClassify: this.currentUpdateProduct.classfiyName, //货品分类
            productClassifyId: this.currentUpdateProduct.product.product_type_id, //货品分类id
            productProperty: this.currentUpdateProduct.product.propertyName == null ? [] : this.currentUpdateProduct
              .product.propertyName.split(","), //货品属性
            productPropertyId: this.currentUpdateProduct.product.pro_property_id == null ? [] : this
              .currentUpdateProduct.product.pro_property_id.split(","), //货品属性id
            productSourceId: this.currentUpdateProduct.product.pro_source_id == null ? "" : this.currentUpdateProduct
              .product.pro_source_id, //货品来源id
            productSource: this.currentUpdateProduct.product.sourceName == null ? "" : this.currentUpdateProduct
              .product.sourceName, //货品来源
            productBrandId: this.currentUpdateProduct.product.brand_id == null ? "" : this.currentUpdateProduct
              .product.brand_id, //货品品牌id
            productBrand: this.currentUpdateProduct.product.brandName == null ? "" : this.currentUpdateProduct.product
              .brandName, //货品品牌
            supplierName: this.currentUpdateProduct.product.supplier_name == null ? "" : this.currentUpdateProduct
              .product.supplier_name, //供应商名称
            supplierId: this.currentUpdateProduct.product.supplier_id == null ? "" : this.currentUpdateProduct.product
              .supplier_id, //供应商ID
            minNum: this.currentUpdateProduct.product.order_limit_num, //起订量
            weightItem: this.currentUpdateProduct.product.weight, //单个重量
            IsWeight: this.currentUpdateProduct.product.weight_unit + '', //判断重量单位
            simplDescription: this.currentUpdateProduct.product.desciption, //简介
            IsGroup: this.currentUpdateProduct.product.is_group_product + '', //是否可组装(+''代表转换成字符串格式)
            IsDisassembly: this.currentUpdateProduct.product.is_disassembly + '', //是否可拆卸
            isSelfOrder: this.currentUpdateProduct.product.is_self_order == 1 ? true : false, //允许客户下单
            isDepot: this.currentUpdateProduct.product.is_depot == 1 ? true : false, //不参与库存
            isCostCheck: this.currentUpdateProduct.product.is_cost_check == 1 ? true : false, //不参与成本核算
            isBomMerge: this.currentUpdateProduct.product.is_bom_merge == 1 ? true : false, //BOM合成物料
            // 价格相关
            priceUnit: basePrice.currency, //价格币种名称
            priceUnitId: basePrice.currency_id, //币种id
            calculateUnit: basePrice.calculateName, //计量单位
            calculateUnitId: basePrice.calculate_type, //计量单位id
            priceId: basePrice.id, //基础计量价格id
            unitSellingPrice: basePrice.price, //销售价
            isOrderDefault: basePrice.is_order_default, //是否为销售价指定计量单位
            purchasePrice: basePrice.cost_price, //采购价
            isPurchaseDefault: basePrice.is_purchase_default, //是否为采购价强制指定单位
          }

          // 将基本信息数据存入data
          for (let i in proBaseData) {
            this.productBasicData[i] = proBaseData[i]
          }

          //判断允许客户下单是否禁用
          if (this.currentUpdateProduct.product.proClassifyIsSelfOrder == 1) {
            this.disable_is_self_order = true;
          }
          //判断bom合成物料是否禁用
          if (this.currentUpdateProduct.product.proClassifyIsBomMerge == 1) {
            this.disable_is_bom_merge = true;
          }
          //判断参与库存是否禁用
          if (this.currentUpdateProduct.product.proClassifyIsDepot == 1) {
            this.disable_is_depot = true;
          }
          // 判断参与成本核算是否禁用
          if (this.currentUpdateProduct.product.proClassifyIsCostCheck == 1) {
            this.disable_is_cost_check = true;
          }

          //获取多价格数据(需修改)
          this.productBasicData.multiUnitSellingPrice = this.currentUpdateProduct.prices;
        }
      },

      // 下拉框展开 关闭事件
      visibleSelect(e) {
        return
        // 获取所有的li
        const lis = document.getElementsByClassName('el-select-dropdown__item')
        // 打开下拉框 添加input
        if (e) {
          for (let i = 0; i < lis.length; i++) {
            const element = lis[i]
            console.log(element);
            const input = document.createElement('input')
            input.style.cssText = 'margin-right: 10px;'
            input.type = 'checkbox'
            // 根据是否有选中的类名来判断li是否被选中
            if (element.classList.contains('selected')) {
              // 对应复选框也设置被选中
              input.checked = true
            }
            element.insertBefore(input, element.lastChild)
          }
        } else {
          // 关闭下拉框 移除掉input--否则每次打开添加就是多个input
          for (let i = 0; i < lis.length; i++) {
            const element = lis[i]
            element.removeChild(element.firstChild)
          }
        }
      },
    },
    components: {
      productType,
      supplierList,
    }
  }
</script>

<style lang="less">
  .el-select-group__title {
    color: @theme;
  }
</style>
<style lang="less" scoped="scoped">
  // 基本信息总框
  .basicAll {

    // width: 500px;
    // border: 1px solid black;
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      // border: 1px solid black;
      li {
        width: 48%;
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        // border: 1px solid black;
        // 标题样式
        .title {
          width: 120px;
          color: black;
          text-align: right;
          font-weight: bold;
          padding-right: 10px;
          // border: 1px solid black;
        }

        //数据样式
        .date {
          display: flex;
          justify-content: space-between;
          align-items: center;

          // border: 1px solid black;
          .date_left {
            //分左边的
            width: 40%;
          }

          .date_right {
            //分右边的
            width: 50%;
          }

          //输入框样式
          .iteminput {
            width: 250px;
          }

          // 下拉框样式
          .selectUl {
            border: 1px solid black;

            li {
              border: 1px solid black;

              .content {
                border: 1px solid black;
              }

              ;
            }
          }
        }
      }

      // 单独一行的li
      .singleli {
        width: 100%;
        display: flex;

        .data {
          display: flex;
          flex: 1;

          .input_price {
            width: 75px;
          }

          .input_split {
            margin: 5px 0px 0px 5px;
          }
        }

        .itemtextarea {
          width: 735px;
        }
      }
    }

    // 价格表格(修改时显示)
    .priceTable {
      width: 100%;
      border: 1px solid black;
    }
  }
</style>
