<!-- 程序版本：V3.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：货品分类选择弹框组件
开始时间：2021-04-06
开发人员：万攀
最后修改：2021-04-06
备注说明：如需修改请联系开发人员 -->

<template>
  <div class="">
    <el-dialog title="货品分类" :visible.sync="show_productTypeBox" :before-close="cancel_productType" width="400px"
      append-to-body>
      <div class="productType_Box">
        <el-tree :data="ProductClassfiy" :props="defaultProps" default-expand-all show-checkbox :check-strictly="true"
          @check-change="getClassfiyData" :expand-on-click-node="false" node-key="id" ref="tree" >
        </el-tree>

      </div>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" @click="commit_productType">确认</el-button>
        <el-button size="mini" @click="cancel_productType">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  //导入方法
  import api from "@/network/system/dictionary";
  import req from "@/network/datum/product"
  export default {
    name: 'tree_productType',
    props:{
      //默认选中的分类id
      selProductTypeId:{
        type: Array,
        default () {
          return []
        }
      }
    },
    data() {
      return {
        //当前页面数据
        selectData: [], //选择货品分类数据

        // Element ui相关
        defaultProps: {
          children: 'children',
          label: 'name'
        },
      };
    },
    mounted() {
      // 判断vuex是否存在货品分类数据
      this.judgeProductType();

    },
    computed: {
      ...mapState({
        ProductClassfiy: state => state.system.ProductClassfiy, //货品分类数据
        show_productTypeBox: state => state.commComponent.show_productTypeBox, //控制显示产品分类弹框组件
      }),
    },
    watch:{
      // 监听默认选中的分类
      selProductTypeId: {
        handler(newVal) {
          this.$nextTick(()=>{
            if(newVal.length>0){
              this.setCheckedKeysout(newVal);
            }
          })
        },
        immediate: true,
        deep:true
      },
    },
    methods: {
      ...mapMutations([
        'SHOW_PRODUCTTYPEBOX', //控制单据选择弹框是否显示
        'PRODUCT_CLASSFIY'
      ]),
      ...mapActions([
        'getProductClassfiy',//获取货品分类数据
      ]),

      /* 判断数据库是否存在货品分类数据 */
      judgeProductType() {
        // 无数据则发送请求
        if (!this.ProductClassfiy.length > 0) {
          // 获取分类信息
          this.getProductClassfiy();
        }
      },

      //通过key获取选中节点
      setCheckedKeysout() {
        this.$nextTick(()=>{
          this.$refs.tree.setCheckedKeys(this.selProductTypeId);
        })
      },
      /* 获取当前分类下的的规格值 */
      getClassfiyData(data, checked, indeterminate) {
        if (checked) {
          this.selectData.push(data)
        } else {
          this.selectData.forEach((item, index) => {
            if (item.id == data.id) {
              this.selectData.splice(index, 1)
            }
          })
        }
        return
        req.searchSpecsByClassfiyId({
          productTypeId: e.id
        }).then(res => {
          if (res.code == 200) {
            this.specsData = res.data.filter((current, index, array) => {
              return current.isEnable != 0
            })
          }
        })
        this.selectData = e
      },

      // 提交选择货品分类
      commit_productType() {
        this.$emit('SelectedData', this.selectData)
        this.SHOW_PRODUCTTYPEBOX(false);
      },
      // 取消选择货品分类
      cancel_productType() {
        this.SHOW_PRODUCTTYPEBOX(false);
      },
    }
  };
</script>

<style lang="less" scoped="scoped">
  .productType_Box {
    width: 100%;
    height: 300px;
    overflow: auto;
    // border: 1px solid black;
  }
</style>
