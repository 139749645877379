<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：货品管理新建页面
	开始时间：2021-01-14
	开发人员：刘巍骏,万攀
	最后修改：2021-01-14
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="dialog">
    <el-dialog :visible.sync="show_addBox" v-if="show_addBox" :before-close="cancle_box" width="1000px" append-to-body>
      <div slot="title" class="dialogBox">
        <span class="itemText" v-if="Is_add==0">新增货品</span>
        <span class="itemText" v-else>修改货品</span>
        <span class="itemText">
          <el-checkbox v-model="isEnhance" v-show="Is_add==0">增强模式</el-checkbox>
        </span>
      </div>
      <!-- 新增货品内容 -->
      <div class="productAll">
        <el-tabs v-model="tabIndex" type="border-card" :before-leave='judgeStep'>
          <el-tab-pane label="基本信息" name="0">
            <basicMessage :Is_add="Is_add" :paraVals="paraVals" @updateIsEnhance="updateIsEnhance"></basicMessage>
          </el-tab-pane>
          <el-tab-pane label="价格管理" name="1" v-if="isEnhance">
            <priceManage :Is_add="Is_add" :isEnhance="isEnhance"></priceManage>
          </el-tab-pane>
          <!-- 修改模式下特用 -->
          <el-tab-pane label="价格管理" name="7" v-if="Is_add == 1">
            <priceManage :Is_add="Is_add" :isEnhance="isEnhance" ref="priceManage"></priceManage>
          </el-tab-pane>
          <el-tab-pane label="规格信息" name="2" v-if="isEnhance">
            <specifaction :Is_add="Is_add"></specifaction>
          </el-tab-pane>
          <el-tab-pane label="库存信息" name="3">
            <inventoryMsg :Is_add="Is_add"></inventoryMsg>
          </el-tab-pane>
          <el-tab-pane label="包装信息" name="8">
            <packing :Is_add="Is_add"></packing>
          </el-tab-pane>
          <el-tab-pane label="货品图片" name="4">
            <imgManage :Is_add="Is_add"></imgManage>
          </el-tab-pane>
          <el-tab-pane label="详细描述" name="5">
            <detailDesribe :Is_add="Is_add"></detailDesribe>
          </el-tab-pane>
          <el-tab-pane label="其它信息" name="6">
            <otherMesg :Is_add="Is_add"></otherMesg>
          </el-tab-pane>
        </el-tabs>
      </div>

      <!-- 确认按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" type="primary" @click="commit">保存</el-button>
        <el-button size="mini" @click="cancle_box">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState
  } from 'vuex'

  // 导入组件
  import basicMessage from './create_componentItem/create_basicMessage.vue'; //基本信息
  import priceManage from './create_componentItem/create_priceManage.vue'; //价格管理
  import imgManage from './create_componentItem/create_ImgManage.vue'; //产品图片
  import specifaction from './create_componentItem/create_specifaction.vue'; //规格信息
  import inventoryMsg from './create_componentItem/create_inventoryMessage.vue'; //规格信息
  import detailDesribe from './create_componentItem/create_detailDesribe.vue'; //规格信息
  import otherMesg from './create_componentItem/create_otherMessage.vue'; //规格信息
  import packing from './create_componentItem/create_packinginformation.vue';//包装信息
  //导入请求
  import api from '@/network/datum/product'
  export default {
    name: 'commodityCreate',
    props: {
      //是否添加
      Is_add: {
        type: Number,
        default: 0
      },
    },
    data() {
      return {
        //数据
        tabIndex: 0, //tab选中下标
        isEnhance: false, //是否为增强模式
        isShowProceTab: false, //是否显示价格标签页(修改时用)
        isUpdateSave: false, //是否为修改界面的保存按钮(默认为false)
        paraVals: { //权限(控制是否必填)
          salePrice: 1, //销售价
          purchasePrice: 1, //采购价
          specification: 1, //规格
          supplierName: 1, //供应商
          minNum: 1, //最小订购量
          weightItem: 1, //重量
          simplDescription: 1, //简述
          productImgData: 1, //图片
          detailData: 1, //详情
        },
        sourceData: []
      }
    },
    computed: {
      ...mapState({
        show_addBox: state => state.datum.show_addBox, //控制新增弹框
        productType: state => state.datum.productType, //产品类型(是否为增强模式)
        productData: state => state.datum.productData, //产品数据
        mainMeasureData: state => state.datum.mainMeasureData, //主计量信息
        assisMeasureData: state => state.datum.assisMeasureData, //辅计量信息
        deleteMeasureData: state => state.datum.deleteMeasureData, //修改时删除的多计量数据
        addInventoryPlace: state => state.datum.addInventoryPlace, //新增的仓位号数据
        deleteInventoryPlace: state => state.datum.deleteInventoryPlace, //已删除的仓位号数据
        saleAssisUnit: state => state.datum.saleAssisUnit, //销售强制使用计量单位
        purchaseAssisUnit: state => state.datum.purchaseAssisUnit, //采购强制使用计量单位
        bomAssisUnit: state => state.datum.bomAssisUnit, //bom使用计量单位
        productPackage: state => state.datum.productPackage, //包装信息
        currentUpdateProduct: state => state.datum.currentUpdateProduct, //请求修改数据
        companyparamlist: state => state.companyparamlist, //获取企业级参数
      }),
    },
    watch: {
      // 是否为增强模式
      isEnhance(newVal) {
        if (newVal == false) {
          if (this.tabIndex == 1 || this.tabIndex == 2) {
            this.tabIndex = '0'
          }
        }
        this.PRODUCT_TYPE(newVal);
      },
      //监听显示弹框属性
      show_addBox(newVal) {
        if (newVal == false && this.Is_add == 0) {
          this.isEnhance = false
        } else if (newVal == false && this.Is_add == 1) {
          this.isEnhance = true
        }
      },
      //新增
      tabIndex(newVal) {
        this.PRODUCT_TAB_INDEX(newVal);
      }
    },
    created() {
      //设置默认模式为极简模式
      this.PRODUCT_TYPE(false);
    },
    mounted() {
      // 获取编辑前数据
      this.getSourceData()
    },
    methods: {
      ...mapMutations([
        'PRODUCT_TYPE', //增强/极简模式
        'PRODUCT_TAB_INDEX', //产品tab下标
        'SHOW_ADDBOX', //控制显示添加弹框
        'IS_CANCEL',//是否为取消关闭
      ]),

      /* 获取编辑前数据 */
      getSourceData() {
        this.sourceData = JSON.parse(JSON.stringify(this.currentUpdateProduct))
      },

      /* 获取必填项 */
      getMustHint(data) {
        let companyparamlist = [{
          isopen: this.companyparamlist.param303, //销售价
          attr: this.companyparamlist.param304 //销售价属性
        }, {
          isopen: this.companyparamlist.param305, //采购价
          attr: this.companyparamlist.param306 //采购价属性
        }, {
          isopen: this.companyparamlist.param307, //规格信息
          attr: this.companyparamlist.param308 //规格信息属性
        }, {
          isopen: this.companyparamlist.param309, //重量
          attr: this.companyparamlist.param310 //重量属性
        }, {
          isopen: this.companyparamlist.param311, //起订单
          attr: this.companyparamlist.param312 //起订单属性
        }, {
          isopen: this.companyparamlist.param313, //供应商
          attr: this.companyparamlist.param314 //供应商属性
        }, {
          isopen: this.companyparamlist.param317, //货品简介
          attr: this.companyparamlist.param318 //货品简介属性
        }, {
          isopen: this.companyparamlist.param315, //上传图片
          attr: this.companyparamlist.param316 //上传图片属性
        }, {
          isopen: this.companyparamlist.param319, //货品详情
          attr: this.companyparamlist.param320 //货品详情属性
        }]
        let paraName = ['salePrice', 'purchasePrice', 'specification', 'weightItem', 'minNum', 'supplierName',
          'simplDescription', 'productImgData', 'detailData'
        ] //参数名数组
        companyparamlist.forEach((item, index) => {
          if (item.isopen == 0) {
            if (item.attr.split(',').indexOf(data.id + '') != -1) {
              this.paraVals[paraName[index]] = 0
            } else {
              this.paraVals[paraName[index]] = 1
            }
          }
        })
      },

      /* 判断步骤是否能进行*/
      judgeStep(newTab, oldTab) {
        // 判断货品信息是否合法
        let basicData = this.productData.basicData;
        let priceData = this.productData.priceData;
        // 判断当前界面类型
        if (this.Is_add == 0) { //新增界面
          //因为必填项做成了动态,所以该处的处理已删除
        } else if (this.Is_add == 1) { //修改界面
          //判断当前处理的界面
          if (oldTab == 0) { //基本信息界面
            //处理基本信息数据
            let logContent = ''; //定义基本信息日志信息
            let pricelogContent = ''; //定义价格管理日志信息
            let updateBaseData = this.getUpdateProductData(); //获取修改后的货品基本信息
            //判断数据是否合法
            if (!updateBaseData) {
              return;
            }
            //判断是否需要修改基本信息数据
            let isUpdate = this.judgeProductInfoUpdate(0, updateBaseData);

            //处理价格数据
            let basePrice = this.currentUpdateProduct.prices.filter(item => item.is_base == 1)[0];
            //处理基本信息中的价格信息
            let updatePriceData = {
              id: basePrice.id, //新增时id为空  修改时id为货品id
              calculate_type: this.productData.basicData.calculateUnitId, //价格计量单位id
              currency_id: this.productData.basicData.priceUnitId, //交易币种id
              currency: this.productData.basicData.priceUnit, //交易币种名称
              type: 1, //设置类型为修改
            }
            //判断是否需要修改价格信息数据
            let isPriceUpdate = this.judgeProductInfoUpdate(8, updatePriceData);

            // 判断基本信息和价格信息哪些需要修改
            if (isUpdate && isPriceUpdate) { //两个都需要修改
              this.$confirm('货品信息已发生改变是否保存?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                //调用修改货品基本信息方法
                this.updaePorductBaseInfo(updateBaseData, logContent, '基本信息');
                //调用修改货品价格信息方法
                this.updatePriceData(updatePriceData, logContent);
              }).catch(() => {
                this.$message({
                  type: 'info',
                  message: '已取消保存',
                  duration: this.elDuration
                });
              });
            } else if (isUpdate) { //只修改基本信息
              this.$confirm('货品信息已发生改变是否保存?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                //调用修改货品基本信息方法
                this.updaePorductBaseInfo(updateBaseData, logContent, '基本信息');
              }).catch(() => {
                this.$message({
                  type: 'info',
                  message: '已取消保存',
                  duration: this.elDuration
                });
              });
            } else if (isPriceUpdate) { //只修改价格信息
              this.$confirm('货品信息已发生改变是否保存?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                //调用修改货品价格信息方法
                this.updatePriceData([updatePriceData], logContent);
              }).catch(() => {
                this.$message({
                  type: 'info',
                  message: '已取消保存',
                  duration: this.elDuration
                });
              });
            } else { //都不修改
              //判断是否为修改界面保存发送的请求
              if (this.isUpdateSave) {
                //关闭弹框
                this.SHOW_ADDBOX(false);
              }
            }
          } else if (oldTab == 3) { //库存信息
            //定义需要处理的数据
            let updateData = [];
            //定义日志信息
            let logContent = '';
            //处理新增数据
            this.addInventoryPlace.forEach((item, index) => {
              let itemPlace = {
                product_info_id: this.currentUpdateProduct.product.id, //货品id
                house_id: item.houseId, //仓库id
                product_place: item.productPlace, //货品仓位号
                place_ids: item.placeIds, //组成仓位号的id
                place_number: 0, //仓位数量
                max_number: 0, //仓位最大存储量
                bar_code: item.barCode, //仓位编码
                type: 0, //操作类型(新增)
              }
              updateData.push(itemPlace)
            })

            // 获取删除数据
            this.deleteInventoryPlace.forEach((item, index) => {
              let itemPlace = {
                id: item.id, //删除数据的id
                type: -1, //操作类型(删除)
              }
              updateData.push(itemPlace)
            })

            //判断是否有修改仓位号信息
            let isPlaceUpdate = this.judgeProductInfoUpdate(4);
            if (isPlaceUpdate) {
              this.updateInventoryData(updateData, logContent);
            } else {
              //判断是否为修改界面保存发送的请求
              if (this.isUpdateSave) {
                //关闭弹框
                this.SHOW_ADDBOX(false)
              }
            }
          } else if (oldTab == 4) { //产品图片
            //获取修改后的货品信息
            let updateBaseData = this.getUpdateProductData();
            //判断数据是否合法
            if (!updateBaseData) {
              return;
            }
            //定义日志信息
            let logContent = '';
            //判断是否需要修改数据
            let isUpdate = this.judgeProductInfoUpdate(0, updateBaseData);

            if (isUpdate) {
              this.$confirm('货品信息已发生改变是否保存?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                //调用修改货品基本信息方法
                this.updaePorductBaseInfo(updateBaseData, logContent, '货品图片');
              }).catch(() => {
                this.$message({
                  type: 'info',
                  message: '已取消保存',
                  duration: this.elDuration
                });
              });
            } else {
              //判断是否为修改界面保存发送的请求
              if (this.isUpdateSave) {
                //关闭弹框
                this.SHOW_ADDBOX(false)
              }
            }
          } else if (oldTab == 5) { //详细描述
            //获取修改后的货品信息
            let updateBaseData = this.getUpdateProductData();
            //判断数据是否合法
            if (!updateBaseData) {
              return;
            }
            //定义日志信息
            let logContent = '';
            //判断是否需要修改数据
            let isUpdate = this.judgeProductInfoUpdate(0, updateBaseData);
            if (isUpdate) {
              this.$confirm('货品信息已发生改变是否保存?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                //调用修改货品基本信息方法
                this.updaePorductBaseInfo(updateBaseData, logContent, '详细描述');
              }).catch(() => {
                this.$message({
                  type: 'info',
                  message: '已取消保存',
                  duration: this.elDuration
                });
              });
            } else {
              //判断是否为修改界面保存发送的请求
              if (this.isUpdateSave) {
                //关闭弹框
                this.SHOW_ADDBOX(false)
              }
            }
          } else if (oldTab == 6) { //其他信息
            //获取修改后的货品信息
            let updateBaseData = this.getUpdateProductData();
            //判断数据是否合法
            if (!updateBaseData) {
              return;
            }
            //定义日志信息
            let logContent = '';
            //判断是否需要修改数据
            let isUpdate = this.judgeProductInfoUpdate(0, updateBaseData);
            if (isUpdate) {
              this.$confirm('货品信息已发生改变是否保存?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                //调用修改货品基本信息方法
                this.updaePorductBaseInfo(updateBaseData, logContent, '其他信息');
              }).catch(() => {
                this.$message({
                  type: 'info',
                  message: '已取消保存',
                  duration: this.elDuration
                });
              });
            } else {
              //判断是否为修改界面保存发送的请求
              if (this.isUpdateSave) {
                //关闭弹框
                this.SHOW_ADDBOX(false)
              }
            }
          } else if (oldTab == 7) { //修改模式的价格管理
            //定义价格数组
            let proPriceData = [];
            console.log(this.saleAssisUnit);
            //价格基本信息
            let priceBase = {
              id: this.productData.basicData.priceId, //需要修改的数据id
              product_id: this.productData.basicData.productId, //货品id
              price: this.productData.basicData.unitSellingPrice, //销售单价
              cost_price: this.productData.basicData.purchasePrice, //采购单价
              calculate_type: this.productData.basicData.calculateUnitId, //计量单位
              currency: this.productData.basicData.priceUnit, //交易币种
              currency_id: this.productData.basicData.priceUnitId, //交易币种id
              price_sort: 0, //多计量排序
              is_base: 1, //是否基础计量单位
              is_order_default: this.productData.basicData.calculateUnitId == this.saleAssisUnit ? 1 :
              0, //是否销售强制使用计量单位
              is_purchase_default: this.productData.basicData.calculateUnitId == this.purchaseAssisUnit ? 1 :
              0, //是否采购强制使用计量单位
              is_bom_default: this.productData.basicData.calculateUnitId == this.bomAssisUnit ? 1 : 0, //是否bom强制使用计量单位
              type: 1, //类型设置为修改(默认)
              exchange_base: 1, //与基础计量单位换算比例
            }
            //添加基础计量单位
            proPriceData.push(priceBase)
            // 定义主计量(多计量)
            if (JSON.stringify(this.mainMeasureData) != "{}") {
              console.log(this.mainMeasureData)
              let priceMain = {
                id: this.mainMeasureData.id, //需要修改的数据id
                product_id: this.productData.basicData.productId, //货品id
                price: this.mainMeasureData.price, //销售单价
                cost_price:  this.mainMeasureData.purchasePrice, //采购单价
                calculate_type: this.mainMeasureData.unitId, //计量单位
                currency: this.productData.basicData.priceUnit, //交易币种
                currency_id: this.productData.basicData.priceUnitId, //交易币种id
                price_sort: 1, //多计量排序
                is_base: 0, //是否基础计量单位
                is_order_default: this.mainMeasureData.unitId == this.saleAssisUnit ? 1 : 0, //是否销售强制使用计量单位
                is_purchase_default: this.mainMeasureData.unitId == this.purchaseAssisUnit ? 1 : 0, //是否采购强制使用计量单位
                is_bom_default: this.mainMeasureData.unitId == this.bomAssisUnit ? 1 : 0, //是否bom强制使用计量单位
                type: this.mainMeasureData.type, //类型设置为修改(默认)
                exchange_base: parseInt(this.mainMeasureData.count), //与基础计量单位换算比例
              }
              //获取价格数组
              proPriceData.push(priceMain)
            }
            //定义辅计量(多计量)
            if (this.assisMeasureData.length != 0) {
              console.log(this.assisMeasureData)
              // 循环获取辅计量
              this.assisMeasureData.forEach((item, index) => {
                let assisPrice = {
                  id: item.id, //需要修改的数据id
                  product_id: this.productData.basicData.productId, //货品id
                  price: item.price, //销售单价
                  cost_price: item.purchasePrice, //采购单价
                  calculate_type: item.unitId, //计量单位
                  currency: this.productData.basicData.priceUnit, //交易币种
                  currency_id: this.productData.basicData.priceUnitId, //交易币种id
                  price_sort: index + 2, //多计量排序
                  is_base: 0, //是否基础计量单位
                  is_order_default: item.unitId == this.saleAssisUnit ? 1 : 0, //是否销售强制使用计量单位
                  is_purchase_default: item.unitId == this.purchaseAssisUnit ? 1 : 0, //是否采购强制使用计量单位
                  is_bom_default: item.unitId == this.bomAssisUnit ? 1 : 0, //是否bom强制使用计量单位
                  type: item.type, //类型设置为修改(默认)
                }
                //计算换算比例
                if (index == 0) { //与主计量换算比例
                  assisPrice.exchange_base = parseInt(item.count) * parseInt(this.mainMeasureData.count);
                } else { //辅计量换算比例
                  let assis_exchange = 1;
                  for (let i = 1; i <= index; i++) {
                    assis_exchange = parseInt(item.count) * parseInt(this.assisMeasureData[i - 1].count) * parseInt(
                      this.mainMeasureData.count) * assis_exchange;
                  }
                  assisPrice.exchange_base = assis_exchange;
                }
                proPriceData.push(assisPrice)
              })
            }

            // 判断是否有删除的价格数据
            if (this.deleteMeasureData.length > 0) {
              this.deleteMeasureData.forEach((item, index) => {
                let deleteItem = {
                  id: item.id, //需要删除的数据id
                  type: item.type, //类型设置为删除
                }
                proPriceData.push(deleteItem)
              })
            }

            //**************处理价格信息是否发生改变**************
            //定义日志信息
            let logContent = '';
            //判断是否需要修改数据
            let isUpdate = this.judgeProductInfoUpdate(1, proPriceData);

            // **************处理基本信息是否发生改变**************
            let logPriceContent = '';
            //获取修改后的货品信息
            let updateBaseData = this.getUpdateProductData();
            //判断是否需要修改数据
            let isUpdateProcuct = this.judgeProductInfoUpdate(0, updateBaseData);


            if (isUpdate || isUpdateProcuct) {
              this.$confirm('货品价格(多计量)信息已发生改变是否保存?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {

                //判断是否修改价格信息
                if(isUpdate){
                  //调用修改多计量价格方法
                  this.updatePriceData(proPriceData, logContent);
                }

                //判断是否修改基本信息
                if(isUpdateProcuct){
                  //调用修改货品基本信息方法
                  this.updaePorductBaseInfo(updateBaseData, logPriceContent, '基本信息');
                }
              }).catch(() => {
                this.$message({
                  type: 'info',
                  message: '已取消保存',
                  duration: this.elDuration
                });
              });
            } else {
              //判断是否为修改界面保存发送的请求
              if (this.isUpdateSave) {
                //关闭弹框
                this.SHOW_ADDBOX(false)
              }
            }
          }
        else if(oldTab==8){
          //获取修改后的货品信息
          let updateBaseData = this.getUpdateProductData();
          //判断数据是否合法
          if (!updateBaseData) {
            return;
          }
          //定义日志信息
          let logContent = '';
          //判断是否需要修改数据
          let isUpdate = this.judgeProductInfoUpdate(0, updateBaseData);
          if (isUpdate) {
            this.$confirm('货品信息已发生改变是否保存?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              //调用修改货品基本信息方法
              this.updaePorductBaseInfo(updateBaseData, logContent, '包装信息');
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消保存',
                duration: this.elDuration
              });
            });
          } else {
            //判断是否为修改界面保存发送的请求
            if (this.isUpdateSave) {
              //关闭弹框
              this.SHOW_ADDBOX(false)
            }
          }
        }
        }
      },

      /* 获取修改后的货品信息 */
      getUpdateProductData() {
        //获取自定义信息
        let custom_field = '';
        this.productData.otherInformationData.forEach((item, index) => { //其他信息规格的转化
          custom_field = custom_field + item.name + "###" + item.value
          if (index != this.productData.otherInformationData.length - 1) {
            custom_field = custom_field + "@INFOSPEED@"
          }
        })
        //定义返回值
        let updateBaseData = {
          id: this.currentUpdateProduct.product.id, //新增时id为空  修改时id为货品id
          name: this.productData.basicData.productName.trim(), //货品名称
          product_code: this.productData.basicData.productCode.trim(), //货品编码
          product_old_code: this.productData.basicData.sourceProductCode, //原物料编号
          specifications: this.productData.basicData.specificationParameter, //货品规格
          product_type_id: this.productData.basicData.productClassifyId, //货品分类id
          pro_source_id: this.productData.basicData.productSourceId, //货品来源id
          brand_id: this.productData.basicData.productBrandId, //货品品牌id
          pro_property_id: this.productData.basicData.productPropertyId.join(","), //货品属性id
          desciption: this.productData.basicData.simplDescription, //简述
          weight: this.productData.basicData.weightItem, //单个重量
          weight_unit: parseInt(this.productData.basicData.IsWeight), //重量单位
          order_limit_num: this.productData.basicData.minNum, //最小订购数量
          is_group_product: parseInt(this.productData.basicData.IsGroup), //是否可组装
          is_disassembly: parseInt(this.productData.basicData.IsDisassembly), //是否可拆卸
          is_self_order: this.productData.basicData.isSelfOrder == true ? 1 : 0, //允许客户下单
          is_depot: this.productData.basicData.isDepot == true ? 1 : 0, //不参与库存
          is_cost_check: this.productData.basicData.isCostCheck == true ? 1 : 0, //不参与成本核算
          is_bom_merge: this.productData.basicData.isBomMerge == true ? 1 : 0, //BOM合成物料
          pro_imgs: this.productData.productImgData.join(","), //货品图片
          detail_desciption: this.productData.detailData, //货品详情信息
          custom_field: custom_field, //自定义信息
          supplier_id: this.productData.basicData.supplierId, //供应商信息
          supplier_name: this.productData.basicData.supplierName, //供应商信息
          package_size:this.productPackage.packageSize,//包装尺寸
          package_unit:this.productPackage.packingUnit,//包装尺寸单位
          package_volume:this.productPackage.packingvolume,//体积
          package_volume_unit:this.productPackage.packingvolumeUnit,//体积单位
          packing_num:this.productPackage.packingNum,//每包装产品数
          packing_min_num:this.productPackage.packingminNum,//最小包装量
          packing_gross_num:this.productPackage.packingGrossNum,//包装毛重
          packing_gross_num_unit:this.productPackage.packingGrossNumUnit,//包装毛重单位
          packing_detail:this.productPackage.packingDetail,//包装说明
        }
        // 将为null的字段设置为""
        for (let i in updateBaseData) {
          if (updateBaseData[i] == null) {
            updateBaseData[i] = "";
          }
        }
        //货品区间价信息
        let sectionPrice = this.getIntervalPrice();
        if (sectionPrice != '' && !sectionPrice) {
          return false;
        }
        updateBaseData.subsection_price_rule = sectionPrice;
        return updateBaseData;
      },

      /* 判断各界面货品基本信息数据是否发生改变 */
      judgeProductInfoUpdate(type, data) {
        //定义返回值
        let result = true;
        //判断哪个tab页的基本信息
        if (type == 0) { //基本信息
          let productInfo = this.currentUpdateProduct.product;
          //定义旧数据
          let oldProductInfo = {
            id: productInfo.id == null ? '' : productInfo.id, //新增时id为空  修改时id为货品id
            name: productInfo.name == null ? '' : productInfo.name, //货品名称
            product_code: productInfo.product_code == null ? '' : productInfo.product_code, //货品编码
            product_old_code: productInfo.product_old_code == null ? '' : productInfo.product_old_code, //原物料编号
            specifications: productInfo.specifications == null ? '' : productInfo.specifications, //货品规格
            product_type_id: productInfo.product_type_id == null ? '' : productInfo.product_type_id, //货品分类id
            pro_source_id: productInfo.pro_source_id == null ? '' : productInfo.pro_source_id, //货品来源id
            brand_id: productInfo.brand_id == null ? '' : productInfo.brand_id, //货品品牌id
            pro_property_id: productInfo.pro_property_id == null ? '' : productInfo.pro_property_id, //货品属性id
            desciption: productInfo.desciption == null ? '' : productInfo.desciption, //简述
            weight: productInfo.weight == null ? '' : productInfo.weight, //单个重量
            weight_unit: parseInt(productInfo.weight_unit) == null ? '' : parseInt(productInfo.weight_unit), //重量单位
            order_limit_num: productInfo.order_limit_num == null ? '' : productInfo.order_limit_num, //最小订购数量
            is_group_product: productInfo.is_group_product == null ? '' : productInfo.is_group_product, //是否可组装
            is_disassembly: productInfo.is_disassembly == null ? '' : productInfo.is_disassembly, //是否可拆卸
            is_self_order: productInfo.is_self_order == null ? '' : productInfo.is_self_order, //是否自主下单(0:否  1:是)
            is_depot: productInfo.is_depot == null ? '' : productInfo.is_depot, //不参与库存
            is_cost_check: productInfo.is_cost_check == null ? '' : productInfo.is_cost_check, //不参与成本核算
            is_bom_merge: productInfo.is_bom_merge == null ? '' : productInfo.is_bom_merge, //BOM合成物料
            pro_imgs: productInfo.pro_imgs == null ? '' : productInfo.pro_imgs, //货品图片
            detail_desciption: productInfo.detail_desciption == null ? '' : productInfo.detail_desciption, //货品详情信息
            custom_field: productInfo.custom_field == null ? '' : productInfo.custom_field, //自定义信息
            supplier_id: productInfo.supplier_id == null ? '' : productInfo.supplier_id, //供应商信息(id)
            supplier_name: productInfo.supplier_name == null ? '' : productInfo.supplier_name, //供应商信息(名称)
              package_size:productInfo.package_size==null?'':productInfo.package_size,//包装尺寸
              package_unit:productInfo.package_unit==null?'':productInfo.package_unit,//包装尺寸单位
              package_volume:productInfo.package_volume==null?'':productInfo.package_volume,//体积
              package_volume_unit:productInfo.package_volume_unit==null?'':productInfo.package_volume_unit,//体积单位
              packing_num:productInfo.packing_num==null?'':productInfo.packing_num,//每包装产品数
              packing_min_num:productInfo.packing_min_num==null?'':productInfo.packing_min_num,//最小包装量
              packing_gross_num:productInfo.packing_gross_num==null?'':productInfo.packing_gross_num,//包装毛重
              packing_gross_num_unit:productInfo.packing_gross_num_unit==null?'':productInfo.packing_gross_num_unit,//包装毛重单位
              packing_detail:productInfo.packing_detail==null?'':productInfo.packing_detail,//包装说明
              subsection_price_rule: productInfo.subsection_price_rule == null ? '' : productInfo
                .subsection_price_rule, //货品区间价
          }

          // console.log(JSON.stringify(oldProductInfo));
          // console.log(JSON.stringify(data));
          // console.log(JSON.stringify(oldProductInfo) == JSON.stringify(data));

          //判断数据是否相同
          if (JSON.stringify(oldProductInfo) == JSON.stringify(data)) {
            result = false;
          }
        } else if (type == 1) { //价格管理信息
          //获取原数据信息(需保证字段顺序一致)
          let oldPriceData = [];
          this.currentUpdateProduct.prices.forEach((item, index) => {
            let priceItem = {
              id: item.id, //数据id
              product_id: item.product_id, //货品id
              price: item.price, //单价
              cost_price: item.cost_price, //成本价
              calculate_type: item.calculate_type, //计量单位id
              currency: item.currency, //交易币种名称
              currency_id: item.currency_id, //交易币种id
              price_sort: item.price_sort, //多计量排序
              is_base: item.is_base, //是否基础计量单位
              is_order_default: item.is_order_default, //是否销售强制使用计量单位
              is_purchase_default: item.is_purchase_default, //是否采购强制使用计量单位
              is_bom_default: item.is_bom_default, //是否bom强制使用计量单位
              type: 1, //类型
              exchange_base: item.exchange_base, //与本币换算率
            }
            oldPriceData.push(priceItem);
          })
          //判断数据是否相同
          if (JSON.stringify(oldPriceData) == JSON.stringify(data)) {
            result = false;
          }
        } else if (type == 3) { //仓库预警规则

        } else if (type == 4) { //仓库仓位号
          //判断是否有修改过仓位号信息
          if (this.addInventoryPlace.length == 0 && this.deleteInventoryPlace.length == 0) {
            result = false;
          }
        } else if (type == 8) { //基本信息中的价格信息
          //获取基础计量单位信息
          let basePrice = this.currentUpdateProduct.prices.filter(item => item.is_base == 1)[0];
          //获取旧数据
          let oldProductInfo = {
            id: basePrice.id, //新增时id为空  修改时id为货品id
            calculate_type: basePrice.calculate_type, //价格计量单位
            currency_id: basePrice.currency_id, //交易币种id
            currency: basePrice.currency, //交易币种名称
            type: 1, //设置类型为修改
          }

          //判断数据是否相同
          if (JSON.stringify(oldProductInfo) == JSON.stringify(data)) {
            result = false;
          }
        }
        return result;
      },

      /* 修改货品基本信息数据 */
      updaePorductBaseInfo(data, logContent, msg) {
        //定义后端接受参数
        let updateData = {
          logContent: logContent, //日志内容
          productInfo: JSON.stringify(data), //货品基本信息数据
          type: 0, //设置为极简模式
        }
        //发送请求
        api.addCommodity(updateData).then(res => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: msg + '信息已保存!',
              duration: this.elDuration
            })
            //获取修改后的数据
            this.$parent.updateProduct(this.currentUpdateProduct.product, 1);
            //判断是否为修改界面保存发送的请求
            if (this.isUpdateSave) {
              //关闭弹框
              this.SHOW_ADDBOX(false)
            }
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 修改货品价格方法 */
      updatePriceData(data, logContent) {
        //定义修改数据
        let updateData = {
          prices: JSON.stringify(data),
          logContent: logContent
        }
        //发送请求
        api.updateProductPrice(updateData).then(res => {
          if (res.code == 200) {
            if (this.tabIndex == 0) {
              // this.$message({
              //   type: 'success',
              //   message: '基本信息已保存',
              //   duration: this.elDuration
              // })
            } else if (this.tabIndex == 7) {
              // this.$message({
              //   type: 'success',
              //   message: '价格管理信息已保存',
              //   duration: this.elDuration
              // })
            }
            //判断是否为修改界面保存发送的请求
            if (this.isUpdateSave) {
              //关闭弹框
              this.SHOW_ADDBOX(false)
            }
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 修改货品仓位号信息数据 */
      updateInventoryData(data, logContent) {
        //定义修改数据
        let updateData = {
          productInfoPlace: JSON.stringify(data),
          logContent: logContent
        }
        //发送请求
        api.updateProductPlace(updateData).then(res => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '库存信息已保存',
              duration: this.elDuration
            })
            //判断是否为修改界面保存发送的请求
            if (this.isUpdateSave) {
              //关闭弹框
              this.SHOW_ADDBOX(false)
            }
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 保存提交事件 */
      commit() {
        // 判断当前操作界面
        if (this.Is_add == 0) { //新增界面
          // 验证数据是否合法
          if (!this.judgeProductLegal()) {
            return
          }
          //调用添加货品基本信息方法
          this.addProductInfo();
        } else if (this.Is_add == 1) { //修改界面
          //设置保存按钮类型为修改界面
          this.isUpdateSave = true;
          //调用修改货品基本信息方法
          this.judgeStep(-1, this.tabIndex)
        }
      },

      /* 验证货品基本信息合法性 */
      judgeProductLegal() {
        if (this.productData.basicData.productName.trim() == '') { //货品名称必填
          this.$message({
            type: 'warning',
            message: '请填写货品名称',
            duration: this.elDuration
          })
          return false
        }
        if (this.productData.basicData.productCode.trim() == '') { //货品编码称必填
          this.$message({
            type: 'warning',
            message: '请填写货品编码',
            duration: this.elDuration
          })
          return false
        }
        if (this.productData.basicData.productClassify == '') { //货品分类必填
          this.$message({
            type: 'warning',
            message: '请选择货品分类',
            duration: this.elDuration
          })
          return false
        }
        if (this.productData.basicData.unitSellingPrice === '' && this.paraVals.salePrice == 0) {
          this.$message({
            type: 'warning',
            message: '请填写销售单价',
            duration: this.elDuration
          })
          return false
        }
        if (this.productData.basicData.purchasePrice === '' && this.paraVals.purchasePrice == 0) {
          this.$message({
            type: 'warning',
            message: '请填写采购单价',
            duration: this.elDuration
          })
          return false
        }
        if (this.productData.basicData.specificationParameter == '' && this.paraVals.specification == 0 && !this.isEnhance) {
          this.$message({
            type: 'warning',
            message: '请填写规格信息',
            duration: this.elDuration
          })
          return false
        }
        if (this.productData.basicData.supplierName == '' && this.paraVals.supplierName == 0) {
          this.$message({
            type: 'warning',
            message: '请选择供应商',
            duration: this.elDuration
          })
          return false
        }
        if (this.productData.basicData.minNum === '' && this.paraVals.minNum == 0) {
          this.$message({
            type: 'warning',
            message: '请填写起订量',
            duration: this.elDuration
          })
          return false
        }
        if (this.productData.basicData.weightItem === '' && this.paraVals.weightItem == 0) {
          this.$message({
            type: 'warning',
            message: '请填写单个重量',
            duration: this.elDuration
          })
          return false
        }
        if (this.productData.basicData.simplDescription == '' && this.paraVals.simplDescription == 0) {
          this.$message({
            type: 'warning',
            message: '请填写商品简介',
            duration: this.elDuration
          })
          return false
        }
        if (this.productData.productImgData.length == 0 && this.paraVals.productImgData == 0) {
          this.$message({
            type: 'warning',
            message: '请上传图片',
            duration: this.elDuration
          })
          return false
        }
        if (JSON.stringify(this.productData.detailData) == '' && this.paraVals.detailData == 0) {
          this.$message({
            type: 'warning',
            message: '请填写详细描述',
            duration: this.elDuration
          })
          return false
        }
        //验证货品库存
        if (this.productData.stockData.length == 0) {
          this.$message({
            type: 'warning',
            message: '请至少选择一个货品存放仓库',
            duration: this.elDuration
          })
          return false
        }
        //验证区间价
        // console.log(this.productData);
        // if(this.productData.tableData_sellSection.length>0){
        //   this.productData.tableData_sellSection.forEach((item,index)=>{

        //   })
        // }
        return true;
      },

      /* 关闭新增弹框 */
      cancle_box() {
        if (this.Is_add == 0) {
          this.$confirm('您正在新增货品,是否退出', '提示', {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.tabIndex = '0';
            //关闭弹框
            this.SHOW_ADDBOX(false);
            //设置为取消
            this.IS_CANCEL(true);
          }).catch(() => {})
        } else if (this.Is_add == 1) {
          this.$confirm('您正在修改货品,是否退出', '提示', {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.tabIndex = '0'
            this.SHOW_ADDBOX(false);
            //设置为取消
            this.IS_CANCEL(true);
          }).catch(() => {})
        }
      },

      /* 获取区间价信息 */
      getIntervalPrice() {
        let gradeNames = [] //销售区间价数量存储
        let isPass = true //是否符合要求
        this.productData.tableData_sellSection.forEach(item => {
          gradeNames.push(item.gradeName1, item.gradeName2)
        })
        if (gradeNames.length > 2) { //当填写的区间数量超出两个时
          gradeNames.forEach((item, index) => {
            //排除第一个跟最后一个,其余的元素进行比对,如果后面一个数大于前面一个数说明不是递增情况,则不符合要求
            if (index != 0 && index != gradeNames.length - 1 && Number(item) < Number(gradeNames[index - 1])) {
              isPass = false //不符合要求
            }
          })
        } else {
          if (Number(gradeNames[1]) < Number(gradeNames[0]) && gradeNames[1] != '') { //当截止量大于起订量且截止量不为空时
            isPass = false //不符合要求
          }
        }
        //判断区间数量是否符合要求
        if (!isPass) {
          this.$message({
            type: 'warning',
            message: '请填写正确的区间数量',
            duration: this.elDuration
          })
          return false;
        }
        let sectionPrice = "";
        this.productData.tableData_sellSection.forEach((item, index) => { //区间价
          //若没填结尾,标识正无穷
          if (item.gradeName2.trim() == "") {
            item.gradeName2 = "+∞";
          }
          //处理价格
          if(item.price.trim() == ""){
            item.price = 0;
          }
          //处理税率
          if(item.taxrate.trim() == ""){
            item.taxrate = 0;
          }
          //处理备注
          if(item.remark.trim() == ""){
            item.remark = '-';
          }
          //获取数据库存储字符串
          sectionPrice = sectionPrice + item.gradeName1 + "##" + item.gradeName2 + "##" + item.price + "##" + item
            .taxrate + "##" + item.remark
          if (index != this.productData.tableData_sellSection.length - 1) {
            sectionPrice = sectionPrice + "#####"
          }
        })
        return sectionPrice;
      },

      /* 添加货品数据信息方法 */
      addProductInfo() {
        //1.定义货品基本信息
        //获取自定义信息
        let custom_field = ''
        this.productData.otherInformationData.forEach((item, index) => { //其他信息规格的转化
          custom_field = custom_field + item.name + "###" + item.value
          if (index != this.productData.otherInformationData.length - 1) {
            custom_field = custom_field + "@INFOSPEED@"
          }
        })
        //货品区间价信息
        let sectionPrice = this.getIntervalPrice();
        if (sectionPrice != '' && !sectionPrice) {
          return
        }

        //获取货品基本信息
        let proBaseData = {
          id: this.Is_add == 1 ? this.currentUpdateProduct.product.id : '', //新增时id为空  修改时id为货品id
          name: this.productData.basicData.productName.trim(), //货品名称
          product_code: this.productData.basicData.productCode.trim(), //货品编码
          product_old_code: this.productData.basicData.sourceProductCode, //原物料编号
          specifications: this.productData.basicData.specificationParameter.join("-"), //货品规格
          pro_imgs: this.productData.productImgData.join(","), //货品图片
          product_type_id: this.productData.basicData.productClassifyId, //货品分类id
          pro_source_id: this.productData.basicData.productSourceId, //货品来源id
          brand_id: this.productData.basicData.productBrandId, //货品品牌id
          pro_property_id: this.productData.basicData.productPropertyId.join(","), //货品属性id
          desciption: this.productData.basicData.simplDescription, //简述
          detail_desciption: this.productData.detailData, //货品详细描述
          supplier_id: this.productData.basicData.supplierId, //供应商id
          supplier_name: this.productData.basicData.supplierName, //供应商名称
          weight: this.productData.basicData.weightItem, //单个重量
          weight_unit: parseInt(this.productData.basicData.IsWeight), //重量单位
          subsection_price_rule: sectionPrice, //区间价
          order_limit_num: this.productData.basicData.minNum, //最小订购数量
          is_group_product: this.productData.basicData.IsGroup, //是否可组装
          is_disassembly: this.productData.basicData.IsDisassembly, //是否可拆卸
          is_self_order: this.productData.basicData.isSelfOrder == true ? 1 : 0, //允许客户下单
          is_depot: this.productData.basicData.isDepot == true ? 1 : 0, //不参与库存
          is_cost_check: this.productData.basicData.isCostCheck == true ? 1 : 0, //不参与成本核算
          is_bom_merge: this.productData.basicData.isBomMerge == true ? 1 : 0, //BOM合成物料
          custom_field: custom_field, //自定义信息
          package_size:this.productPackage.packageSize,//包装尺寸
          package_unit:this.productPackage.packingUnit,//包装尺寸单位
          package_volume:this.productPackage.packingvolume,//体积
          package_volume_unit:this.productPackage.packingvolumeUnit,//体积单位
          packing_num:this.productPackage.packingNum,//每包装产品数
          packing_min_num:this.productPackage.packingminNum,//最小包装量
          packing_gross_num:this.productPackage.packingGrossNum,//包装毛重
          packing_gross_num_unit:this.productPackage.packingGrossNumUnit,//包装毛重单位
          packing_detail:this.productPackage.packingDetail,//包装说明
        }
        // 2.定义货品价格数据
        let proPriceData;
        //定义基础计量单位及价格
        let priceBase = {
          price: this.productData.basicData.unitSellingPrice, //销售单价
          cost_price: this.productData.basicData.purchasePrice, //采购单价
          calculate_type: this.productData.basicData.calculateUnitId, //计量单位
          currency: this.productData.basicData.priceUnit, //交易币种
          currency_id: this.productData.basicData.priceUnitId, //交易币种id
          is_base: 1, //是否基础计量单位
          price_sort: 0, //多计量排序
          exchange_base: 1, //与基础计量单位换算比例
          is_order_default: this.productData.basicData.calculateUnitId == this.saleAssisUnit ? 1 : 0, //是否销售强制使用计量单位
          is_purchase_default: this.productData.basicData.calculateUnitId == this.purchaseAssisUnit ? 1 :
          0, //是否采购强制使用计量单位
          is_bom_default: this.productData.basicData.calculateUnitId == this.bomAssisUnit ? 1 : 0, //是否bom强制使用计量单位
        }

        // 判断模式
        if (!this.isEnhance) { //极简模式
          //获取价格数组
          proPriceData = priceBase;
        } else { //增强模式
          proPriceData = []
          //添加基础计量单位
          proPriceData.push(priceBase)
          // 定义主计量(多计量)
          if (JSON.stringify(this.mainMeasureData) != "{}") {
            let priceMain = {
              price: this.mainMeasureData.price, //销售单价
              cost_price: this.mainMeasureData.purchasePrice, //采购单价
              calculate_type: this.mainMeasureData.unitId, //计量单位
              currency: this.productData.basicData.priceUnit, //交易币种
              currency_id: this.productData.basicData.priceUnitId, //交易币种id
              is_base: 0, //是否基础计量单位
              price_sort: 1, //多计量排序
              exchange_base: parseInt(this.mainMeasureData.count), //与基础计量单位换算比例
              is_order_default: this.mainMeasureData.unitId == this.saleAssisUnit ? 1 : 0, //是否销售强制使用计量单位
              is_purchase_default: this.mainMeasureData.unitId == this.purchaseAssisUnit ? 1 : 0, //是否采购强制使用计量单位
              is_bom_default: this.mainMeasureData.unitId == this.bomAssisUnit ? 1 : 0, //是否bom强制使用计量单位
            }
            //获取价格数组
            proPriceData.push(priceMain)
          }
          //定义辅计量(多计量)
          if (this.assisMeasureData.length != 0) {
            // 循环获取辅计量
            this.assisMeasureData.forEach((item, index) => {
              let assisPrice = {
                price: item.price, //销售单价
                cost_price: item.purchasePrice, //采购单价
                calculate_type: item.unitId, //计量单位
                currency: this.productData.basicData.priceUnit, //交易币种
                currency_id: this.productData.basicData.priceUnitId, //交易币种id
                is_base: 0, //是否基础计量单位
                price_sort: index + 2, //多计量排序
                is_order_default: item.unitId == this.saleAssisUnit ? 1 : 0, //是否销售强制使用计量单位
                is_purchase_default: item.unitId == this.purchaseAssisUnit ? 1 : 0, //是否采购强制使用计量单位
                is_bom_default: item.unitId == this.bomAssisUnit ? 1 : 0, //是否bom强制使用计量单位
              }
              //计算换算比例
              if (index == 0) { //与主计量换算比例
                assisPrice.exchange_base = parseInt(item.count) * parseInt(this.mainMeasureData.count);
              } else { //辅计量换算比例
                let assis_exchange = 1;
                for (let i = 1; i <= index; i++) {
                  assis_exchange = parseInt(item.count) * parseInt(this.assisMeasureData[i - 1].count) * parseInt(
                    this.mainMeasureData.count) * assis_exchange;
                }
                assisPrice.exchange_base = assis_exchange;
              }
              proPriceData.push(assisPrice)
            })
          }
          console.log(proPriceData);
          //判断是否有添加规格,若没添加规格则默认添加价格数据
          if (this.productData.specificationsData.length == 0) {
            this.productData.specificationsData.push({
              specName: '',
              prices: proPriceData
            })
          }
        }

        //3.定义产品库存信息
        let proDepotData = []; //库存数量
        let proDepotRuleData = []; //库存规则
        let proDepotPlaceData = []; //仓位号数据
        //获取库存数据
        if (this.productData.stockData.length > 0) {
          //循环获取已填仓库数据
          this.productData.stockData.forEach((item, index) => {
            //定义库存数量数据
            let warehouseItem = {
              id: this.Is_add == 1 ? item.wareHouseNmberId : null, //库存数量表主键id
              warehouse_id: item.id, //库存id
              warehouseNumber: item.initInventory, //初始库存(随入库单累加)
              init_number: item.initInventory, //期初库存(只能添加一次)
              product_batch: item.batchStr, //货品批次号
              cost_price: item.price, //当前批次号成本价
              bills_type_id: item.bills_type_id, //生成批次号的单据模板id
              usable_number: item.initInventory, //账面数量(可用库存)只能添加一次
            }
            proDepotData.push(warehouseItem)
            //定义库存规则数据
            let warehouseRuleItem = {
              id: this.Is_add == 1 ? item.wareHouseRuleId : null, //库存规则表主键id
              warehouse_id: item.id, //仓库Id
              max_warning: item.inventoryUpper, //库存上限
              min_warning: item.inventoryFloor, //库存下限
              remind_day: 7, //过期提醒天数(默认设置7天)
            }
            proDepotRuleData.push(warehouseRuleItem)
            //定义库存仓位号数据
            item.place.forEach((itemJ, indexJ) => {
              let wareHousePlaceItem = {
                house_id: item.id, //仓库id
                product_place: itemJ.productPlace, //货品仓位号
                place_ids: itemJ.placeIds, //组成仓位号的id
                place_number: 0, //仓位数量
                max_number: 0, //仓位最大存储量
                bar_code: itemJ.productCode, //仓位编码
              }
              proDepotPlaceData.push(wareHousePlaceItem)
            })
          })
        }

        //4.定义传入后端的数据
        let data = {
          productInfo: JSON.stringify(proBaseData), //货品基本信息
          productNumber: JSON.stringify(proDepotData), //货品库存数量信息
          stockWarningRules: JSON.stringify(proDepotRuleData), //货品库存预警信息
          productInfoPlace: JSON.stringify(proDepotPlaceData), //货品仓位信息
        }
        // 判断哪种模式(根据不同模式获取不同数据)
        if (!this.isEnhance) { //极简模式
          data.type = 0;
          data.productPrice = JSON.stringify(proPriceData); //货品价格信息
        } else { //增强模式
          data.type = 1;
          data.specPriceVO = JSON.stringify(this.productData.specificationsData);
        }

        //判断是新增还是修改(根据不同传递不同参数)
        if (this.Is_add == 1) { //修改
          data.prices = JSON.stringify(this.productData.basicData.multiUnitSellingPrice);
        }
        /* 发起新增或者修改请求 */
        api.addCommodity(data).then(res => {
          if (res.code == 200) {
            if (this.Is_add == 0) {
              this.tabIndex = '0'
              this.isEnhance = false
              this.$message({
                type: 'success',
                message: '新增货品成功',
                duration: 1500
              })
              //关闭弹框
              this.SHOW_ADDBOX(false);
            } else {
              this.$message({
                type: 'success',
                message: '修改货品成功',
                duration: 1500
              })
              //关闭弹框
              this.SHOW_ADDBOX(false);
            }
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: 1500
            })
          }
        })
      },

      /* 修改模式 */
      updateIsEnhance(val){
        this.isEnhance = val;
      },
    },
    components: {
      basicMessage,
      priceManage,
      imgManage,
      specifaction,
      inventoryMsg,
      detailDesribe,
      otherMesg,
      packing
    }
  }
</script>

<style lang="less">
  /* 新增货品内容 */
  .productAll {
    height: 100%;

    /* border: 1px solid black; */
    // 选项卡高度
    .productTab_item {
      height: 45vh;
      overflow: auto;
    }
  }

  .dialogBox {
    display: flex;

    .itemText {
      font-size: 16px;
      padding-right: 20px;
      // border: 1px solid black;
    }
  }
</style>
