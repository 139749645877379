<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：新建规格选择页面
	开始时间：2021-01-14
	开发人员：刘巍骏,万攀
	最后修改：2021-05-01
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="specifactionAll productTab_item">
    <!-- 规格选择框 -->
    <div class="spe_Box">
      <div class="spe_title">规格选择</div>
      <div class="spe_table">
        <table>
          <thead>
            <tr>
              <th width="40px">序号</th>
              <th>规格名称</th>
              <th>规格值</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item,index) in specsData">
              <td>{{index+1}}</td>
              <td>{{item.name}}</td>
              <td>
                <el-checkbox-group v-model="item.checkList">
                  <el-checkbox v-for="(obj,index) in item.children" :label="obj.name"></el-checkbox>
                </el-checkbox-group>
              </td>
            </tr>
            <tr v-show="specsData.length==0">
              <td colspan="3">该货品分类下暂无规格信息</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="spe_btn">
        <el-button size="mini" :disabled="specsData.length==0" type="primary" @click="getTableData">生成组合</el-button>
        <span class="tips">如果生成组合,则会生成多个货品</span>
      </div>
    </div>

    <!-- 规格组合框 -->
    <div class="spe_Box">
      <div class="spe_title">规格组合</div>
      <div class="spe_table">
        <table>
          <thead>
            <tr>
              <th width="40px">序号</th>
              <th>规格组合</th>
              <th>{{productData.basicData.priceUnit}}/{{productData.basicData.calculateUnit}}</th>
              <th v-if="JSON.stringify(mainMeasureData)!='{}'">{{productData.basicData.priceUnit}}/{{mainMeasureData.unit}}</th>
              <th v-if="assisMeasureData.length>0" v-for="elem in assisMeasureData">{{productData.basicData.priceUnit}}/{{elem.unit}}</th>
              <th width="30px">操作</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(elem,index) in datacomb">
              <td>{{index + 1}}</td>
              <td>
                <input type="text" v-model="elem.combName" placeholder="请输入组合名称">
              </td>
              <td>
                <input type="text" v-model="elem.comb" placeholder="请输入对应价格">
              </td>
              <td v-if="JSON.stringify(mainMeasureData)!='{}'">
                <input type="text" v-model="elem.mainMeasure.price" placeholder="请输入对应价格">
              </td>
              <td v-if="assisMeasureData.length>0" v-for="elemi in elem.assisMeasure">
                <input type="text" v-model="elemi.price" placeholder="请输入对应价格">
              </td>
              <td>
                <i class="el-icon-delete" @click="remove_Data(index)"></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  export default {
    name: 'specifactionCreate',
    data() {
      return {
        datacomb: [], //生成规格与对应价格数据
        assisMeasure: [], //保存辅计量的数据
        specPriceVO: [], //保存转换之后的列表数据
        newSpecData: { //用于清空对象
          specName: '',
          prices: []
        },
        speData: [], //规格表格的数据
      }
    },
    computed: {
      ...mapState({
        show_addBox: state => state.datum.show_addBox, //控制新增弹框
        productData: state => state.datum.productData, //货品数据
        mainMeasureData: state => state.datum.mainMeasureData, //主计量信息
        assisMeasureData: state => state.datum.assisMeasureData, //辅计量信息
        specsData: state => state.datum.specsData, //根据分类保存规格信息
      }),
    },
    watch: {
      //监听生成多规格价格
      datacomb: {
        handler(newVal) {
          this.specPriceVO = []
          this.createSpec();
        },
        deep: true,
      },
      show_addBox(newVal) {
        if (newVal == false) {
          this.datacomb = []
          this.PRODUCT_DATA({
            specPriceVO: []
          })
        }
      },
      //监听根据货品分类查询到的规格数据
      specsData: {
        handler(newVal) {
          this.speData = newVal;
        },
        deep: true,
      },
      // 监听多规格数据
      specPriceVO: {
        handler(newVal) {
          this.PRODUCT_DATA({
            specPriceVO: newVal
          });
        },
        deep: true,
      }
    },
    methods: {
      ...mapMutations([
        'PRODUCT_DATA'
      ]),

      /* 获取表格数据 */
      getTableData() {
        let data = []
        // 循环添加已选规格
        this.speData.forEach(item => {
          if (item.checkList.length != 0) {
            data.push(item.checkList);
          }
        })
        if (data.length <= 1) {
          this.datacomb = [] //清空数组
          data[0].forEach(item => {
            let mainMeasure = JSON.parse(JSON.stringify(this.mainMeasureData));
            let assisMeasure = []
            this.assisMeasureData.forEach(item => {
              let obj = {};
              obj = JSON.parse(JSON.stringify(item))
              assisMeasure.push(obj)
            })
            let obj = {
              comb: '',
              combName: '',
              combsim: '',
              mainMeasure: '',
              assisMeasure: [],
            }
            obj.combName = item
            obj.comb = this.productData.basicData.unitSellingPrice
            obj.mainMeasure = mainMeasure
            obj.combsim = ''
            obj.assisMeasure = assisMeasure
            this.datacomb.push(obj)
          })
        } else {
          this.datacomb = data.reduce((last, current) => {
            const array = [];
            last.forEach(par1 => {
              current.forEach(par2 => {
                let mainMeasure = JSON.parse(JSON.stringify(this.mainMeasureData));
                let assisMeasure = []
                this.assisMeasureData.forEach(item => {
                  let obj = {};
                  obj = JSON.parse(JSON.stringify(item))
                  assisMeasure.push(obj)
                })
                let obj = {
                  comb: '',
                  combName: '',
                  combsim: '',
                  mainMeasure: '',
                  assisMeasure: [],
                }
                console.log(par1, par2);
                if (typeof par1 == 'object') {
                  obj.combName = par1.combName + "-" + par2
                } else {
                  obj.combName = par1 + "-" + par2
                }
                obj.comb = this.productData.basicData.unitSellingPrice
                obj.mainMeasure = mainMeasure
                obj.combsim = ''
                obj.assisMeasure = assisMeasure
                array.push(obj)
              });
            });
            return array;
          })
        }
        this.createSpec();
        // if(this.speData.checkList.length>0){
        //   for(let i=0;i<this.checkList.length;i++){
        //     if(this.checkList1.length>0){
        //       for(let j=0;j<this.checkList1.length;j++){
        //         if(this.checkList2.length>0){
        //           for(let z=0;z<this.checkList2.length;z++){
        //             let combName = ""
        //             combName = this.checkList[i] + '-' +this.checkList1[j] + "-" + this.checkList2[z];
        //             let mainMeasure = JSON.parse(JSON.stringify(this.mainMeasureData));
        // let assisMeasure = []
        //  this.assisMeasureData.forEach(item=>{
        //     let obj = {};
        //     obj = JSON.parse(JSON.stringify(item))
        //     assisMeasure.push(obj)
        //  })
        //             data.push({
        //               combName:combName,
        //               combsim:'',
        //               comb:this.productData.basicData.unitSellingPrice,
        //               mainMeasure,
        //               assisMeasure,
        //             })
        //           }
        //         }else{
        //           let combName = ""
        //           combName = this.checkList[i] + '-' +this.checkList1[j];
        //           let mainMeasure = JSON.parse(JSON.stringify(this.mainMeasureData));
        //           let assisMeasure = []
        //            this.assisMeasureData.forEach(item=>{
        //               let obj = {};
        //               obj = JSON.parse(JSON.stringify(item))
        //               assisMeasure.push(obj)
        //            })
        //           data.push({
        //             combName:combName,
        //             combsim:'',
        //             comb:this.productData.basicData.unitSellingPrice,
        //             mainMeasure,
        //             assisMeasure,
        //           })
        //         }
        //       }
        //     }else{
        //       let combName = ""
        //       combName = this.checkList[i];
        //       let mainMeasure = JSON.parse(JSON.stringify(this.mainMeasureData));
        //       let assisMeasure = []
        //        this.assisMeasureData.forEach(item=>{
        //           let obj = {};
        //           obj = JSON.parse(JSON.stringify(item))
        //           assisMeasure.push(obj)
        //        })
        //       data.push({
        //         combName:combName,
        //         combsim:'',
        //         comb:this.productData.basicData.unitSellingPrice,
        //         mainMeasure,
        //         assisMeasure,
        //       })
        //     }
        //   }
        //   this.datacomb = data;
        //   console.log(data);
        // }
        // this.createSpec();
      },

      /* 生成后端需要的数据结构 */
      createSpec() {
        for (let i = 0; i < this.datacomb.length; i++) {
          let specData = { //初始化对象
            specName: '',
            prices: []
          }
          specData.specName = this.datacomb[i].combName //规格名
          let obj = {}
          obj.price = this.datacomb[i].comb //价格
          obj.calculate_type = this.productData.basicData.calculateUnitId //计量单位ID
          obj.currency = this.productData.basicData.priceUnit //币种
          obj.currency_id = this.productData.basicData.priceUnitId, //交易币种id
          obj.cost_price = this.productData.basicData.purchasePrice //采购单价
          obj.is_base = 1;
          obj.exchange_base="", //与基础计量单位换算比例
          // obj.weight = this.productData.basicData.weightItem //重量
          // obj.order_limit_num = this.productData.basicData.minNum //起订量
          specData.prices.push(obj)
          if (this.datacomb[i].mainMeasure.price != undefined) { //如果主计量不为空添加主计量
            obj = {}
            obj.price = this.datacomb[i].mainMeasure.price //价格
            obj.calculate_type = this.datacomb[i].mainMeasure.unitId //计量单位Id
            obj.currency = this.productData.basicData.priceUnit //币种
            obj.currency_id = this.productData.basicData.priceUnitId, //交易币种id
            obj.cost_price = this.datacomb[i].mainMeasure.purchasePrice //采购单价
            obj.is_base = 0;
            obj.exchange_base=this.datacomb[i].mainMeasure.count, //与基础计量单位换算比例
            // obj.order_limit_num = this.productData.basicData.minNum //起订量
            // obj.weight = this.productData.basicData.weightItem //重量
            specData.prices.push(obj)
          }
          // 判断是否存在辅计量
          if(this.datacomb[i].assisMeasure.length >0 ){
            for (let j = 0; j < this.datacomb[i].assisMeasure.length; j++) { //添加辅计量
              obj = {}
              obj.price = this.datacomb[i].assisMeasure[j].price //价格
              obj.calculate_type = this.datacomb[i].assisMeasure[j].unitId //计量单位Id
              obj.currency = this.productData.basicData.priceUnit //币种
              obj.currency_id = this.productData.basicData.priceUnitId, //交易币种id
              obj.cost_price = this.datacomb[i].assisMeasure.purchasePrice //采购单价
              obj.is_base = 0;
              obj.exchange_base=this.datacomb[i].assisMeasure.count, //与基础计量单位换算比例
              // obj.order_limit_num = this.productData.basicData.minNum //起订量
              // obj.weight = this.productData.basicData.weightItem //重量
              specData.prices.push(obj)
            }
          }
          this.specPriceVO.push(specData)
        }
      },
      /* 删除规格 */
      remove_Data(index) {
        this.datacomb.splice(index, 1)
      },
    }
  }
</script>

<style lang="less" scoped="scoped">
  .specifactionAll {

    // 图标移入样式
    i:hover {
      cursor: pointer;
      color: @theme;
    }

    //表格而央视
    table {
      border-collapse: collapse;

      thead {
        tr {
          th {
            height: 30px;
            text-align: center;
            padding: 0 10px;
            border: 1px solid @borderColor;
          }
        }
      }

      tbody {
        tr {
          td {
            height: 30px;
            text-align: center;
            padding: 0 10px;
            border: 1px solid @borderColor;

            input {
              width: 100%;
              height: 100%;
              border: none;
              outline: none;
              text-align: center;
            }
          }
        }
      }
    }

    .spe_Box {

      // border: 1px solid black;
      .spe_title {
        padding: 10px;
        background: @form_bgColer;
        // border: 1px solid black;
      }

      .spe_table {
        padding: 10px;
        // border: 1px solid black;
      }

      .spe_btn {
        padding: 10px;
        // border: 1px solid black;
      }
    }



    // border: 1px solid black;
    ul {

      // border: 1px solid black;
      li {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        // border: 1px solid black;
        .title {
          width: 80px;
          color: black;
          font-weight: bold;
          // border: 1px solid black;
        }

        .date {
          width: 100%;
          display: flex;
          align-items: center;

          // border: 1px solid black;
          .item {
            width: 50%;
          }

          .iteminput {
            width: 250px;
          }

          .itemtextarea {
            width: 250px;
          }
        }

        // 表格模式样式
        .data_table {

          // border: 1px solid black;
          .item_operate {
            //操作按钮样式
            padding: 5px 0;
            // border: 1px solid black;
          }

          .item_table {
            width: 100%;
            height: 300px;
            // border: 1px solid black;
          }
        }
      }
    }
  }
</style>
