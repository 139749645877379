<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：包装信息页面
	开始时间：2022-9-21
	开发人员：王铭宇
	最后修改：2022-9-21
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="packingAll productTab_item scollStyle">
    <div class="title">包装尺寸(长*宽*高)</div>
    <el-input v-model="packingLong" class="input_packing" size="mini" @change="calculateVolume()" placeholder="长度"></el-input>
    <span>*</span>
    <el-input v-model="packingWidth" class="input_packing" size="mini" @change="calculateVolume()" placeholder="宽度"></el-input>
    <span>*</span>
    <el-input v-model="packingHeight" class="input_packing" size="mini" @change="calculateVolume()" placeholder="高度"></el-input>
    <el-select class="input_unit" v-model="packageData.packingUnit" size="mini" @change="calculateVolume()" placeholder="单位" >
      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
    </el-select>
    <div class="title">体积</div>
    <el-input v-model="packageData.packingvolume" class="input_packing" size="mini" placeholder="体积" disabled></el-input>
    <el-input v-model="packageData.packingvolumeUnit" class="input_unit" size="mini" placeholder="单位" disabled></el-input>
    <div class="title">每包装产品数</div>
    <el-input v-model="packageData.packingNum" class="input_packing" size="mini" placeholder="数量" ></el-input>
    <span>/</span>
    <el-select class="input_unit" disabled  v-model="packageData.packingpurchaseUnit" size="small" placeholder="请选择">
      <el-option v-for="item in ProductUnit" :key="item.id" :label="item.name" :value="item.id"
        ></el-option>
    </el-select>
    <div class="title">最小包装量</div>
    <el-input v-model="packageData.packingminNum" class="input_packing" size="mini" placeholder="数量" ></el-input>
    <span>/</span>
    <el-select class="input_unit"  disabled v-model="packageData.packingpurchaseUnit" size="small" placeholder="请选择">
      <el-option v-for="item in ProductUnit" :key="item.id" :label="item.name" :value="item.id"
        ></el-option>
    </el-select>
    <!-- <el-input v-model="packingpurchaseUnit" class="input_unit" size="mini" placeholder="采购单位" disabled></el-input> -->
    <div class="title">包装毛重</div>
    <el-input v-model="packageData.packingGrossNum" class="input_packing" size="mini" placeholder="数量" ></el-input>
      <el-radio v-model="packageData.packingGrossNumUnit" label="g">g</el-radio>
      <el-radio v-model="packageData.packingGrossNumUnit" label="kg">kg</el-radio>
      <div class="title">包装说明</div>
     <el-input type="textarea" :rows="6" placeholder="请输入详细描述" v-model="packageData.packingDetail">
    </el-input>
  </div>
</template>
<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
  } from 'vuex'
  export default {
    props: {
      //是否为新增
      Is_add: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {
        options: [{
          value: 'mm',
          label: '毫米'
        }, {
          value: 'cm',
          label: '厘米'
        }, {
          value: 'dm',
          label: '分米'
        }, {
          value: 'm',
          label: '米'
        }],
        packingLong: '',//长度
        packingWidth: '',//管度
        packingHeight: '',//高度

        packageData:{
          packageSize:'',//包装尺寸
          packingUnit: '',//单位
          packingvolume:'',//体积
          packingvolumeUnit:'',//体积单位
          packingNum:'',//每包装产品数
          packingpurchaseUnit:'',//采购单位
          packingminNum:'',//最小包装数
          packingGrossNum:'',//毛重数量
          packingGrossNumUnit:'',//毛重单位
          packingDetail:'',//包装描述信息
        }
      }
    },
    mounted() {
      if (this.Is_add == 1 && this.currentUpdateProduct.product != null) {
        //包装信息回显处理
        if(!!this.currentUpdateProduct.product.package_size){
          let str=this.currentUpdateProduct.product.package_size.split("*")
          this.packingLong=str[0]
          this.packingWidth=str[1]
          this.packingHeight=str[2]
        }
        this.packageData.packageSize=this.currentUpdateProduct.product.package_size
        this.packageData.packingUnit=this.currentUpdateProduct.product.package_unit
        this.packageData.packingvolume=this.currentUpdateProduct.product.package_volume
        this.packageData.packingvolumeUnit=this.currentUpdateProduct.product.package_volume_unit
        this.packageData.packingNum=this.currentUpdateProduct.product.packing_num
        this.packageData.packingminNum=this.currentUpdateProduct.product.packing_min_num
        this.packageData.packingGrossNum=this.currentUpdateProduct.product.packing_gross_num
        this.packageData.packingGrossNumUnit=this.currentUpdateProduct.product.packing_gross_num_unit
        this.packageData.packingDetail=this.currentUpdateProduct.product.packing_detail
        this.packageData.packingpurchaseUnit=this.purchaseAssisUnit
      }
    },
    computed: {
      ...mapState({
        show_addBox: state => state.datum.show_addBox, //控制新增弹框
        productType: state => state.datum.productType, //产品类型(是否为增强模式)
        productData: state => state.datum.productData, //产品数据
        mainMeasureData: state => state.datum.mainMeasureData, //主计量信息
        assisMeasureData: state => state.datum.assisMeasureData, //辅计量信息
        deleteMeasureData: state => state.datum.deleteMeasureData, //修改时删除的多计量数据
        addInventoryPlace: state => state.datum.addInventoryPlace, //新增的仓位号数据
        deleteInventoryPlace: state => state.datum.deleteInventoryPlace, //已删除的仓位号数据
        saleAssisUnit: state => state.datum.saleAssisUnit, //销售强制使用计量单位
        purchaseAssisUnit: state => state.datum.purchaseAssisUnit, //采购强制使用计量单位
        bomAssisUnit: state => state.datum.bomAssisUnit, //bom使用计量单位
        currentUpdateProduct: state => state.datum.currentUpdateProduct, //请求修改数据
        companyparamlist: state => state.companyparamlist, //获取企业级参数
        ProductUnit: state => state.system.ProductUnit, //计量单位
        currentUpdateProduct: state => state.datum.currentUpdateProduct,//当前修改产品
      }),
    },
    watch:{
      //监听货品填写数据发生改变
      productData: {
        deep: true,
        handler(newVal) {
          //获取可选的指定多计量单位信息
          this.getProductMeasureData();
        }
      },
      //监听采购计量单位发生变化
      purchaseAssisUnit:{
        deep: true,
        handler(newVal) {
          //获取可选的指定多计量单位信息
          this.getProductMeasureData();
        }
      },
      //监听包装信息改变
      packageData:{
        handler(newVal) {
          //处理货品属性数据
          this.PRODUCT_PACKAGE(newVal);
        },
        deep: true,
      },
    },
    methods:{

      ...mapMutations([
        'PRODUCT_PACKAGE'//引入包装信息
        ]),
      //计算体积和相关单位的赋值
      calculateVolume(){
        if(!!this.packingLong&&!!this.packingWidth&&!!this.packingHeight){
          this.packageData.packingvolume=this.packingLong*this.packingWidth*this.packingHeight
          this.packageData.packageSize=this.packingLong+'*'+this.packingWidth+'*'+this.packingHeight
        }else{
          this.packageData.packingvolume=''
        }
        if(!!this.packageData.packingUnit){
          this.packageData.packingvolumeUnit=this.packageData.packingUnit+'³'
        }
      },
      getProductMeasureData(){
       if(!!this.purchaseAssisUnit){
         this.packageData.packingpurchaseUnit=this.purchaseAssisUnit
       }
      },
    }
  }
</script>
<style lang="less" scoped="scoped">
  .packingAll {
    .title {
      width: 150px;
      color: black;
      font-weight: bold;
      margin-left: 10px;
      // border: 1px solid black;
    }

    .input_packing {
      width: 100px;
      margin: 10px;

    }
    .input_unit {
      //单位选择框
      width: 80px;
      margin: 10px;
    }
  }
</style>
