<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
  技术支持：Tech@21gmail.com
  单元名称：仓位号选择弹框组件
  开始时间：2021-01-15
  开发人员：刘巍骏
  最后修改：2021-01-15
  备注说明：如需修改请联系开发人员
  -->
<template>
  <div class="">
    <el-dialog title="仓位号" :visible.sync="show_warehousePlace" :before-close="colse_placeBox" width="400px"
      append-to-body>
      <div class="place_Box">
        <el-tree :data="placeData" :props="defaultProps" :default-checked-keys="defaultSelPlace" node-key="id"
          default-expand-all show-checkbox :check-strictly="true" @check-change="getSelectWarehouseData">
        </el-tree>
      </div>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" @click="commit_place">确认</el-button>
        <el-button size="mini" @click="colse_placeBox">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState
  } from 'vuex'

  //导入方法
  import api from "@/network/system/dictionary"; //数据字典相关接口

  export default {
    name: 'tree_place',
    props: {
      //仓库id
      warehouseId: {
        type: Number,
        default: -1
      },
      //默认选中的仓位数据
      defaultSelPlace: {
        type: Array,
        default () {
          return []
        }
      }
    },
    data() {
      return {
        placeData: [], //仓位号数据
        defaultProps: { //匹配接口字段信息
          children: 'children',
          label: 'name'
        },
        defaultSelectPalce: [], //默认选中的仓位数据
        SelectData: [], //选中的数据
      };
    },
    mounted() {

    },
    computed: {
      ...mapState({
        show_warehousePlace: state => state.commComponent.show_warehousePlace, //控制显示部门选择弹框组件
      }),
    },
    watch: {
      //监听仓位号弹框显示
      show_warehousePlace(newVal) {
        if (newVal) {
          //获取仓位号数据
          this.getWrehousePlaceData();
        } else {
          //清空data数据
          Object.assign(this.$data, this.$options.data())
        }
      }
    },
    methods: {
      ...mapMutations([
        'SHOW_WAREHOUSEPLACE', //控制仓位号弹框是否显示
      ]),

      /* 获取仓位号数据 */
      getWrehousePlaceData() {
        let data = {
          depotFormId: this.warehouseId
        }
        api.storageDict.findDepotFormPlaceByFormId(data).then(res => {
          if (res.code == 200) {
            //获取数据
            this.placeData = res.data;
            //转换为非树形结构
            let noTreeData = this.commonJsExtend.toNoTree(JSON.parse(JSON.stringify(res.data)), []);
            //获取默认选中
            this.defaultSelectPalce = this.defaultSelPlace;
            //将已选数据存储到已选数据中
            this.defaultSelPlace.forEach((itemI, indexI) => {
              noTreeData.forEach((itemJ, indexJ) => {
                if (itemI == itemJ.id) {
                  this.getSelectWarehouseData(itemJ, true, true);
                }
              })
            })
            // 循环获取默认选中数据
            noTreeData.forEach((item, index) => {
              if (item.disabled) {
                this.defaultSelectPalce.push(item.id);
              }
            })
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 获取选中的数据 */
      getSelectWarehouseData(row, is_check, is_child) {
        if (is_check) {
          this.SelectData.push(row)
        } else {
          this.SelectData.forEach((item, index) => {
            if (item.id == row.id) {
              this.SelectData.splice(index, 1)
            }
          })
        }
      },

      /* 处理仓位号数据 */
      disposeWarehousePlaceData() {
        //定义返回值
        let placeArr = [];
        //循环获取仓位信息
        this.SelectData.forEach((item, index) => {
          //获取父级数据
          let parentData = this.getPlaceParentData(item, []);
          //逆序
          parentData.reverse();
          //获取组合字符串
          let placeObj = {};
          let placeItemStr = "";
          let placeIdArr = [];
          parentData.forEach((item, index) => {
            //获取名称字符串
            placeItemStr = placeItemStr + item.name;
            //后去id字符串
            placeIdArr.push(item.id)
            placeObj = {
              nameStr: placeItemStr,
              idStr: placeIdArr.join(",")
            }
          })
          //将处理好的数据存入
          placeArr.push(placeObj)
        })
        return placeArr;
      },

      getPlaceParentData(data, arr) {
        //获取非树形结构的数据
        let noTreePlace = this.commonJsExtend.toNoTree(this.placeData, [])
        //判断是否为一级
        if (data.parentId != -1) {
          //过滤所需数据
          let itemData = noTreePlace.filter(item => item.id == data.parentId);
          if (itemData) {
            arr.push(data)
          }
          //回调
          this.getPlaceParentData(itemData[0], arr);
        } else {
          arr.push(data)
        }
        return arr;
      },

      /* 关闭弹框 */
      colse_placeBox() {
        this.SHOW_WAREHOUSEPLACE(false);
      },

      /* 提交选择数据 */
      commit_place() {
        //获取仓位名称
        let selectArr = this.disposeWarehousePlaceData();
        let resData = [];
        //获取返回对象数据
        selectArr.forEach((item, index) => {
          let itemObj = {
            id: index, //id
            houseId: this.warehouseId, //仓库id
            maxNumber: 0, //仓位号最大数量
            placeNumber: 0, //当前仓位数量
            productPlace: item.nameStr, //仓位号名称
            placeIds: item.idStr, //仓位号组成id
            barCode: '', //仓位号编码
          }
          resData.push(itemObj)
        })
        //将处理好的数据发送到父组件
        this.$emit('SelectedData', resData)
        //关闭弹框
        this.SHOW_WAREHOUSEPLACE(false);
      },
    }
  };
</script>

<style lang="less" scoped="scoped">
  .place_Box {
    width: 100%;
    height: 400px;
    overflow: auto;
    // border: 1px solid black;
  }
</style>
