<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：新建销售单价页面
	开始时间：2021-01-14
	开发人员：刘巍骏,万攀
	最后修改：2021-05-01
	备注说明：主计量,辅计量无实际区分
-->
<template>
  <div class="priceManageAll productTab_item scollStyle">
    <div class="item_form">
      <ul>
        <li>
          <div class="title">基础计量单位:</div>
          <div class="data">
            <div class="itemleft">
              <el-select class="input_unit" v-model="productData.basicData.calculateUnit" size="mini" placeholder="单位"
                :disabled="judgeUpdateUnit()">
                <el-option v-for="item in ProductUnit" :key="item.id" :label="item.name" :value="item.name"
                  @click.native="getBaseUnitId(item)"></el-option>
              </el-select>
              <!-- 说明 -->
              <span class="tips">说明:在多计量时建议使用最小单位</span>
            </div>
          </div>
        </li>
        <li>
          <div class="title">销售单价:</div>
          <div class="data">
            <!-- 左侧销售价 -->
            <div class="itemleft">
              <el-input class="input_price" v-model="productData.basicData.unitSellingPrice" size="mini"
                placeholder="单价" oninput="this.value=this.value.replace(/[^0-9\.]/g,'')"></el-input>
              <span class="input_split">{{productData.basicData.priceUnit}}</span>
              <!-- <span class="input_split">/</span> -->
              <!-- <span class="input_split">{{productData.basicData.calculateUnit}}</span> -->
            </div>
            <!-- 操作 -->
            <div class="itemright">
              <el-checkbox class="input_checkbox" v-model="show_measure" :disabled="judgeUpdateUnit()">多计量</el-checkbox>
            </div>
            <!-- 中间采购价 -->
            <div class="itemContent">
              <span class="title">采购单价:</span>
              <el-input class="input_price" v-model="productData.basicData.purchasePrice" size="mini" placeholder="单价"
                oninput="this.value=this.value.replace(/[^0-9\.]/g,'')"></el-input>
              <span class="input_split">{{productData.basicData.priceUnit}}</span>
              <!-- <span class="input_split">/</span>
              <span class="input_split">{{productData.basicData.calculateUnit}}</span> -->
            </div>
          </div>
        </li>
        <!-- 主计量 -->
        <li v-show="show_measure">
          <div class="title">计量设置:</div>
          <div class="data">
            <div class="itemleft">
              <!-- 数量框 -->
              <el-input class="input_price" v-model="mainMeasure.count" size="mini" placeholder="数量"
                oninput="this.value=this.value.replace(/[^0-9\.]/g,'')" :disabled="judgeUpdateUnit()"></el-input>
              <!-- 计量单位 -->
              <span class="input_split">{{productData.basicData.calculateUnit}}</span>
              <!-- 分隔符 -->
              <span class="input_split">=</span>
              <!-- 价格框 -->
              <el-input class="input_price" v-model="mainMeasure.price" size="mini" placeholder="价格"
                oninput="this.value=this.value.replace(/[^0-9\.]/g,'')"></el-input>
              <!-- 价格单位 -->
              <span class="input_split">{{productData.basicData.priceUnit}}</span>
              <!-- 分隔符 -->
              <span class="input_split">/</span>
              <!-- 计量单位下拉 -->
              <el-select class="input_unit" v-model="mainMeasure.unit" size="mini" placeholder="单位"
                :disabled="judgeUpdateUnit()">
                <el-option v-for="item in ProductUnit" :key="item.id" :label="item.name" :value="item.name"
                  @click.native="getUnitId(item)"></el-option>
              </el-select>
            </div>
            <div class="itemright">
              <i class="el-icon-circle-plus-outline" v-if="!judgeUpdateUnit()" @click="addAssisMeasureData"></i>
            </div>
            <div class="itemContent">
              <!-- 采购价格框 -->
              <span class="title">对应采购价:</span>
              <el-input class="input_price" v-model="mainMeasure.purchasePrice" size="mini" placeholder="价格"
                oninput="this.value=this.value.replace(/[^0-9\.]/g,'')"></el-input>
              <!-- 价格单位 -->
              <span class="input_split">{{productData.basicData.priceUnit}}</span>
              <!-- 分隔符 -->
              <!-- <span class="input_split">/</span> -->
              <!-- 计量单位 -->
              <!-- <span class="input_split">{{mainMeasure.unit}}</span> -->
            </div>
          </div>
        </li>
        <!-- 辅计量 -->
        <li v-show="show_measure" v-for="(elem,index) in assisMeasure">
          <div class="title">计量设置:</div>
          <div class="data">
            <div class="itemleft">
              <!-- 数量框 -->
              <el-input class="input_price" v-model="elem.count" size="mini" placeholder="数量"
                oninput="this.value=this.value.replace(/[^0-9\.]/g,'')" :disabled="judgeUpdateUnit()"></el-input>
              <!-- 数量计量单位 -->
              <span class="input_split">{{elem.lastUnit}}</span>
              <!-- 分隔符 -->
              <span class="input_split">=</span>
              <!-- 价格输入框 -->
              <el-input class="input_price" v-model="elem.price" size="mini" placeholder="价格"
                oninput="this.value=this.value.replace(/[^0-9\.]/g,'')"></el-input>
              <!-- 价格单位 -->
              <span class="input_split">{{productData.basicData.priceUnit}}</span>
              <!-- 分隔符 -->
              <span class="input_split">/</span>
              <!-- 计量单位 -->
              <el-select class="input_unit" v-model="elem.unit" size="mini" placeholder="单位"
                :disabled="judgeUpdateUnit()">
                <el-option v-for="item in ProductUnit" :key="item.id" :label="item.name" :value="item.name"
                  @click.native="getassisUnitId(index,item)"></el-option>
              </el-select>
            </div>
            <div class="itemright">
              <i class="el-icon-remove-outline" v-if="!judgeUpdateUnit()" @click="remove_assisMeasureData(index)"></i>
            </div>
            <div class="itemContent">
              <span class="title">对应采购价:</span>
              <!-- 采购价格框 -->
              <el-input class="input_price" v-model="elem.purchasePrice" size="mini" placeholder="价格"
                oninput="this.value=this.value.replace(/[^0-9\.]/g,'')"></el-input>
              <!-- 价格单位 -->
              <span class="input_split">{{productData.basicData.priceUnit}}</span>
              <!-- 分隔符 -->
              <!-- <span class="input_split">/</span> -->
              <!-- 计量单位 -->
              <!-- <span class="input_split">{{elem.unit}}</span> -->
            </div>
          </div>
        </li>
        <li>
          <div class="title">销售指定单位:</div>
          <div class="data">
            <!-- 左边销售指定 -->
            <div class="itemleft">
              <el-select class="input_unit" v-model="saleAssignUnit" size="mini" placeholder="单位"
                :disabled="judgeUpdateUnit()">
                <el-option v-for="item in measureData" :key="item.unitId" :label="item.unit" :value="item.unitId"
                  @click.native="getAssisUnit(0,item)"></el-option>
              </el-select>
            </div>
            <!-- 中间采购指定 -->
            <div class="itemContent">
              <span class="title">采购指定单位:</span>
              <el-select class="input_unit" v-model="purchaseAssignUnit" size="mini" placeholder="单位"
                :disabled="judgeUpdateUnit()">
                <el-option v-for="item in measureData" :key="item.unitId" :label="item.unit" :value="item.unitId"
                  @click.native="getAssisUnit(1,item)"></el-option>
              </el-select>
            </div>
            <!-- 右边说明 -->
            <div class="itemright">
            </div>
          </div>
        </li>
        <li>
          <div class="title">bom指定单位:</div>
          <div class="data">
            <!-- 左边bom指定 -->
            <div class="itemleft">
              <el-select class="input_unit" v-model="bomAssignUnit" size="mini" placeholder="单位"
                :disabled="judgeUpdateUnit()">
                <el-option v-for="item in measureData" :key="item.unitId" :label="item.unit" :value="item.unitId"
                  @click.native="getAssisUnit(2,item)"></el-option>
              </el-select>
            </div>
          </div>
        </li>
        <!-- <li>
          <div class="title">采购会员价:</div>
          <div class="data_table">
            <table>
              <thead>
                <tr>
                  <th class="row_head">会员等级</th>
                  <th v-for="elem in tableData_purchase">{{elem.gradeName}}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="row_head">
                    <div class="row_headBox">
                      <span>价格({{productData.basicData.priceUnit}})</span>
                    </div>
                  </td>
                  <td v-for="elem in tableData_purchase">
                    <input class="input_price" v-model="elem.price" placeholder="对应会员价">
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </li> -->
        <!-- <li>
          <div class="title">销售会员价:</div>
          <div class="data_table">
            <table>
              <thead>
                <tr>
                  <th class="row_head">会员等级</th>
                  <th v-for="elem in tableData_purchase">{{elem.gradeName}}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="row_head">
                    <div class="row_headBox">
                      <span>价格({{productData.basicData.priceUnit}})</span>
                    </div>
                  </td>
                  <td v-for="elem in tableData_purchase">
                    <input class="input_price" v-model="elem.price" placeholder="对应会员价">
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </li> -->
        <li class="sell_range">
          <div class="title">销售区间价:</div>
          <div class="data_table">
            <table>
              <thead>
                <tr>
                  <th class="sellrange_th">起订量-截止量</th>
                  <th class="row_head">
                    <div class="row_headBox">
                      <span>价格({{productData.basicData.priceUnit}})</span>
                    </div>
                  </th>
                  <th>税费(%)</th>
                  <th>备注</th>
                  <th>操作</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(elem,index) in tableData_sellSection">
                  <td class="input_range_td">
                    <div class="item">
                      <input class="input_range" type="text" v-model="elem.gradeName1"
                        oninput="this.value=this.value.replace(/[^0-9]/g,'')" placeholder="起订数">
                      <span>-</span>
                      <input class="input_range" type="text" v-model="elem.gradeName2"
                        oninput="this.value=this.value.replace(/[^0-9]/g,'')" placeholder="截止数">
                    </div>
                  </td>
                  <td>
                    <div class="tdbody">
                      <input class="input_price" v-model="elem.price" placeholder="对应价格"
                        oninput="this.value=this.value.replace(/[^0-9\.]/g,'')">
                      <span class="input_split">/</span>
                      <span class="input_table_unit">{{saleAssignUnitName}}</span>
                    </div>
                  </td>
                  <td>
                    <div class="tdbody">
                      <input class="input_price" v-model="elem.taxrate" placeholder="税费"
                        oninput="this.value=this.value.replace(/[^0-9\.]/g,'')">
                      <span class="input_split">%</span>
                    </div>
                  </td>
                  <td>
                    <div class="tdbody">
                      <input class="input_remark" v-model="elem.remark" placeholder="备注">
                    </div>
                  </td>
                  <td>
                    <i class="el-icon-delete" @click="remove_sellSection(index)"></i>
                  </td>
                </tr>
                <tr>
                  <td colspan="5">
                    <i class="el-icon-plus" @click="add_sellSection"></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- 提示信息 -->
          <div class="data_tip">
            <i class="el-icon-info"></i>
            <span class="tips">若截止数为空,则表示无上限</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  export default {
    name: 'createPriceManage',
    props: {
      //判断是新增还是修改
      Is_add: {
        type: Number,
        default: 0
      },
    },
    data() {
      return {
        //数据
        mainMeasure: {}, // 主计量数据
        assisMeasure: [], //辅计量
        measureData: [], //已填写的多计量数据
        tableData_sellSection: [], //销售区间单价
        saleAssignUnit: '', //销售指定单位
        saleAssignUnitName: '', //销售指定单位(名称)
        purchaseAssignUnit: '', //采购指定单位
        purchaseAssignUnitName: '', //采购指定单位(名称)
        bomAssignUnit: '', //bom指定单位
        baseCalculateUnit: '', //基础计量单位

        //控制开关
        show_measure: false, //控制显示多计量
      }
    },
    computed: {
      ...mapState({
        productType: state => state.datum.productType, //货品类型(是否为增强模式)
        productData: state => state.datum.productData, //货品数据(当前界面填写的数据)
        ProductUnit: state => state.system.ProductUnit, //计量单位
        show_addBox: state => state.datum.show_addBox, //控制新增弹框
        productTypeMoreUtil: state => state.datum.productTypeMoreUtil, //货品分类设置的多计量数据
        currentUpdateProduct: state => state.datum.currentUpdateProduct, //获取修改的数据
        productIsBillsUsed: state => state.datum.productIsBillsUsed, //货品是否已被单据使用
      }),
    },
    created() {},
    mounted() {
      //默认调用一次获取多计量数组
      this.getProductMeasureData(0);
      //获取修改价格数据
      this.getUpdatPriceData();
      //设置默认多计量数据
      this.initMoreUnitData();
    },
    watch: {
      //监听销售区间价发生改变
      tableData_sellSection: {
        handler(newVal) {
          // 将销售区间价存入vuex
          this.PRODUCT_DATA({
            tableData_sellSection: newVal
          });
        },
        deep: true,
      },
      //监听显示新增弹框
      show_addBox(newVal) {
        if (newVal == false) {
          this.show_measure = false
          this.tableData_sellSection = [];
        }
      },
      // 监听主计量
      mainMeasure: {
        deep: true,
        handler(newVal) {
          this.MAIN_MEASURE_DATA(newVal);
        }
      },
      // 监听辅计量
      assisMeasure: {
        deep: true,
        handler(newVal) {
          this.ASSIS_MEASURE_DATA(newVal);
        }
      },
      // 监听多计量开关
      show_measure(newVal) {
        //调用获取多计量数据
        this.getMeasureData();
      },
      //监听货品填写数据发生改变
      productData: {
        deep: true,
        handler(newVal) {
          //获取可选的指定多计量单位信息
          this.getProductMeasureData();
        }
      },
      // 监听销售指定计量单位发生变化
      saleAssignUnit(newVal) {
        //将数据存储到vuex
        this.SALE_ASSIS_UNIT(newVal);
      },
      // 监听销售指定计量单位发生变化(名称)
      saleAssignUnitName(newVal) {
        //将数据存储到vuex
        this.SALE_ASSIS_UNIT_NAME(newVal);
      },
      // 监听采购指定计量单位发生变化
      purchaseAssignUnit(newVal) {
        //将数据存储到vuex
        this.PURCHASE_ASSIS_UNIT(newVal);
      },
      // 监听采购指定计量单位发生变化(名称)
      purchaseAssignUnitName(newVal) {
        this.PURCHASE_ASSIS_UNIT_NAME(newVal);
      },
      //监听Bom指定计量单位发生变化
      bomAssignUnit(newVal) {
        //将数据存储到vuex
        this.BOM_ASSIS_UNIT(newVal);
      }
    },
    methods: {
      ...mapMutations([
        "MAIN_MEASURE_DATA", //主计量
        "ASSIS_MEASURE_DATA", //辅计量
        'PRODUCT_DATA', //货品数据
        'PRODUCT_TAB_INDEX', //标签页下标
        'UPDATE_MEASURE_DATA', //修改的计量数据
        'ADD_MEASURE_DATA', //添加的计量数据
        'DELETE_MEASURE_DATA', //删除的计量数据
        'SALE_ASSIS_UNIT', //销售强制使用计量单位
        'SALE_ASSIS_UNIT_NAME', //销售强制使用计量单位(名称)
        'PURCHASE_ASSIS_UNIT', //采购强制使用计量单位
        'PURCHASE_ASSIS_UNIT_NAME', //采购强制使用计量单位(名称)
        'BOM_ASSIS_UNIT', //bom指定计量单位
        'BASE_UNIT_DATA', //默认计量单位(根据货品设置多计量时使用)
      ]),

      /* 初始化默认多计量数据 */
      initMoreUnitData() {
        //判断新增界面才加载
        if (this.Is_add == 0 && this.productTypeMoreUtil.length > 0) {
          //清空已选计量
          this.measureData = [];
          //清空辅计量
          this.assisMeasure = [];
          //循环获取计量数据
          this.productTypeMoreUtil.forEach((item, index) => {
            //根据计量单位id获取计量单位
            let cuentCalculate = this.commonJsExtend.getDictMatchingData(item.finalCalculateId, this.ProductUnit);

            //获取基础计量单位
            if (item.isBase == 1) {
              this.BASE_UNIT_DATA(cuentCalculate);

              //定义基础计量信息
              let baseUnit = {
                unit: cuentCalculate.name, //单位
                unitId: item.finalCalculateId, //单位id
              }

              //添加已选计量
              this.addSelMaterialData(baseUnit);
            }

            //根据下标获取主计量
            if (index == 1) {
              //获取主计量
              this.mainMeasure = {
                count: item.baseNum, //数量
                price: 0, //销售价
                unit: cuentCalculate.name, //单位
                unitId: item.finalCalculateId, //单位id
                purchasePrice: 0, //采购价
                type: 0, //设置类型为新增
              }

              //设置勾选多计量
              this.show_measure = true;

              //添加已选计量
              // this.addSelMaterialData(this.mainMeasure);
            } else if (index > 1) {
              let lastUnit = "";
              if (this.assisMeasure.length == 0) {
                lastUnit = this.mainMeasure.unit;
              } else {
                lastUnit = this.assisMeasure[this.assisMeasure.length - 1].unit;
              }
              //获取辅计量
              let assisItem = {
                count: item.baseNum, //数量
                price: 0, //价格
                lastUnit: lastUnit, //最后一个单位
                unit: cuentCalculate.name, //当前单位
                unitId: item.finalCalculateId, //当前单位id
                purchasePrice: 0, //采购价
                type: 0, //设置类型为新增
              }
              this.assisMeasure.push(assisItem);

              //添加已选计量
              this.addSelMaterialData(assisItem);
            }

            //设置销售指定计量单位
            if (item.isOrderDefault == 1) {
              this.saleAssignUnit = item.finalCalculateId;
              this.saleAssignUnitName = cuentCalculate.name;
            }
            //设置采购指定计量单位
            if (item.isPurchaseDefault == 1) {
              this.purchaseAssignUnit = item.finalCalculateId;
              this.purchaseAssignUnitName = cuentCalculate.name;
            }
            //设置bom指定计量单位
            if (item.isBomDefault == 1) {
              this.bomAssignUnit = item.finalCalculateId;
            }
          })
        }
      },

      /* 添加已选计量信息 */
      addSelMaterialData(data){
        let selId = [];
        this.measureData.forEach((item,index)=>{
          selId.push(item.unitId);
        })

        //判断是否已经添加过
        if(!selId.includes(data.unitId)){
          this.measureData.push(data);
        }
      },

      /* 获取多计量价格数据 */
      getMeasureData() {
        //判断是勾选还是关闭
        if (!this.show_measure) { //关闭
          //判断当前界面操作界面
          if (this.Is_add == 0) { //新增界面
            // 设置主计量
            this.mainMeasure = {};
            //清空辅计量
            this.assisMeasure = [];
            //清空已设多计量数据
            this.measureData = [];
          } else if (this.Is_add == 1) { //修改界面
            //设置主计量为删除
            if (!!this.mainMeasure.id) {
              this.mainMeasure.type = -1;
              this.DELETE_MEASURE_DATA(this.mainMeasure);
            }
            this.mainMeasure = {};
            //设置辅计量为删除
            this.assisMeasure.forEach((item, index) => {
              if (!!item.id) {
                item.type = -1;
                this.DELETE_MEASURE_DATA(item);
              }
            })
            //清空辅计量
            this.assisMeasure = [];
          }
          //清空已设多计量数据
          this.measureData = [];
        } else { //显示
          if (JSON.stringify(this.mainMeasure) == "{}") {
            this.mainMeasure = {
              count: 0, //数量
              price: 0, //销售价
              unit: '', //单位
              unitId: '', //单位id
              purchasePrice: 0, //采购价
              type: 0, //设置类型为新增
            }
          }
          //添加多计量数据
          this.addSelMaterialData(this.mainMeasure);
        }
      },

      /* 新增辅计量 */
      addAssisMeasureData() {
        let lastUnit = "";
        if (this.assisMeasure.length == 0) {
          lastUnit = this.mainMeasure.unit;
        } else {
          lastUnit = this.assisMeasure[this.assisMeasure.length - 1].unit;
        }
        let assisItem = {
          count: 0, //数量
          price: 0, //价格
          lastUnit: lastUnit, //最后一个单位
          unit: '', //当前单位
          unitId: '', //当前单位id
          purchasePrice: 0, //采购价
          type: 0, //设置类型为新增
        }
        this.assisMeasure.push(assisItem);
      },

      /* 获取可选的指定多计量单位信息type(触发条件  0:组件加载时触发) */
      getProductMeasureData(type) {
        this.measureData = [];
        //基础计量单位
        let basePrice = {
          unit: this.productData.basicData.calculateUnit, //当前单位
          unitId: this.productData.basicData.calculateUnitId, //当前单位id
        }
        //判断有数据才加
        if (!!basePrice.unit) {
          this.addSelMaterialData(basePrice);
        }
        //判断当前是否为新增界面且初次加载
        if (this.Is_add == 0 && type == 0) {
          //设置默认指定单位
          this.saleAssignUnit = basePrice.unitId;
          this.saleAssignUnitName = basePrice.unit;
          this.purchaseAssignUnit = basePrice.unitId;
          this.purchaseAssignUnitName = basePrice.unit;
          this.bomAssignUnit = basePrice.unitId;
        }
        //主计量单位
        let mainPrice = {
          unit: this.mainMeasure.unit, //当前单位
          unitId: this.mainMeasure.unitId, //当前单位id
        }
        //判断有数据才加
        if (!!mainPrice.unit) {
          this.addSelMaterialData(mainPrice);
        }
        //辅计量单位
        this.assisMeasure.forEach((item, index) => {
          let assisItem = {
            unit: item.unit, //当前单位
            unitId: item.unitId, //当前单位id
          }
          if (!!assisItem.unit) {
            this.addSelMaterialData(assisItem);
          }
        })
      },

      /* 获取修改的价格数据 */
      getUpdatPriceData() {
        //判断当前界面状态
        if (this.Is_add == 1) { //修改界面
          //判断是否为多计量
          if (this.currentUpdateProduct.prices.length > 1) { //已设置多计量
            //显示多计量
            this.show_measure = true;
            //获取主计量
            this.mainMeasure = {
              id: this.currentUpdateProduct.prices[1].id, //id
              count: this.currentUpdateProduct.prices[1].exchange_base_num, //数量
              price: this.currentUpdateProduct.prices[1].price, //价格
              unit: this.currentUpdateProduct.prices[1].calculateName, //当前单位
              unitId: this.currentUpdateProduct.prices[1].calculate_type, //当前单位id
              lastUnit: this.currentUpdateProduct.prices[0].calculateName, //最后一个单位
              purchasePrice: this.currentUpdateProduct.prices[1].cost_price, //采购价
              type: 1, //默认设置为修改
            }
            //获取辅计量
            if (this.currentUpdateProduct.prices.length > 1) {
              for (let i = 2; i < this.currentUpdateProduct.prices.length; i++) {
                let assisMeasureItem = {
                  id: this.currentUpdateProduct.prices[i].id, //id
                  count: this.currentUpdateProduct.prices[i].exchange_base_num, //数量
                  price: this.currentUpdateProduct.prices[i].price, //价格
                  unit: this.currentUpdateProduct.prices[i].calculateName, //当前单位
                  unitId: this.currentUpdateProduct.prices[i].calculate_type, //当前单位id
                  lastUnit: this.currentUpdateProduct.prices[i - 1].calculateName, //最后一个单位
                  purchasePrice: this.currentUpdateProduct.prices[i].cost_price, //采购价
                  type: 1, //默认设置为修改
                }
                //将获取的辅计量数据添加到数据中
                this.assisMeasure.push(assisMeasureItem);
              }
            }
          }
          //获取销售默认指定计量单位
          let saleAssign = this.currentUpdateProduct.prices.filter(item => item.is_order_default == 1);
          if (saleAssign.length != 0) {
            this.saleAssignUnit = saleAssign[0].calculate_type;
            this.saleAssignUnitName = saleAssign[0].calculateName;
          }
          //获取采购默认指定计量单位
          let purchaseAssign = this.currentUpdateProduct.prices.filter(item => item.is_purchase_default == 1);
          if (purchaseAssign.length != 0) {
            this.purchaseAssignUnit = purchaseAssign[0].calculate_type;
            this.purchaseAssignUnitName = purchaseAssign[0].calculateName;
          }
          //获取bom默认指定计量单位
          let bomAssign = this.currentUpdateProduct.prices.filter(item => item.is_bom_default == 1);
          if (bomAssign.length != 0) {
            this.bomAssignUnit = bomAssign[0].calculate_type;
          }

          //获取区间价格信息
          if (this.currentUpdateProduct.product.subsection_price_rule) {
            //分割多个区间信息
            let selectArr = this.currentUpdateProduct.product.subsection_price_rule.split("#####");
            //循环获取每一条数据信息
            selectArr.forEach((item, index) => {
              let itemArr = item.split("##");
              //定义单个对象
              let itemAbj = {
                gradeName1: itemArr[0],
                gradeName2: itemArr[1],
                price: itemArr[2],
                taxrate: itemArr[3],
                remark: itemArr[4] == "null" ? "" : itemArr[4]
              }
              //将数据存入data
              this.tableData_sellSection.push(itemAbj);
            })
          }
        }
      },

      /* 获取基础计量单位 */
      getBaseUnitId(item) {
        this.BASE_UNIT_DATA(item);
      },

      /* 获取选择的主计量单位信息 */
      getUnitId(data) {
        // 判断选择的计量单位是否已选
        let len = 0;
        this.measureData.forEach((item, index) => {
          if (item.unitId == data.id) {
            len++
          }
        })
        //判断结果
        if (len != 0) { //有重复值
          this.$message({
            type: 'warning',
            message: '该计量单位已存在!',
            duration: this.elDuration
          })
          //清空主计量值
          this.mainMeasure.unitId = '';
          this.mainMeasure.unit = '';
        } else { //无重复值
          //获取主计量数据
          this.mainMeasure.unitId = data.id;
          this.mainMeasure.unit = data.name;
          //触发一次获取已设计量单位数据信息方法
          this.getProductMeasureData();
        }
      },

      /* 获取辅计量单位id */
      getassisUnitId(index, data) {
        // 判断选择的计量单位是否已选
        let len = 0;
        this.measureData.forEach((item, index) => {
          if (item.unitId == data.id) {
            len++
          }
        })
        //判断结果
        if (len != 0) { //有重复值
          this.$message({
            type: 'warning',
            message: '该计量单位已存在!',
            duration: this.elDuration
          })
          //清空主计量值
          this.assisMeasure[index].unitId = '';
          this.assisMeasure[index].unit = '';
        } else { //无重复值
          //获取主计量数据
          this.assisMeasure[index].unitId = data.id;
          this.assisMeasure[index].unit = data.name;
          //触发一次获取已设计量单位数据信息方法
          this.getProductMeasureData();
        }
      },

      /* 获取销售指定计量单位type(0:销售指定单位 1:采购指定单位  2:bom指定单位) */
      getAssisUnit(type, data) {
        if (type == 0) { //销售指定单位
          this.saleAssignUnit = data.unitId;
          this.saleAssignUnitName = data.unit;
        } else if (type == 1) { //采购指定单位
          this.purchaseAssignUnit = data.unitId;
          this.purchaseAssignUnitName = data.unit;
        } else if (type == 2) { //bom指定单位
          this.bomAssignUnit = data.unitId;
        }
      },

      /* 判断是否可以修改计量单位 */
      judgeUpdateUnit() {
        //定义返回结果
        let result = false;

        //判断修改时才触发
        if (this.Is_add == 1) {
          //判断是否被单据引用
          if (this.productIsBillsUsed) {
            result = true;
          }
        }

        return result;
      },

      /* 移除辅计量 */
      remove_assisMeasureData(index) {
        //调用移除已选计量单位数据
        this.removeMeasure(this.assisMeasure[index].unitId);
        //判断当前操作界面
        if (this.Is_add == 0) { //新增界面
          this.assisMeasure.splice(index, 1);
        } else if (this.Is_add == 1) { //修改界面
          if (!!this.assisMeasure[index].id) {
            //设置为删除
            this.assisMeasure[index].type = -1;
            //将删除的数据添加到vuex
            this.DELETE_MEASURE_DATA(this.assisMeasure[index]);
          }
          this.assisMeasure.splice(index, 1);
        }
      },

      /* 移除已设计量单位数组 */
      removeMeasure(id) {
        this.measureData = this.measureData.filter(item => item.unitId != id);
      },

      /* 销售会员价区间数据 */
      //新增销售会员价区间数据
      add_sellSection() {
        this.tableData_sellSection.push({
          gradeName1: '',
          gradeName2: '',
          price: '',
          taxrate: '',
          remark: ''
        })
      },

      /* 移除销售区间价 */
      remove_sellSection(index) {
        this.tableData_sellSection.splice(index, 1)
      },
    }
  }
</script>

<style lang="less" scoped="scoped">
  // 价格管理内容
  .priceManageAll {
    .item_form {
      width: 100%;

      // border: 1px solid black;
      ul {

        // border: 1px solid black;
        li {
          display: flex;
          align-items: center;
          margin-bottom: 20px;

          // border: 1px solid black;
          .title {
            width: 100px;
            color: black;
            font-weight: bold;
            // border: 1px solid black;
          }

          //价格输入框
          .input_price {
            width: 75px;
            margin-right: 10px;
          }

          // 备注输入框
          .input_remark {
            width: 100%;
            border: none;
            outline: none;
            text-align: center;
          }

          .input_split {
            //分隔符
            font-size: 14px;
            text-align: center;
            padding: 0 5px;
            // border: 1px solid black;
          }

          .input_unit {
            //单位选择框
            width: 75px;
          }

          i:hover {
            //图标样式
            cursor: pointer;
            color: @theme;
          }

          // 数据信息
          .data {
            width: calc(100% - 80px);
            display: flex;
            align-items: center;
            justify-content: space-between;

            // border: 1px solid red;
            .itemleft {
              //数据左侧内容
              width: 50%;
              display: flex;
              align-items: center;
              // border: 1px solid black;
            }

            .itemContent {
              width: 40%;
              display: flex;
              align-items: center;
              text-align: left;
              // border: 1px solid black;
            }

            .itemright {
              //数据右侧内容
              width: 10%;
              display: flex;
              align-items: center;
              // border: 1px solid black;
            }
          }

          // 表格模式样式
          .data_table {
            width: calc(100% - 80px);
            overflow: auto;

            // border: 1px solid black;
            table {
              border-collapse: collapse;

              thead {
                tr {
                  th {
                    width: 100px;
                    height: 30px;
                    text-align: center;
                    background-color: @table_th_bgColor;
                    border: 1px solid @borderColor;
                  }

                  // 范围th样式
                  .sellrange_th {
                    width: 200px;
                  }
                }
              }

              tbody {
                tr {
                  td {
                    height: 30px;
                    width: 80px;
                    text-align: center;
                    border: 1px solid @borderColor;

                    .input_price {
                      // width: 100%;
                      // height: 100%;
                      border: none;
                      outline: none;
                      text-align: center;
                    }

                    .input_table_unit {
                      width: 50px;
                    }

                    // 表格内部样式
                    .tdbody {
                      display: flex;
                      align-items: center;
                    }
                  }

                  // 范围td样式
                  .input_range_td {

                    // border: 1px solid black;
                    .item {
                      display: flex;
                      justify-content: space-between;

                      .input_range {
                        width: 45%;
                        border: none;
                        outline: none;
                        text-align: center;
                      }
                    }
                  }
                }
              }

              .row_head {
                width: 125px;

                // border: 1px solid black;
                .row_headBox {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;

                  // border: 1px solid red;
                  span {
                    width: 100%;
                    // border: 1px solid black;
                  }
                }
              }
            }
          }
        }

        // 销售区间范围样式
        .sell_range {
          position: relative;
          display: flex;
          align-items: flex-start;

          // border: 1px solid black;
          .title {
            padding: 5px 0;
            // border: 1px solid black;
          }
        }
      }
    }
  }
</style>
