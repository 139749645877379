<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：新建详情描述页面
	开始时间：2021-01-14
	开发人员：刘巍骏,万攀
	最后修改：2021-05-01
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="productTab_item">
    <el-input type="textarea" :rows="6" placeholder="请输入详细描述" v-model="productDetail">
    </el-input>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  export default {
    props: {
      //是否为新增
      Is_add: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {
        productDetail: '',
      }
    },
    mounted() {
      if (this.Is_add == 1 && this.currentUpdateProduct.product != null) {
        this.productDetail = this.currentUpdateProduct.product.detail_desciption
      }
    },
    watch: {
      productDetail: {
        handler(newVal) {
          this.PRODUCT_DATA({
            productDetail: newVal
          });
        },
        deep: true,
      },
    },
    computed: {
      ...mapState({
        currentUpdateProduct: state => state.datum.currentUpdateProduct,//当前修改产品
        show_addBox: state => state.datum.show_addBox,//控制显示新建弹框
      })
    },
    methods: {
      ...mapMutations([
        'PRODUCT_DATA' //修改货品数据
      ])
    }
  }
</script>

<style>
</style>
