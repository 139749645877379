<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：新建图片上传页面
	开始时间：2021-01-14
	开发人员：刘巍骏,万攀
	最后修改：2021-05-01
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="imgManageAll productTab_item" id="productImgBox">
    <el-upload :action="Api" list-type="picture-card" :file-list="ImgArr" :headers="headers" :on-success="saveImgInfo"
      ref="upload" :before-upload="beforeUpload">
      <i slot="default" class="el-icon-plus"></i>
      <div slot="file" slot-scope="{file}">
        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
        <span class="el-upload-list__item-actions">
          <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
            <i class="el-icon-zoom-in"></i>
          </span>
          <span class="el-upload-list__item-delete" @click="handleRemove(file)">
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible" append-to-body top="5vh">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'
  import api from '@/network/datum/product';
  export default {
    name: 'imgManage',
    props: {
      Is_add: {
        type: Number,
        default: 0
      },
    },
    data() {
      return {
        Api: baseUrl + '/datainfo/uploadImg',
        headers: {
          token: '', //携带过去的请求头
        },
        dialogImageUrl: '', //查看图片地址
        dialogVisible: false,
        ImgArr: [], //保存的图片数组(界面显示(对象))
        ImgUrlArr: [], //图片地址(数据存储(字符串))
        imageSrc: '', //图片路径

        //辅助数据
        loading:"",//loading框
      }
    },
    created() {
      this.headers.token = getCookie('token'); //获取请求头
      this.getUpdateImg() //获取图片
    },
    watch: {
      //监听图片数组发生变化
      ImgUrlArr: {
        deep: true,
        handler(newVal) {
          this.PRODUCT_DATA({
            productImgData: newVal
          })
        },
      },
      currentUpdateProduct: {
        deep: true,
        handler(newVal) {
          this.getUpdateImg() //获取图片
        },
      },
      // 监听弹框显示
      show_addBox(newVal) {
        if (newVal == false) {
          this.$refs.upload.clearFiles();
          this.ImgArr = [];
          this.ImgUrlArr = [];
        }
      },
    },
    computed: {
      ...mapState({
        productData: state => state.datum.productData, //货品数据
        show_addBox: state => state.datum.show_addBox, //控制新增弹框
        currentUpdateProduct: state => state.datum.currentUpdateProduct, //获取修改的数据
        companyparamlists: state => state.companyparamlists, //获取企业级参数
      })
    },
    mounted() {
      //加载企业级参数
      if (this.companyparamlists == "") {
        this.getCompanyParamLists();
      }
    },
    methods: {
      ...mapMutations([
        'PRODUCT_DATA'
      ]),
      ...mapMutations({
        getCompanyParamLists: 'getCompanyParamLists',
      }),

      /* 获取当前修改 货品的图片 */
      getUpdateImg() {
        if (JSON.stringify(this.currentUpdateProduct) != '{}' && this.Is_add == 1) {
          if (this.currentUpdateProduct.product.pro_imgs) {
            //将字符串转换为数组
            let imgarr = this.currentUpdateProduct.product.pro_imgs.split(',');
            //判断是否有图片数据
            if (imgarr.length>0) {
              //循环前清空数据
              this.ImgArr = [];//清空图像数组
              this.ImgUrlArr = [];//清空图像路径
              //循环获取图像数据
              imgarr.forEach((item, index) => {
                let itemName = item.split("@@@")[0];//获取图片名称
                let itemPath = item.split("@@@")[1];//获取图片地址
                this.ImgUrlArr.push(item);
                //图片信息对象
                let imgobj = {
                  name:itemName,
                  url:baseUrl + itemPath
                }
                //将图片存入界面
                this.ImgArr.push(imgobj);
              })
            }
          }
        }
      },
      
      /* 限制图片上传 */
      beforeUpload(file) {
        let img = this.commonJsExtend.beforeUpload(file, this.companyparamlists,1);
        this.loading = this.commonJsExtend.customLoading("#productImgBox", 4, '文件上传中,请稍候...');
        return img;
      },

      /*  保存上传图片*/
      saveImgInfo(response, file, fileList) {
        //关闭loading框
        this.loading.close();
        //获取界面显示的图片列表数组
        let imgobj = {
          name:file.name,
          url:baseUrl + response.data
        }
        this.ImgArr.push(imgobj);
        //获取后端存储的图片数据
        let ImgUrl = file.name+"@@@"+response.data;
        this.ImgUrlArr.push(ImgUrl);
      },

      /* 删除图片 */
      handleRemove(file) {
        this.$confirm('您确定要删除这张图片吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          //判断当前操作界面
          if (this.Is_add == 0) {//新增界面
            //删除界面图片数据
            this.ImgArr.forEach((item, index) => {
              if (file.uid == item.uid) {
                //删除界面数据
                this.ImgArr.splice(index, 1);
                //删除地址数据
                this.ImgUrlArr.splice(index, 1);
              }
            })
          } else if(this.Is_add == 1) {//修改界面
            //删除图片列表中的数据
            this.ImgArr.forEach((item, index) => {
              if (item.url == file.url) {
                //删除界面数据
                this.ImgArr.splice(index, 1);
                //删除地址数据
                this.ImgUrlArr.splice(index, 1);
              }
            })
          }
        }).catch(() => {})
      },

      /* 查看大图 */
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
    }
  }
</script>

<style lang="less" scoped="scoped">
  .imgManageAll {}
</style>
