<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：货品管理视图页面
	开始时间：2021-01-14
	开发人员：刘巍骏
	最后修改：2021-01-14
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="billingview">
    <!-- 货品操作框 -->
    <div class="billingview_headoperate">
      <!-- 标题 -->
      <div class="head_operateList">
        <ul class="list">
          <li @click="getProductListData(0)" :class="{'selLi':sel_searchBtn==0}">
            全部
          </li>
          <li :class="{'selLi':sel_searchBtn==2}">
            <el-dropdown trigger="click" placement="bottom" @visible-change="getAttrData" @command="command">
              <span class="el-dropdown-link">
                属性<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for=" item in attrData" :key="item.id+''" :command="item.id">
                  {{item.name}}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
          <li :class="{'selLi':sel_searchBtn==3}">
            <el-dropdown trigger="click" placement="bottom" @visible-change="getSourceData" @command="sourcecommand">
              <span class="el-dropdown-link">
                来源<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for=" item in sourceData" :key="item.id+''" :command="item.id">
                  {{item.name}}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
          <li @click="getProductListData(conditionType)">
            刷新<i class="el-icon-refresh-right"></i>
          </li>
          <li>
            <el-popover placement="bottom" width="400" v-model="show_searchBox" trigger="manual">
              <div class="billingview_searchTitle">
                <span>根据以下条件搜索:</span>
              </div>
              <el-form ref="form" :model="product_searchData" size="mini" label-width="100px">
                <el-form-item label="编号:">
                  <el-input v-model="product_searchData.productCode" size="small" placeholder="请输入内容" clearable>
                  </el-input>
                </el-form-item>
                <el-form-item label="货品名:">
                  <el-input v-model="product_searchData.productName" size="small" placeholder="请输入内容" clearable>
                  </el-input>
                </el-form-item>
                <el-form-item label="规格型号:">
                  <el-input v-model="product_searchData.specifications" size="small" placeholder="请输入内容" clearable>
                  </el-input>
                </el-form-item>
                <el-form-item label="所属仓库:">
                  <el-input v-model="product_searchData.houseName" size="small" placeholder="请输入内容"
                    @focus="judgeRelatedData(0)" clearable></el-input>
                </el-form-item>
                <el-form-item label="审核状态:">
                  <el-select v-model="product_searchData.isSubmit" placeholder="请选择状态" style="width: 100%;" clearable>
                    <el-option value="" key="-1" label="全部"></el-option>
                    <el-option label="审核中" value="0">审核中</el-option>
                    <el-option label="已驳回" value="1">已驳回</el-option>
                    <el-option label="复审中" value="2">复审中</el-option>
                    <el-option label="已审核" value="3">已审核</el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="上架状态:">
                  <el-select v-model="product_searchData.putAwayState" placeholder="请选择状态" style="width: 100%;"
                    clearable>
                    <el-option value="" key="-1" label="全部"></el-option>
                    <el-option label="已上架" value="0">已上架</el-option>
                    <el-option label="已下架" value="1">已下架</el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="上线时间区间:">
                  <el-col :span="10">
                    <el-date-picker type="date" placeholder="选择日期" v-model="product_searchData.startTime"
                      style="width: 100%;"></el-date-picker>
                  </el-col>
                  <el-col class="line" :span="3" :push="1"> - </el-col>
                  <el-col :span="10">
                    <el-date-picker type="date" placeholder="选择日期" v-model="product_searchData.endTime"
                      style="width: 100%;"></el-date-picker>
                  </el-col>
                </el-form-item>
                <el-form-item label="更新时间区间:">
                  <el-col :span="10">
                    <el-date-picker type="date" placeholder="选择日期" v-model="product_searchData.updateStartTime"
                      style="width: 100%;"></el-date-picker>
                  </el-col>
                  <el-col class="line" :span="3" :push="1"> - </el-col>
                  <el-col :span="10">
                    <el-date-picker type="date" placeholder="选择日期" v-model="product_searchData.updateEndTime"
                      style="width: 100%;"></el-date-picker>
                  </el-col>
                </el-form-item>
                <el-form-item>
                  <div class="billingview_searchBtnBox">
                    <el-button @click="getProductListData(4,2)" type="primary">搜索</el-button>
                    <el-button @click="show_searchBox=false">取消</el-button>
                  </div>
                </el-form-item>
              </el-form>
              <span slot="reference" class="billingview_searchBut" @click="show_searchBox=!show_searchBox">
                查询<i class="el-icon-search"></i>
              </span>
            </el-popover>
          </li>
        </ul>
      </div>

      <!-- 操作列表 -->
      <div class="head_operateList">
        <ul class="list">
          <li @click="show_createBox">
            <i class="el-icon-plus"></i>新增
          </li>
          <li @click="checkProductInfo" v-if="show_enableCheck">
            <i class="el-icon-document-checked"></i>审批
          </li>
          <li>
            <i class="el-icon-document-copy"></i>复制
          </li>
          <li @click="showExport">
            <i class="el-icon-upload2 rotateX"></i>导入
          </li>
          <li @click="showImport">
            <i class="el-icon-download rotateX"></i>导出
          </li>
        </ul>
      </div>
    </div>

    <!-- 货品表格  -->
    <div class="billingview_tablewrapper billing_table">
      <el-table ref='productTable' :data="ProductListData" @select="getSelectedProductData"
        @select-all="getSelectedAllData"  height="100%" border style="width: 100%">
        <!-- 索引 -->
        <el-table-column type="index" fixed width="30">
          <template slot="header" slot-scope="scope">
            <el-tooltip placement="bottom-start" effect="light">
              <div slot="content">
                <ul class="billsList_relvanceTips scollStyle">
                  <li v-for="elem in showListFiledData" v-if="elem.classify == -1">
                    <el-checkbox v-model="elem.is_show">{{elem.name}}</el-checkbox>
                  </li>
                </ul>
              </div>
              <i class="el-icon-setting"></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <!-- 全选 -->
        <el-table-column type="selection" fixed width="45"></el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" fixed width="50">
          <template slot-scope="scope">
            <el-tooltip placement="right" effect="light">
              <div slot="content">
                <ul class="billsList_relvanceTips scollStyle">
                  <li @click="updateProduct(scope.row,0)">
                    <i class="el-icon-edit-outline icon" title="修改"></i>
                    <span class="left_padding_10">修改</span>
                  </li>
                  <li @click="deleteProduct(scope.row)">
                    <i class="el-icon-delete operate_icon" title="删除"></i>
                    <span class="left_padding_10">删除</span>
                  </li>
                  <li @click="soldOutProduct(scope.row)" v-if="scope.row.is_down == 0">
                    <i class="el-icon-bottom operate_icon" title="下架"></i>
                    <span class="left_padding_10">下架</span>
                  </li>
                  <li @click="putAwayProduct(scope.row)" v-if="scope.row.is_down == 1">
                    <i class="el-icon-top operate_icon" title="下架"></i>
                    <span class="left_padding_10">上架</span>
                  </li>
                </ul>
              </div>
              <i class="el-icon-more icon_color_red"></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <!-- 基本信息内容 -->
        <el-table-column prop="product_code" label="货品编号" width="180px" sortable v-if="showListFiledArr.includes(0)"
          show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="span_pointer span_color_blue" @click="showProDetail(scope.row.id,'')">{{scope.row.product_code}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="product_old_code" label="原货品编号"
          v-if="show_oldProductCode && showListFiledArr.includes(27)" width="180px" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="name" label="货品名称" v-if="showListFiledArr.includes(1)" width="150px"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="specifications" label="货品规格" v-if="showListFiledArr.includes(2)" width="240px"
          show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="prodoctName left_text colum_item">{{scope.row.specifications}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="is_submit" label="审核状态" v-if="show_enableCheck && showListFiledArr.includes(26)"
          width="80px">
          <template slot-scope="scope">
            <el-button v-if="scope.row.is_submit == 0" type="primary" size="mini">审核中</el-button>
            <el-button v-if="scope.row.is_submit == 1" type="danger" size="mini">已驳回</el-button>
            <el-button v-if="scope.row.is_submit == 2" type="primary" size="mini">复审中</el-button>
            <el-button v-if="scope.row.is_submit == 3" type="success" size="mini">已审核</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="propertyName" label="货品属性" v-if="showListFiledArr.includes(3)" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="库存数"  prop="warehouseTotalNumber" sortable  v-if="showListFiledArr.includes(22)" width="120px" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tooltip placement="right">
              <ul slot="content">
                <li>
                  <span>占用量:{{scope.row.occupyTotalNumber | getPurchaseChangeNum(scope.row)}}</span>
                  <span>{{scope.row.productPrice | getPurchaseUnit}}</span>
                </li>
                <li>
                  <span>在途量:{{scope.row.onwayTotalNumber | getPurchaseChangeNum(scope.row)}}</span>
                  <span>{{scope.row.productPrice | getPurchaseUnit}}</span>
                </li>
                <li>
                  <span>生产量:{{scope.row.productTotalNumber | getPurchaseChangeNum(scope.row)}}</span>
                  <span>{{scope.row.productPrice | getPurchaseUnit}}</span>
                </li>
                <li>
                  <span>可用量:{{scope.row.usableTotalNumber | getPurchaseChangeNum(scope.row)}}</span>
                  <span>{{scope.row.productPrice | getPurchaseUnit}}</span>
                </li>
                <li>
                  <span>库存量:{{scope.row.warehouseTotalNumber | getPurchaseChangeNum(scope.row)}}</span>
                  <span>{{scope.row.productPrice | getPurchaseUnit}}</span>
                </li>
              </ul>
              <span>
                {{scope.row.warehouseTotalNumber | getPurchaseChangeNum(scope.row)}}
                &nbsp;
                {{scope.row.productPrice | getPurchaseUnit}}
              </span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="productPrice[0].price" sortable :sort-method="(a,b)=>{return a.productPrice[0].price - b.productPrice[0].price}" label="销售单价" v-if="showListFiledArr.includes(29)" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="productPrice[0].cost_price" sortable :sort-method="(a,b)=>{return a.productPrice[0].cost_price - b.productPrice[0].cost_price}" label="成本单价" v-if="showListFiledArr.includes(30)" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="sourceName" label="货品来源" v-if="showListFiledArr.includes(4)" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="brandName" label="货品品牌" v-if="showListFiledArr.includes(5)" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="supplier_name" label="供应商" v-if="showListFiledArr.includes(6)" width="240px"
          show-overflow-tooltip></el-table-column>
       <!-- <el-table-column prop="simple_num" label="样单数" v-if="showListFiledArr.includes(20)" show-overflow-tooltip>
          <template slot-scope="scope">
            <span @click="getBillsData(scope.row,0)">{{scope.row.simple_num}}</span>
            <span @click="showProDetail(scope.row.id,'1')">{{scope.row.simple_num}}</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="offer_num" sortable label="报价数" v-if="showListFiledArr.includes(20)" show-overflow-tooltip>
          <template slot-scope="scope">
            <span @click="showProDetail(scope.row.id,'1')">{{scope.row.offer_num}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="order_num" sortable label="订单数" v-if="showListFiledArr.includes(21)" show-overflow-tooltip>
          <template slot-scope="scope">
            <!-- <span @click="getBillsData(scope.row,1)">{{scope.row.order_num}}</span> -->
            <span @click="showProDetail(scope.row.id,'2')">{{scope.row.order_num}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="outsourcing_num" sortable label="外购数" v-if="showListFiledArr.includes(23)" show-overflow-tooltip>
          <template slot-scope="scope">
            <!-- <span @click="getBillsData(scope.row,2)">{{scope.row.outsourcing_num}}</span> -->
            <span @click="showProDetail(scope.row.id,'5','1')">{{scope.row.outsourcing_num}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="in_production_num" sortable label="生产中" v-if="showListFiledArr.includes(24)"
          show-overflow-tooltip>
          <template slot-scope="scope">
            <!-- <span @click="getBillsData(scope.row,3)">{{scope.row.in_production_num}}</span> -->
            <span @click="showProDetail(scope.row.id,'6','','2')">{{scope.row.in_production_num}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="produced_num" sortable label="已生产" v-if="showListFiledArr.includes(25)" show-overflow-tooltip>
          <template slot-scope="scope">
            <!-- <span @click="getBillsData(scope.row,4)">{{scope.row.produced_num}}</span> -->
            <span @click="showProDetail(scope.row.id,'6','','1')">{{scope.row.produced_num}}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="create_user_name" label="销售单价" width="80" v-if="showListFiledArr.includes(7)" show-overflow-tooltip></el-table-column> -->
        <!-- <el-table-column prop="create_time" label="采购单价" width="80" v-if="showListFiledArr.includes(8)" show-overflow-tooltip></el-table-column> -->
        <el-table-column prop="desciption" label="货品简介" v-if="showListFiledArr.includes(9)" width="150px"
          show-overflow-tooltip></el-table-column>
        <!-- <el-table-column prop="create_time" label="计量单位" width="80" v-if="showListFiledArr.includes(10)" show-overflow-tooltip></el-table-column> -->
        <!-- <el-table-column prop="create_user_name" label="价格单位" width="80" v-if="showListFiledArr.includes(11)" show-overflow-tooltip></el-table-column> -->
        <el-table-column prop="order_limit_num" sortable label="起订量" v-if="showListFiledArr.includes(12)" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="weight" label="单个重量" v-if="showListFiledArr.includes(13)" show-overflow-tooltip>
        </el-table-column>
        <!-- <el-table-column prop="create_user_name" label="是否可组装" width="80" v-if="showListFiledArr.includes(14)" show-overflow-tooltip></el-table-column> -->
        <!-- <el-table-column prop="create_user_name" label="是否可拆卸" width="80" v-if="showListFiledArr.includes(15)" show-overflow-tooltip></el-table-column> -->
        <el-table-column prop="create_user_name" label="创建人" v-if="showListFiledArr.includes(16)" width="150px"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="create_time" label="创建时间" v-if="showListFiledArr.includes(17)" width="180px"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="is_down" label="上架状态" v-if="showListFiledArr.includes(28)" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button v-if="scope.row.is_down == 0" type="success" size="mini">已上架</el-button>
            <el-button v-if="scope.row.is_down == 1" type="danger" size="mini">已下架</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="check_user_name" label="审核人" v-if="showListFiledArr.includes(18)" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="check_time" label="审核时间" v-if="showListFiledArr.includes(19)" width="180px"
          show-overflow-tooltip></el-table-column>
      </el-table>
    </div>

    <!-- 表格分页 -->
    <div class="billing_tablePage">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page.sync="currentPage" :page-sizes="[10, 20, 30, 40,50]" :page-size.sync="currentPageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="totalSize" :disabled="Is_click">
      </el-pagination>
    </div>

    <!-- 货品关联单据弹框 -->
    <el-dialog :title="billsTypeTitle" :visible.sync="show_billsDetail" width="60%" append-to-body>
      <div class="billing_table billingDetailBox" id="billingDetailBox">
        <el-table :data="billsData" height="95%" border style="width: 100%">
          <!-- 索引 -->
          <el-table-column type="index" fixed width="30"></el-table-column>
          <!-- 数据内容 -->
          <el-table-column prop="billsNo" label="单据编号" sortable width="150" show-overflow-tooltip></el-table-column>
          <el-table-column prop="createTime" label="单据日期" width="150" show-overflow-tooltip></el-table-column>
          <el-table-column v-if="[0,1].includes(currentBillsType)" prop="customerName" label="客户" width="150"
            show-overflow-tooltip></el-table-column>
          <el-table-column v-if="[2].includes(currentBillsType)" prop="supplierName" label="供应商" width="150"
            show-overflow-tooltip></el-table-column>
          <el-table-column prop="money" label="金额" width="120" show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="!!scope.row.discount">{{scope.row.discount}}</span>
              <span v-else>{{scope.row.money}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="productNumber" label="货品数量" min-width="80" show-overflow-tooltip></el-table-column>
        </el-table>

        <div class="billsPageBox">
          <el-pagination @size-change="billsPageChange" @current-change="getCurrentPage"
            :current-page.sync="billsPageData.currentPage" :page-sizes="[10, 20, 30, 40,50]"
            :page-size.sync="billsPageData.currentPageSize" layout="total, sizes, prev, pager, next, jumper"
            :total="billsPageData.totalSize">
          </el-pagination>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="show_billsDetail = false">取 消</el-button>
        <el-button size="small" type="primary" @click="show_billsDetail = false">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 下架原因弹框 -->
    <el-dialog title="下架原因" :visible.sync="show_soldout" width="500px" append-to-body>
      <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="soldoutData">
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="show_soldout = false">取 消</el-button>
        <el-button size="small" type="primary" @click="soldoutProData">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 新增货品组件 -->
    <commodityCreate :Is_add="Is_add" v-if="show_addBox"></commodityCreate>

    <!--  货品导入组件 -->
    <export-product @close_exportBox="close_exportBox" :show_exportBox="show_exportBox" v-if="show_exportBox">
    </export-product>

    <!-- 仓库选择组件 -->
    <warehouseList @SelectedData="getWarehouseData"></warehouseList>

    <!-- 货品详情组件 -->
    <productDetail :productId="detailProductId" :proDetailBillTab="proDetailBillTab" :classify="classify" :mesDepotState="mesDepotState" v-if="show_productDetailBox"></productDetail>

    <!-- 导出列表弹框 -->
    <el-dialog title="导出列表" :visible.sync="isShowExportListBox" width="420px" :before-close="closeExportListBox"
      append-to-body>
      <div>
        <el-row class="exportListBox">
          <el-col :span="4" class="title">
            <span>导出:</span>
          </el-col>
          <el-col :span="13" class="content">
            <el-radio-group v-model="exportListType">
              <el-radio :label="0">导出所选</el-radio>
              <el-radio :label="1">导出全部</el-radio>
            </el-radio-group>
            <el-checkbox-group v-model="checkList">
                <el-checkbox label="有库存"></el-checkbox>
                <el-checkbox label="有交易"></el-checkbox>
            </el-checkbox-group>
          </el-col>
        </el-row>
        <el-row v-if="exportListType == 1">
          <el-col :span="24">
            <el-col :span="4">起止时间:</el-col>
            <el-col :span="8">
              <el-date-picker type="date" placeholder="选择日期" v-model="startTime" style="width: 100%;" size="mini">
              </el-date-picker>
            </el-col>
            <el-col class="line" :span="3" :push="1"> - </el-col>
            <el-col :span="8">
              <el-date-picker type="date" placeholder="选择日期" v-model="endTime" style="width: 100%;" size="mini">
              </el-date-picker>
            </el-col>
          </el-col>
        </el-row>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="closeExportListBox">取 消</el-button>
        <el-button size="mini" type="primary" @click="commitExportList">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  // 导入组件
  import commodityCreate from './commodity_create.vue'; //新增货品组件
  import exportProduct from './commondity_export.vue'; //货品导入
  import warehouseList from '@/components/commComponent/list/list_warehouse'; //仓库列表组件
  import productDetail from '@/components/commComponent/dialog/product_detail.vue';

  // 导入请求接口
  import api from '@/network/datum/product'; //货品相关接口
  import attrApi from '@/network/system/dictionary'; //数据字典相关接口
  import stockApi from '@/network/stock/stock.js'; //仓库相关接口

  export default {
    name: 'commodityView',
    props: {
      //货品分类数据
      selClassifyId: {
        type: String,
        default: ""
      },
      //是否执行查询
      is_execute: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        // 组件数据
        Is_add: 0, //判断当前是新增还是修改
        Is_click: false, //分页是否能点击
        soldoutData: '', //下架原因
        curentOperateProData: {}, //当前操作的货品数据

        //控制开关
        show_billsDetail: false, //控制显示单据详情弹框
        show_exportBox: false, //是否显示货品导入
        show_searchBox: false, //是否显示搜索
        show_followUp: false, //是否显示跟进数弹框
        show_chance: false, //是否显示报价弹框
        show_order: false, //是否显示订单弹框
        show_enableCheck: false, //是否启用审核
        show_oldProductCode: false, //控制是否显示旧物料编码
        show_soldout: false, //控制是否显示下架原因弹框
        show_productDetailBox:false,//控制显示货品详情

        //表格数据
        ProductListData: [], //传入表格组件的数据
        billsData: [], //单据详情数据
        selProductData: [], //选中的货品数据
        showListFiledData: [], //列表可显示的字段
        showListFiledArr: [], //列表需要显示的

        // 列表查询条件信息
        conditionType: 0, //0:全部 1:分类
        currentPage: 1, //当前页标
        currentPageSize: 20, //当前页面数据数量
        totalSize: 0, //总页数

        //loading加载框
        loading: '',

        // 高级搜索框数据
        product_searchData: { //高级搜索数据
          productCode: '', //编号
          productName: '', //名称
          specifications: '', //规格
          houseName: '', //仓库
          houseId: '', //仓库id
          startTime: '', //上线开始时间
          endTime: '', //上线结束时间
          updateStartTime: '', //更新开始时间
          updateEndTime: '', //更新结束时间
          isSubmit: '', //货品审核状态
          putAwayState: '0', //上架状态
        },
        personnel: '', //员工
        departmentPower: [], //权限部门
        filterPower: {}, //符合条件的权限对象
        attrData: [], //货品属性
        sourceData: [], //来源
        sel_searchBtn: 0, //当前选中查询条件(0:全部 1:已审 2:未审 3:状态 4:高级查询)

        //货品关联单据信息数据
        billsTypeTitle: '', //货品数量关联单据弹框名称
        billsPageData: { //分页数据
          currentPage: 0, //当前页
          currentPageSize: 20, //页面大小
          totalSize: 0, //总页数
        },
        currentBillsType: '', //当前单据弹框类型
        currentBillsProduct: '', //当前获取单据信息的货品

        //货品详情组件相关数据
        detailProductId:-1,//当前查看的货品详情id
        proDetailBillTab:'',//定义页面
        classify:'',//相关类型
        mesDepotState:'',//判断是否只加载已入库

        //货品导出相关
        isShowExportListBox:false,//货品导出控制弹框
        exportListType: 0, //导出方式(0:已选  1:全部)
        startTime: '', //开始时间
        endTime: '', //结束时间
        proPropertyId:'',//属性id
        proSourceId:'',//来源id
        checkList:['有库存'],//多选
      }
    },
    computed: {
      ...mapState({
        WarehouseConfig: state => state.system.WarehouseConfig, //仓库数据
        currentUpdateProduct: state => state.datum.currentUpdateProduct, //当前修改货品信息
        show_addBox: state => state.datum.show_addBox, //显示新增弹框
        is_cancel: state => state.datum.is_cancel, //是否为新增关闭
        userPowerInfo: state => state.user.userPowerInfo, //权限
        companyparamlist: state => state.companyparamlist, //获取企业级参数
        productListFileData: state => state.bill_list_config.productListFileData, //生产计划列表可显示字段
        productBillsKeys: state => state.system.productExportProcessKeys, //销售单据导出字段
        UserInfo: state => state.user.UserInfo, //当前登录用户信息

        //权限相关
        companyparamlist: state => state.companyparamlist, //获取企业级参数
      })
    },
    watch: {
      /* 监听点击分类 */
      is_execute(newVal) {
        //判断是查询的全部还是分类
        if (this.selClassifyId == "") {
          this.conditionType = 0;
        } else {
          this.conditionType = 1;
        }
        //查询货品列表数据
        this.getProductListData(this.conditionType);
      },
      /* 监听控制新增弹框数据发生改变 */
      show_addBox(newVal) {
        if (!newVal) {
          //关闭时默认请求一次数据(出取消外)
          if (!this.is_cancel) {
            this.getProductListData(this.conditionType);
          }
        } else {
          //初始化非取消关闭
          this.IS_CANCEL(false);
          //打开时默认初始化一次vuex数据
          this.INIT_PRODUCT_DATA();
        }
      },
      // 监听显示列表数据发生变化
      showListFiledData: {
        handler(newVal) {
          this.getCurShowFiledArr();
        },
        deep: true
      },
    },
    created() {
      //判断货品相关权限
      this.judgeProductPower();
    },
    mounted() {
      //获取vuex数据
      //默认获取全部货品数据
      this.getProductListData(0);
      //初始化列表显示字段数据
      this.initListShowFiledData();
    },
    updated() {
      this.$nextTick(() => {
        this.$refs['productTable'].doLayout();
      });
    },
    methods: {
      ...mapMutations([
        'CURRENT_UPDATEP_RODUCT', //当前修改货品信息
        'INIT_PRODUCT_DATA', //初始化基本信息
        'SHOW_ADDBOX', //显示新增弹框
        'IS_CANCEL', //是否为取消关闭
        'SHOW_WAREHOUSEBOX', //控制仓库是否显示
        'PRODUCT_IS_BILLS_USED', //货品是否已被其它单据使用
      ]),
      ...mapActions([
        'getUserPowerInfo', //获取用户操作权限
      ]),
      //顺逆排序
      commonSort(val){
        //定义查询公共数据
        let data = {
          pageIndex: this.currentPage, //当前页
          pageSize: this.currentPageSize, //页面大小
          condition: this.conditionType, //查询条件
          isDown:0
        }
        if(!!this.selClassifyId&&this.conditionType==1){
          data.classfiyId = this.selClassifyId
        }
        //当查询条件不为空 并且是为4条件查询的情况
        if(!!this.product_searchData&&this.conditionType==4){
          //定义高级条件查询
          let {
            productCode, //编号
            productName, //名称
            specifications, //规格
            houseName, //仓库
            houseId, //仓库id
            startTime, //上线开始时间
            endTime, //上线结束时间
            updateStartTime, //更新开始时间
            updateEndTime, //更新结束时间
            isSubmit, //货品审核状态
            putAwayState, //货品上架状态
          } = this.product_searchData;
          //编号
          if (productCode != '') {
            data.productCode = productCode
          }
          //名称
          if (productName != '') {
            data.productName = productName
          }
          //规格
          if (specifications != '') {
            data.specifications = specifications
          }
          //仓库
          if (houseName != '') {
            data.houseId = houseId
          }
          //货品审核状态
          if (isSubmit != '') {
            data.isSubmit = isSubmit
          }
          //开始时间
          if (startTime != '') {
            data.startTime =this.commonJsExtend.getDateTime(startTime,0)
          }
          //结束时间
          if (endTime != '') {
            data.endTime =this.commonJsExtend.getDateTime(endTime,0)
          }
          //开始时间
          if (updateStartTime != '') {
            data.updateStartTime =this.commonJsExtend.getDateTime(updateStartTime,0)
          }
          //结束时间
          if (updateEndTime != '') {
            data.updateEndTime =this.commonJsExtend.getDateTime(updateEndTime,0)
          }
        }
        //排序约定 sortType（1为库存数 2为销售单价 3为成本单价 4为报价数 5为订单数 6为外购数 7为生产中 8为已生产 9为起订量）
        if(val.prop=="warehouseTotalNumber"&&!!val.order){
             data.sortType=1//为库存数
             data.order=val.order
        }
        // //加载loading框
        // this.loading = this.commonJsExtend.customLoading("#product_body", 4, '货品列表信息获取中,请稍候...');
        // //发送请求
        // api.searchCommodityByClassfiyId(data).then(res => {
        //   //判断接口返回数据
        //   if (res.code == 200) {
        //     //获取当前列表数据
        //     this.ProductListData = res.data.rows;
        //     //获取总条数
        //     this.totalSize = res.data.total;
        //     //定义需要获取库存的货品id数据
        //     let proIdArr = [];
        //     this.ProductListData.forEach((item, index) => {
        //       proIdArr.push(item.id)
        //     })
        //     //获取主仓
        //     let mainWarehouse = "";
        //     this.WarehouseConfig.forEach((item, index) => {
        //       if (item.is_main == 0) {
        //         mainWarehouse = item;
        //       }
        //     })
        //     //获取库存信息
        //     this.getProductStockNum(proIdArr.join(","), mainWarehouse.id);
        //   } else {
        //     this.$message({
        //       type: 'error',
        //       message: res.message,
        //       duration: this.elDuration
        //     })
        //   }
        //   //关闭loading框
        //   this.loading.close();
        // })
        console.log(val)
        console.log(this.conditionType)
        console.log(this.selClassifyId)
        console.log(this.product_searchData)

      },
      /* 判断货品相关权限 */
      judgeProductPower() {
        //判断是否开启审核
        if (this.companyparamlist.param347 == '0') {
          this.show_enableCheck = true;
        } else if (this.companyparamlist.param347 == '1') {
          this.show_enableCheck = false;
        }

        //判断是否显示旧物料编码
        if (this.companyparamlist.param345 == "0") { //启用
          this.show_oldProductCode = true;
        }
      },
      //关闭导出弹框
      closeExportListBox(){
        this.isShowExportListBox=false
      },
      /* 下载文件或打开pdf文件 */
      downloadFile(href, name) {
        if (this.operate == 2) { //为pdf文件时
          let filePreViewData = {
            file_path: href,
            name
          }
          this.showFilePreView(filePreViewData)
        } else if (this.operate == 1) { //为其他文件时
          const link = document.createElement("a");
          link.style.display = 'none';
          link.href = baseUrl + href;
          link.download = name;
          document.body.appendChild(link);
          link.click();
        }
      },
      // 获取时间差startTime:开始时间  endTime:结束时间
      getDifferTime(startTime, endTime) {
        if (startTime == "" && endTime == "") {
          return true;
        }
        //判断日期区间
        let today = new Date(); //今天日期
        today.setHours(0, 0, 0, 0);
        if (startTime == '' || endTime == '') { //起止时间其中一个为空
          this.$message({
            type: 'warning',
            message: '起止日期不能为空!',
            duration: this.elDuration
          })
          return false;
        } else if (endTime.getTime() - startTime.getTime() < 0) { //结束日期不能早于开始日期
          this.$message({
            type: 'warning',
            message: '结束日期不能早于开始日期!',
            duration: this.elDuration
          })
          return false;
        } else if (today.getTime() < startTime.getTime() || today.getTime() < endTime
          .getTime()) { //所选择的日期不能早于当前日期
          this.$message({
            type: 'warning',
            message: '所选择的日期不能早于当前日期!',
            duration: this.elDuration
          })
          return false;
        } else { //限制日期选择区间
          let duringTime = (endTime.getTime() - startTime.getTime()) / (24 * 60 * 60 * 1000)
          if (duringTime > 90) {
            this.$message({
              type: 'warning',
              message: '日期区间不能超过90天!',
              duration: this.elDuration
            })
            return false;
          }
        }
        return true;
      },
      commitExportList(){
         if(this.companyparamlist.param371==0){
           let jurisdiction=this.companyparamlist.param372.split(",")
           if(!jurisdiction.includes(this.UserInfo.user_id.toString())){
             this.$message({
               type: 'warning',
               message: '你没有导出权限!',
               duration: this.elDuration,
             })
             return
           }
         }
        let exportIdsArr = [];
          if(this.exportListType==0){
            //定义导出的列表数据id字段
              //判断是否已选数据
              if (this.selProductData.length == 0) {
                this.$message({
                  type: 'warning',
                  message: '请先选择需要导出的数据!',
                  duration: this.elDuration,
                })
                return
              }
            this.startTime=''
            this.endTime=''
            this.selProductData.forEach((item,index)=>{
              exportIdsArr.push(item.id);
            })
            this.closeExportListBox()
          }else if (this.exportListType == 1) { //导出全部

          //判断日期差
          if (!this.getDifferTime(this.startTime, this.endTime)) {
            return
          }
          this.startTime=this.commonJsExtend.getDateTime(this.startTime,0)
          this.endTime=this.commonJsExtend.getDateTime(this.endTime,0)
        }


        //定义后端公共接受参数
        let exportData = {
          type: 0, //格式
          ids: exportIdsArr.join(","),
          startTime: this.startTime,
          endTime: this.endTime,
        }
        if(this.checkList.length>0){
          this.checkList.forEach((item,index)=>{
            if(item=='有库存'){
              exportData.whetherInventory=1
            }
            if(item=='有交易'){
             exportData.whetherTransaction=1
            }
          })
        }
        if(this.exportListType == 1){
          if(!!this.selClassifyId){
            exportData.classifyId=this.selClassifyId
          }
          if(!!this.proPropertyId){
            exportData.proPropertyId=this.proPropertyId
          }
          if(!!this.proSourceId){
            exportData.proSourceId=this.proSourceId
          }
          if(!!this.product_searchData.productCode){
            exportData.productCode=this.product_searchData.productCode
          }
          if(!!this.product_searchData.productName){
            exportData.productName=this.product_searchData.productName
          }
          if(!!this.product_searchData.specifications){
            exportData.specifications=this.product_searchData.specifications
          }
          if(!!this.product_searchData.houseName){
            exportData.houseName=this.product_searchData.houseName
          }
          if(!!this.product_searchData.houseId){
            exportData.houseId=this.product_searchData.houseId
          }
          if(!!this.product_searchData.isSubmit){
            exportData.isSubmit=this.product_searchData.isSubmit
          }
          if(!!this.product_searchData.putAwayState){
            exportData.putAwayState=this.product_searchData.putAwayState
          }
          if(!!this.product_searchData.startTime){
            exportData.startTime=this.commonJsExtend.getDateTime(this.product_searchData.startTime,0)
          }
          if(!!this.product_searchData.endTime){
            exportData.endTime=this.commonJsExtend.getDateTime(this.product_searchData.endTime,0)
          }
          if(!!this.product_searchData.updateStartTime){
            exportData.updateStartTime=this.commonJsExtend.getDateTime(this.product_searchData.updateStartTime,0)
          }
          if(!!this.product_searchData.updateEndTime){
            exportData.updateEndTime=this.commonJsExtend.getDateTime(this.product_searchData.updateEndTime,0)
          }
        }
        // 请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#billExportBox", 4, '单据列表导出中,请稍候...');
        exportData.fields = this.productBillsKeys.join(','); //需要导出的字段名
        api.exportProductList(exportData).then(res=>{
          if (res.code == 200 && res.data) {
            let filePathArr = [];
            let name = '';
            filePathArr = res.data.split('//')
            name = decodeURI(filePathArr[filePathArr.length - 1])
            //设置操作类型为导出excle
            this.operate = 1;
            //下载文件
            this.downloadFile(res.data, name, 1)
            //关闭导出弹框
            this.closeExportListBox()
            this.loading.close();
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
            this.loading.close();
          }
        })
      },
      /* 获取货品列表数据type(0:全部  1:分类 2:属性  3:来源  4:高级查询) */
      async getProductListData(type, searchType, command) {
        this.proSourceId=''
        this.proPropertyId=''
        //判断用户权限(需优化  这里先这样写  不知道为什么await没有用)
        if (this.userPowerInfo.length == 0) {
          await this.getUserPowerInfo();
          //判断是否有查询权限
          if (this.commonJsExtend.isHaveThePower(21, 2, 1, '查询')) {
            this.ProductListData = []
            return
          }
        } else {
          //判断是否有查询权限
          if (this.commonJsExtend.isHaveThePower(21, 2, 1, '查询')) {
            this.ProductListData = []
            return
          }
        }

        //将查询类别存入
        this.conditionType = type;
        this.sel_searchBtn = type;

        //定义查询公共数据
        let data = {
          pageIndex: this.currentPage, //当前页
          pageSize: this.currentPageSize, //页面大小
          condition: this.conditionType, //查询条件
        }

        // 判断其他查询条件
        if (this.conditionType == 1) { //货品分类
          data.classfiyId = this.selClassifyId;
        }

        //判断是否查询全部
        if (type == 0) { //是
          //修改父组件分类为全部
          this.$parent.updateClassifyToAll();
        }

        //定义高级条件查询
        let {
          productCode, //编号
          productName, //名称
          specifications, //规格
          houseName, //仓库
          houseId, //仓库id
          startTime, //上线开始时间
          endTime, //上线结束时间
          updateStartTime, //更新开始时间
          updateEndTime, //更新结束时间
          isSubmit, //货品审核状态
          putAwayState, //货品上架状态
        } = this.product_searchData;

        //判断查询条件
        if (searchType == 0) { //属性
          data.proPropertyId = command;
          this.proPropertyId=command;

        } else if (searchType == 1) { //来源
          data.proSourceId = command
          this.proSourceId=command

        } else if (searchType == 2) { //高级查询
          let today = new Date()
          today.setHours(0, 0, 0, 0)
          if ((startTime != '' && startTime != null) || (endTime != '' && endTime != null)) { //起止时间其中一个不为空
            if (startTime == '' || endTime == '') { //起止时间其中一个为空
              this.$message({
                type: 'warning',
                message: '查询条件为上线时间区间时必须两个都填写!',
                duration: this.elDuration
              })
              return
            } else if (endTime.getTime() - startTime.getTime() < 0) { //结束日期不能早于开始日期
              this.$message({
                type: 'warning',
                message: '结束日期不能早于开始日期!',
                duration: this.elDuration
              })
              return
            } else if (today.getTime() < startTime.getTime() || today.getTime() < endTime
              .getTime()) { //所选择的日期不能早于当前日期
              this.$message({
                type: 'warning',
                message: '所选择的日期不能早于当前日期!',
                duration: this.elDuration
              })
              return
            } else { //限制日期选择区间
              let duringTime = (endTime.getTime() - startTime.getTime()) / (24 * 60 * 60 * 1000)
              if (duringTime > 90) {
                this.$message({
                  type: 'warning',
                  message: '日期区间不能超过90天!',
                  duration: this.elDuration
                })
                return
              }
            }
          }
          if ((updateStartTime != '' && updateStartTime != null) || (updateEndTime != '' && updateEndTime !=
              null)) { //起止时间其中一个不为空
            if (updateStartTime == '' || updateEndTime == '') { //起止时间其中一个为空
              this.$message({
                type: 'warning',
                message: '查询条件为更新时间区间时必须两个都填写!',
                duration: this.elDuration
              })
              return
            } else if (updateEndTime.getTime() - updateStartTime.getTime() < 0) { //结束日期不能早于开始日期
              this.$message({
                type: 'warning',
                message: '结束日期不能早于开始日期!',
                duration: this.elDuration
              })
              return
            } else if (today.getTime() < updateStartTime.getTime() || today.getTime() < updateEndTime
              .getTime()) { //所选择的日期不能早于当前日期
              this.$message({
                type: 'warning',
                message: '所选择的日期不能早于当前日期!',
                duration: this.elDuration
              })
              return
            } else { //限制日期选择区间
              let duringTime = (updateEndTime.getTime() - updateStartTime.getTime()) / (24 * 60 * 60 * 1000)
              if (duringTime > 90) {
                this.$message({
                  type: 'warning',
                  message: '日期区间不能超过90天!',
                  duration: this.elDuration
                })
                return
              }
            }
          }
          //关闭弹框
          this.show_searchBox = false;
        }

        //设置高级查询默认条件
        if (putAwayState != '') { //下架状态
          data.isDown = putAwayState
        }

        //设置查询条件
        if (![0, 1, 2, 3].includes(type)) {
          //编号
          if (productCode != '') {
            data.productCode = productCode
          }
          //名称
          if (productName != '') {
            data.productName = productName
          }
          //规格
          if (specifications != '') {
            data.specifications = specifications
          }
          //仓库
          if (houseName != '') {
            data.houseId = houseId
          }
          //货品审核状态
          if (isSubmit != '') {
            data.isSubmit = isSubmit
          }
          //开始时间
          if (startTime != '') {
            data.startTime =this.commonJsExtend.getDateTime(startTime,0)
          }
          //结束时间
          if (endTime != '') {
            data.endTime =this.commonJsExtend.getDateTime(endTime,0)
          }
          //开始时间
          if (updateStartTime != '') {
            data.updateStartTime =this.commonJsExtend.getDateTime(updateStartTime,0)
          }
          //结束时间
          if (updateEndTime != '') {
            data.updateEndTime =this.commonJsExtend.getDateTime(updateEndTime,0)
          }
        }

        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#product_body", 4, '货品列表信息获取中,请稍候...');
        //发送请求
        api.searchCommodityByClassfiyId(data).then(res => {
          //判断接口返回数据
          if (res.code == 200) {
            //获取当前列表数据
            this.ProductListData = res.data.rows;
            //获取总条数
            this.totalSize = res.data.total;
            //定义需要获取库存的货品id数据
            let proIdArr = [];
            this.ProductListData.forEach((item, index) => {
              proIdArr.push(item.id)
            })
            //获取主仓
            let mainWarehouse = "";
            this.WarehouseConfig.forEach((item, index) => {
              if (item.is_main == 0) {
                mainWarehouse = item;
              }
            })
            //获取库存信息
            this.getProductStockNum(proIdArr.join(","), mainWarehouse.id);
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
          //关闭loading框
          this.loading.close();
        })
      },

      /* 获取货品库存信息 proDataIdStr:货品id字符串数据  houseId:仓库数据id*/
      getProductStockNum(proDataIdStr, houseId) {
        //判断是否有货品
        if (proDataIdStr == "") {
          return
        }
        let data = {
          ids: proDataIdStr, //货品id
          warehouseId: houseId, //仓库id
        }
        stockApi.findProNumByHouseIdAndProId(data).then(res => {
          if (res.code == 200) {
            this.ProductListData.forEach((itemI, indexI) => {
              res.data.forEach((itemJ, indexJ) => {
                //获取库存相关信息
                if (itemI.id == itemJ.productInfoId) {
                  this.$set(itemI, "warehouseTotalNumber", itemJ.warehouseTotalNumber); //库存量
                  this.$set(itemI, "occupyTotalNumber", itemJ.occupyTotalNumber); //占用量
                  this.$set(itemI, "onwayTotalNumber", itemJ.onwayTotalNumber); //在途量
                  this.$set(itemI, "productTotalNumber", itemJ.productTotalNumber); //生产量
                  this.$set(itemI, "usableTotalNumber", itemJ.usableTotalNumber); //可用量
                }
              })
            })
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 弹框组件 */
      judgeRelatedData(type) {
        if (type == 0) {
          this.SHOW_WAREHOUSEBOX(true) //仓库
        }
      },

      /* 获取选中的货品数据 */
      getSelectedProductData(selection, row) {
        //获取选中的数据
        this.selProductData = selection;
      },

      /* 全选货品数据 */
      getSelectedAllData(selection) {
        //获取选中的数据
        this.selProductData = selection;
      },

      /* 分页大小发生改变 */
      handleSizeChange(val) {
        //获取当前页面大小
        this.currentPageSize = val;
        //获取列表数据
        this.getProductListData(this.conditionType);
      },

      /* 当前页面发生改变 */
      handleCurrentChange(val) {
        //获取当前页下标
        this.currentPage = val;
        //获取列表数据
        this.getProductListData(this.conditionType);
      },

      /* 删除货品 */
      deleteProduct(data) {
        //判断是否有删除权限
        if (this.commonJsExtend.isHaveThePower(21, 2, 3, '删除')) {
          return
        }
        this.$confirm('您确定要删除这个货品吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          //加载loading框
          this.loading = this.commonJsExtend.customLoading("#product_body", 2);
          this.Is_click = true //loading加载时让用户无法点击分页
          api.deleteCommodity({
            productId: data.id, //货品id
            name: data.name //货品名称
          }).then(res => {
            //关闭loading框
            this.loading.close()
            if (res.code == 200) {
              this.Is_click = false
              this.$message({
                type: 'success',
                message: '删除成功',
                duration: 1500
              })
              //获取最新数据
              this.getProductListData(this.conditionType);
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: 1500
              })
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消删除',
            duration: 1500
          })
        })
      },

      /* 下架货品 */
      soldOutProduct(data) {
        this.$confirm('您确定要下架这个货品吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          //显示下架原因填写弹框
          this.show_soldout = true;
          //清空下架原因
          this.soldoutData = "";
          //获取当前操作的货品数据
          this.curentOperateProData = data;
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消下架',
            duration: 1500
          })
        })
      },

      /* 下架货品 */
      soldoutProData(productId) {
        // 判断作废原因是否已填
        if (this.soldoutData.trim() == "") {
          this.$message({
            type: 'warning',
            message: '请填写下架原因!',
            duration: this.elDuration
          })
          return
        }
        //关闭弹框
        this.show_soldout = false;
        //定义传入后端的数据
        let soldoutData = {
          productId: this.curentOperateProData.id,
          reason: this.soldoutData
        }
        // 请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#product_body", 4, "货品下架中,请稍候...");
        // 发送请求
        api.soldOutProduct(soldoutData).then(res => {
          this.loading.close();
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '货品下架成功!',
              duration: this.elDuration
            })
            this.getProductListData(this.conditionType);
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            });
          }
        })
      },

      /* 上架货品 */
      putAwayProduct(data) {
        this.$confirm('您确定要重新上架这个货品吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          //发送上架请求
          this.putAwayProData(data);
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消上架',
            duration: 1500
          })
        })
      },

      /* 重新上架货品请求 */
      putAwayProData(data) {
        //定义传入后端的数据
        let putAwayData = {
          productId: data.id
        }
        // 请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#product_body", 4, "货品上架中,请稍候...");
        // 发送请求
        api.putAwayProduct(putAwayData).then(res => {
          this.loading.close();
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '货品上架成功!',
              duration: this.elDuration
            })
            //重新获取列表数据
            this.getProductListData(this.conditionType);
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            });
          }
        })
      },

      /* 修改货品data(需要修改的货品数据) type(0:列表页点击修改  1:修改后再次请求)*/
      updateProduct(data, type) {
        //判断是否有修改权限
        if (this.commonJsExtend.isHaveThePower(21, 2, 4, '修改')) {
          return
        }

        // ******************获取货品信息******************
        //加载loading框
        // 发送网络请求
        api.searchCommodityById({
          productId: data.id
        }).then(res => {
          if (res.code == 200) {
            //将数据存入vuex
            this.CURRENT_UPDATEP_RODUCT(res.data)
            //将模式设置成修改
            this.Is_add = 1;
            if (type == 0) {
              //显示添加弹框
              this.SHOW_ADDBOX(true)
            }
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })

        // ******************获取货品是否已被其它单据使用******************
        api.findProductIsBillsUsed({
          productId: data.id
        }).then(res => {
          if (res.code == 200) {
            //判断是否已被使用
            if(res.data.result == "1"){
              this.PRODUCT_IS_BILLS_USED(true);
            }else if(res.data.result == "0"){
              this.PRODUCT_IS_BILLS_USED(false);
            }
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 审核货品信息 */
      checkProductInfo() {
        if (this.selProductData.length == 0) {
          this.$message({
            type: 'warning',
            message: '请选择需要审核的货品',
            duration: this.elDuration
          })
          return
        }
        //获取需要处理的id数据
        let selProductIdArr = [];
        this.selProductData.forEach((item, index) => {
          if (item.is_submit == 3) {
            this.$message({
              type: 'warning',
              message: '选择的数据中不能存在已审核货品!',
              duration: this.elDuration
            })
            return
          }
          selProductIdArr.push(item.id);
        })
        //定义后端接受参数
        let updateData = {
          ids: selProductIdArr.join(','),
          isSubmit: 3, //审核通过状态
        }
        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#product_body", 4, '货品审核中,请稍候!');
        //请求数据
        api.updateProductStatusYes(updateData).then(res => {
          //关闭loading框
          this.loading.close();
          // 判断返回值
          if (res.code == 200) {
            //获取最新数据
            this.getProductListData(this.conditionType);
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 根据属性查询 */
      command(command) {
        this.getProductListData(this.conditionType, 0, command) //属性
      },
      /* 根据来源查询 */
      sourcecommand(command) {
        this.getProductListData(this.conditionType, 1, command) //来源
      },

      /* 获取属性列表 */
      getAttrData() {
        this.sel_searchBtn = 2;
        // 发送请求
        attrApi.productDict.findAllProductProperty().then(res => {
          if (res.code == 200) {
            this.attrData = res.data //属性列表
          } else {
            this.$message({
              type: "warning",
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 获取属性列表 */
      getSourceData() {
        this.sel_searchBtn = 3;
        // 发送请求
        attrApi.productDict.findAllProductSource().then(res => {
          if (res.code == 200) {
            this.sourceData = res.data //属性列表
          } else {
            this.$message({
              type: "warning",
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 获取仓库弹框选择数据 */
      getWarehouseData(data) {
        this.product_searchData.houseName = data.name //仓库名称
        this.product_searchData.houseId = data.id //仓库id
      },

      // 显示新建货品弹框
      show_createBox() { //显示
        //判断是否有新增权限
        if (this.commonJsExtend.isHaveThePower(21, 2, 2, '新增')) {
          return
        }
        this.Is_add = 0
        this.SHOW_ADDBOX(true)
      },

      /* 关闭货品导入弹框 */
      close_exportBox() {
        this.show_exportBox = false
      },

      /* 打开导入弹框 */
      showExport() {
        //判断是否有导入权限
        if (this.commonJsExtend.isHaveThePower(21, 2, 6, '导入')) {
          return
        }
        this.show_exportBox = true
      },

      /* 打开导出弹框 */
      showImport() {
        //判断是否有导出权限
        if (this.commonJsExtend.isHaveThePower(21, 2, 5, '导出')) {
          return
        }
        //获取默认开始日期和结束日期
        let today = new Date();
        //设置时间为零点
        today.setHours(0, 0, 0, 0);
        //结束时间默认当前日期
        this.endTime = today;
        //开始时间默认当前日期往前推90天
        this.startTime = new Date(today.getTime() - 24 * 60 * 60 * 1000 * 90);
        this.isShowExportListBox=true

      },

      /* 单据页面大小信息改变 */
      billsPageChange(val) {
        //获取单据当前页信息
        this.billsPageData.currentPageSize = val;
        //获取数据
        this.getBillsData(this.currentBillsProduct, this.currentBillsType);
      },

      /* 单据列表当前页信息改变 */
      getCurrentPage(val) {
        //获取单据当前页信息
        this.billsPageData.currentPage = val;
        //获取数据
        this.getBillsData(this.currentBillsProduct, this.currentBillsType);
      },

      /* 获取货品单据详情信息 data:需要获取数据的货品id type:(0:样单数 1:订单数  2:外购数  3:生产中 4:已生产)*/
      getBillsData(data, type) {
        //获取当前操作的货品数据
        this.currentBillsProduct = data;
        //获取当前的单据类型
        this.currentBillsType = type;
        //显示弹框
        this.show_billsDetail = true;
        //定义后端接受参数
        let getData = {
          pageIndex: this.billsPageData.currentPage,
          pageSize: this.billsPageData.currentPageSize,
          productId: data.id,
        }
        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#billingDetailBox", 4, "单据列表信息获取中,请稍候!");
        // 判断操作类型
        if (type == 0) { //样单数
          this.billsTypeTitle = "样品单信息"
          api.findProdSimpleBillsInfo(getData).then(res => {
            //关闭loading框
            this.loading.close();
            if (res.code == 200) {
              //获取表格数据
              this.billsData = res.data.rows;
              //获取分页信息
              this.billsPageData.totalSize = res.data.total;
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (type == 1) { //订单数
          this.billsTypeTitle = "订单信息"
          api.findProdOrderBillsInfo(getData).then(res => {
            //关闭loading框
            this.loading.close();
            if (res.code == 200) {
              //获取表格数据
              this.billsData = res.data.rows;
              //获取分页信息
              this.billsPageData.totalSize = res.data.total;
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (type == 2) { //采购数
          this.billsTypeTitle = "外购单信息"
          api.findProdPurchaseBillsInfo(getData).then(res => {
            //关闭loading框
            this.loading.close();
            if (res.code == 200) {
              //获取表格数据
              this.billsData = res.data.rows;
              //获取分页信息
              this.billsPageData.totalSize = res.data.total;
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (type == 3) { //生产中
          this.billsTypeTitle = "生产单信息"
          api.findProdProducedBillsInfo(getData).then(res => {
            //关闭loading框
            this.loading.close();
            if (res.code == 200) {
              //获取表格数据
              this.billsData = res.data.rows;
              //获取分页信息
              this.billsPageData.totalSize = res.data.total;
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (type == 4) { //已生产
          this.billsTypeTitle = "生产单信息"
          api.findProdProductionBillsInfo(getData).then(res => {
            //关闭loading框
            this.loading.close();
            if (res.code == 200) {
              //获取表格数据
              this.billsData = res.data.rows;
              //获取分页信息
              this.billsPageData.totalSize = res.data.total;
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        }
      },

      /* 显示货品详情弹框 */
      showProDetail(id,type,classify,mesDepotState){
        //显示弹框
        this.show_productDetailBox = true;
        //获取需要查看的货品id
        this.detailProductId = id;
        //设置跳转到标签页
        this.proDetailBillTab=type;
        //相关类型
        this.classify=classify;
        //判断是否只加载已入库
        this.mesDepotState=mesDepotState;
      },

      /* 关闭货品详情弹框 */
      closeProDetail(){
        this.show_productDetailBox = false;
      },

      /*初始化列表显示字段数据 */
      initListShowFiledData() {
        //获取界面显示数组
        this.showListFiledArr = this.commonJsExtend.initListShowFiledData('productListArr', this.productListFileData)
          .showListFiledArr;
        //获取控制数组
        this.showListFiledData = this.commonJsExtend.initListShowFiledData('productListArr', this.productListFileData)
          .showListFiledData;
        //定义不需要的id数组
        let ids=[3,4,5,13,20,25,6,9,28,16,17,18,19];
        //判断其它控制权限
        this.showListFiledData.forEach((item, index) => {
          //判断是否开启旧物料编码
          if (item.value == 27) {
            if (this.companyparamlist.param345 == "1") { //不启用
              this.showListFiledData.splice(index, 1);
            }
          }
          //过滤掉不需要的
          if(ids.includes(item.value)&&!getCookie('productListArr')){
            item.is_show=false
          }
        })
      },

      /* 获取当前需要显示的列表字段 */
      getCurShowFiledArr() {
        //将数据存入cookie
        this.showListFiledArr = this.commonJsExtend.getCurShowFiledArr('productListArr', this.showListFiledData);
      },
    },
    filters: {
      /* 获取采购计量单位 */
      getPurchaseUnit(value) {
        let result = "";
        //判断是否有值
        if (value && value.length > 0) {
          value.forEach((item, index) => {
            if (item.is_purchase_default == 1) {
              result = item.calculateName;
            }
          })
          //若没有基础计量单位则默认选中第一个
          if (result == "") {
            result = value[0].calculateName;
          }
        }
        return result;
      },
      /* 多计量换算 */
      getPurchaseChangeNum(number, row) {
        //定义返回结果
        let resnum = isNaN(number) ? 0 : number;

        // 获取多计量数据
        let productPriceList = row.productPrice;

        // 获取基础计量单位
        let curCalculate = null;
        let curCalculateArr = productPriceList.filter(item => item.is_base == 1);
        if (curCalculateArr.length > 0) {
          curCalculate = curCalculateArr[0].exchange_base;
        }

        //获取采购计量单位
        let purchaseCalculate = null;
        let purchaseCalculateArr = productPriceList.filter(item => item.is_purchase_default == 1);
        if (purchaseCalculateArr.length > 0) {
          purchaseCalculate = purchaseCalculateArr[0].exchange_base;
        }

        //转换计量单位
        if (curCalculate != null && purchaseCalculate != null) {
          let curcxchaangebase = 1;
          //判断转换关系
          if (curCalculate > purchaseCalculate) { //大转小
            //获取换算计量
            curcxchaangebase = (curCalculate / purchaseCalculate);
          } else if (curCalculate < purchaseCalculate) { //小转大
            //获取换算计量
            curcxchaangebase = (purchaseCalculate / curCalculate);
          }

          //计算数据
          resnum = resnum / curcxchaangebase;
        }

        return resnum;
      },
    },
    components: {
      commodityCreate,
      exportProduct,
      warehouseList,
      productDetail
    }
  }
</script>
<style lang="less" scoped>
  .prodoctName {
    display: block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: default;
  }

  .rotateX {
    transform: rotateX(180deg);
  }

  .billingDetailBox {
    height: 45vh;
  }

  .span_pointer{
    cursor: pointer;
  }

  .span_color_blue:hover{
    color: blue;
  }

  // 单据分页弹框
  .billsPageBox {
    padding: 5px 0;
    // border: 1px solid black;
  }
  /* 导出列表弹框样式 */
  .exportListBox {
    padding: 0 0 30px 0;
    // border: 1px solid black;
  }
</style>
